import type {
  DietDish,
  DietDishTypeIdMap,
  DietIngredient,
  DietMealTypeIdMap,
  DishInFormType,
  StoreDietDish
} from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'

import { A } from '@mobily/ts-belt'

import { api } from '@/services/apiService'

export interface SearchIngredient {
  ingredient__is_diary_free: boolean
  ingredient__is_gluten_free: boolean
  ingredient__is_vegan: boolean
  ingredient__is_vegetarian: boolean
  ingredient_name: string
}

export interface DishByIngredient {
  dish_types: (keyof DietDishTypeIdMap)[]
  ingredients: SearchIngredient[]
  meal_types: (keyof DietMealTypeIdMap)[]
}
export type DishSearchByIngredientResponse = Record<string, DishByIngredient>
export type CreateDishParam = DietDish | StoreDietDish | DishInFormType
export type DishSearchIngredient = {
  base: number
  calories: number
  category: number
  id: number
  is_diary_free: boolean
  is_favourite: boolean
  is_gluten_free: boolean
  is_public: boolean
  is_vegan: boolean
  is_vegetarian: boolean
  name: string
  quantity: number
} & Record<Nutrients, number>
export interface DishSearchIngredientsResponse {
  ingredients: DishSearchIngredient[]
}

export const dishService = {
  async fetchDish(dishId: number | string) {
    const url = `/dishes/${dishId}/`
    const res = await api.get<StoreDietDish>(url)
    return res.data
  },
  async createDish(dish: CreateDishParam) {
    const url = '/dishes/'
    const res = await api.post<{ id: number }>(url, dish)
    return res.data
  },
  async deleteDish(dishId: number) {
    const url = `dishes/${dishId}/`
    const res = await api.delete<{ id: number }>(url)
    return res.data
  },
  async copyDish(dishId: number) {
    const url = `/dishes/${dishId}/copy/`
    const res = await api.post<{ id: number }>(url)
    return res.data
  },
  async updateDish(dishId: number, dish: StoreDietDish | DishInFormType) {
    const url = `/dishes/${dishId}/`
    const res = await api.patch(url, dish)
    return res.data
  },
  async fetchDishes(chosenNutrientsList: Nutrients[]) {
    let queryString = ''

    A.forEach(chosenNutrientsList, (nutrient) => {
      queryString += `search[]=${nutrient}&`
    })
    const url = `dishes/?${queryString}/`
    const res = await api.get<DishInFormType[]>(url)
    return res.data
  },
  async fetchSearchIngredients(nutrients: Nutrients[], resetCache = false) {
    const url = '/dishes/ingredients/'
    const res = await api.post<DishSearchIngredientsResponse>(url, {
      nutrients,
      reset_cache: resetCache
    })
    return res.data.ingredients
  },
  async dishSearchByIngredient() {
    const url = 'dishes/dish_search_by_ingredient/'
    const res = await api.get<DishSearchByIngredientResponse>(url)
    return res.data
  },
  async toggleFavoriteDish(dishId: number) {
    const url = `dishes/${dishId}/toggle_favorite/`
    const res = await api.post<{ id: number }>(url)
    return res.data
  },
  async updateIngredientInDishQuantity(dishId: number, dishIngredientId: number, quantity: number) {
    const url = `/dishes/${dishId}/update_ingredient_quantity/`
    const res = await api.post(url, { dish_ingredient_id: dishIngredientId, quantity })
    return res.data
  },
  async deleteIngredient(dishId: number, dishIngredientId: number) {
    const url = `/dishes/${dishId}/delete_ingredient/`
    const res = await api.post(url, { dish_ingredient_id: dishIngredientId })
    return res.data
  },
  async addIngredient(dishId: number, ingredient: DietIngredient) {
    const url = `/dishes/${dishId}/add_ingredient/`
    const res = await api.post(url, { ingredient })
    return res.data
  },
  async replaceIngredient(dishId: number, ingredient: DietIngredient, dishIngredientId: number) {
    const url = `/dishes/${dishId}/replace_ingredient/`
    const res = await api.post(url, { ingredient, dishIngredientId })
    return res.data
  }
}
