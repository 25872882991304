<template>
  <div class="ingredients-list">
    <div class="search">
      <div class="row-f main-row">
        <BaseInputSearch
          :placeholder="'Wyszukaj potrawę po nazwie...'"
          :debounce-after="750"
          :model-value="searchedPhrase"
          @update:model-value="($event) => (searchedPhrase = $event)"
        />
        <div class="row-f extra">
          <BaseCheckbox
            :disabled="!dishesWithIngredientsLoaded"
            :label="'Wyszukaj w produktach'"
            :model-value="searchByIngredientName"
            @click="setSearchByIngredientName"
          />
          <div
            class="row-f search-buttons"
            :class="{ extended: extendedFilters }"
          >
            <StyledButton
              v-if="areFiltersActive(dishesType)"
              variant="secondary"
              @click="resetFilters"
            >
              Wyczyść filtry
            </StyledButton>
            <StyledButton
              variant="secondary"
              @click="toggleExtendedFilters()"
            >
              {{ !extendedFilters ? 'Zaawansowane' : 'Ukryj zaawansowane' }}
            </StyledButton>
          </div>
        </div>
      </div>
      <DishesExtendedFilters
        v-show="extendedFilters"
        :dishes-with-ingredients-loaded="dishesWithIngredientsLoaded"
        :kcal-min="kcalMin"
        :kcal-max="kcalMax"
        :preparation-time-min="preparationTimeMin"
        :preparation-time-max="preparationTimeMax"
        :portions-min="portionsMin"
        :portions-max="portionsMax"
        :active-tab="dishesType"
        :reset-filters="resetFiltersValue"
        @set-dish-size="setDishSizeForFilters($event)"
        @set-kcal-min="setKcalMin($event)"
        @set-kcal-max="setKcalMax($event)"
        @set-preparation-time-min="setPreparationTimeMin($event)"
        @set-preparation-time-max="setPreparationTimeMax($event)"
        @set-portions-min="setPortionsMin($event)"
        @set-portions-max="setPortionsMax($event)"
        @set-meal-type="setMealTypeForFilters($event)"
        @reset-kcal-min-max-value="resetKcalMinMax()"
        @reset-preparation-time-min-max-value="resetPreparationTimeMinMax()"
        @reset-portions-min-max-value="resetPortionsMinMax()"
      />
      <div
        v-show="extendedFilters"
        class="dish-tags"
      >
        <span>Wybrane tagi:</span>
        <span>Filtruj listę potraw dzięki zaawansowanej funkcji tagów systemowych</span>

        <div
          v-if="dishTypesForFiltersLength > 0"
          class="chosen-types"
        >
          <div
            v-for="(type, index) in getDishTypeNames"
            :key="index"
            class="chosen-type-box editable"
          >
            <span>{{ type.name }}</span>
            <BaseIcon
              size="18"
              name="close"
              class="remove-tag"
              @click="removeSingleTag(type.id)"
            />
          </div>
        </div>
        <StyledButton
          class="show-tags"
          variant="secondary"
          left-icon="add"
          left-icon-size="24"
          @click="showDishTagList"
        >
          Pokaż dostępne tagi
        </StyledButton>
      </div>
    </div>
    <div
      class="nav"
      :class="{
        'sticky-extended': extendedFilters,
        'with-dish-types': dishTypesForFiltersLength > 0
      }"
    >
      <div
        class="nav__element"
        :class="{ 'nav__element--active': dishesType === 'all' }"
        @click="setDishesFilterByType('all')"
      >
        <div class="nav__name">
          Wszystkie
        </div>
        <BaseBadge>
          <span v-if="areFiltersActive('all')">{{ dishesFilterByCounted }} / </span>
          {{ dishes.length }}
        </BaseBadge>
      </div>
      <div
        class="nav__element"
        :class="{ 'nav__element--active': dishesType === 'is_public' }"
        @click="setDishesFilterByType('is_public')"
      >
        <div class="nav__name">
          Publiczne
        </div>
        <BaseBadge>
          <span v-if="areFiltersActive('is_public')">{{ dishesFilterByCounted }} / </span>
          {{ publicDishes.length }}
        </BaseBadge>
      </div>
      <div
        class="nav__element"
        :class="{ 'nav__element--active': dishesType === 'is_private' }"
        @click="setDishesFilterByType('is_private')"
      >
        <BaseIcon
          name="lock"
          type="disabled"
        />
        <div class="nav__name">
          Prywatne
        </div>
        <BaseBadge>
          <span v-if="areFiltersActive('is_private')">{{ dishesFilterByCounted }} / </span>
          {{ privateDishes.length }}
        </BaseBadge>
      </div>
      <div
        class="nav__element"
        :class="{ 'nav__element--active': dishesType === 'is_favorite' }"
        @click="setDishesFilterByType('is_favorite')"
      >
        <BaseIcon
          name="favorite"
          class="size-20 favorite-icon"
        />
        <div class="nav__name">
          Ulubione
        </div>
        <BaseBadge>
          <span v-if="areFiltersActive('is_favorite')">{{ dishesFilterByCounted }} / </span>
          {{ favoriteDishes.length }}
        </BaseBadge>
      </div>

      <Space
        fill
        align-x="end"
        align-y="center"
        direction="columns"
        :space="4"
      >
        <StyledButton
          class="add-dish"
          left-icon="add"
          :disabled="loading"
          @click="showNewDishModal"
        >
          Dodaj potrawę
        </StyledButton>
        <BaseTooltip class="base-tooltip--right base-tooltip--top">
          <template #tooltip>
            Zmień wyświetlane wartości odżywcze
          </template>
          <IconButton
            name="settings"
            type="close"
            @click="showChosenNutrientsModal()"
          />
        </BaseTooltip>
      </Space>
    </div>
    <div
      class="list-and-form"
      :class="{ 'full ': extendedForm }"
    >
      <div
        class="list"
        :class="{ hidden: extendedForm, 'divided ': dishFormShown }"
      >
        <div
          class="header row"
          :class="[
            `column-ingredient-${chosenNutrientsLength}`,
            {
              'sticky-extended': extendedFilters,
              'with-dish-types': dishTypesForFiltersLength > 0,
              toggled: dishFormShown
            }
          ]"
        >
          <div class="column private" />
          <div class="header__name column">
            <div
              class="column__sortable"
              :class="{ active: sortBy === 'name' }"
              @click="setSortBy('name')"
            >
              Nazwa potrawy
              <BaseIcon
                class="size-20"
                :name="getIconBySort('name')"
              />
            </div>
          </div>
          <div class="column">
            <div
              class="column__sortable"
              :class="{ active: sortBy === 'quantity' }"
              @click="setSortBy('quantity')"
            >
              <div class="header-nutrient-name">
                Ilość(g)
              </div>
              <BaseIcon
                class="size-20"
                :name="getIconBySort('quantity')"
              />
            </div>
          </div>
          <div class="column">
            <BaseTooltip class="base-tooltip--center base-tooltip--top">
              <template #tooltip>
                <div>Wartość energetyczna</div>
              </template>
              <div
                class="column__sortable"
                :class="{ active: sortBy === 'calories' }"
                @click="setSortBy('calories')"
              >
                <div class="column-positioned">
                  K(kcal)
                </div>
                <BaseIcon
                  class="size-20"
                  :name="getIconBySort('calories')"
                />
              </div>
            </BaseTooltip>
          </div>
          <div
            v-for="nutrient in chosenNutrients"
            :key="`dish-${nutrient}`"
            class="column"
          >
            <BaseTooltip class="base-tooltip--center base-tooltip--top">
              <template #tooltip>
                <div>{{ nutrientsMap[nutrient].name }}</div>
              </template>
              <div
                class="column__sortable"
                :class="{
                  active: sortBy === nutrient,
                  disabled: nutrient === 'ratio_n_3_n_6'
                }"
                @click="setSortBy(nutrient)"
              >
                <div class="column-positioned">
                  {{ getNutrientHeader(nutrient) }}
                </div>
                <BaseIcon :name="getIconBySort(nutrient)" />
              </div>
            </BaseTooltip>
          </div>
          <div class="column" />
          <div
            v-if="dishFormShown"
            class="column extend"
            @click="hideDishForm"
          >
            <BaseIcon
              name="double_arrow"
              class="size-18 outlined"
            />
          </div>
        </div>
        <div
          v-if="loading"
          class="list-loader"
        >
          <div class="list-loader-bar">
            <div class="list-loader-bar__progress" />
          </div>
        </div>
        <div
          v-else-if="dishesFilterByList.length === 0"
          class="empty-results"
        >
          <BaseIcon
            name="info"
            class="size-20 base-icon--info"
          />
          Nie znaleziono potraw
        </div>
        <div
          v-else-if="dishesFilterByList.length > 0"
          class="ingredients"
        >
          <div
            v-for="dish in dishesFilterBySliced"
            :key="`dish-${dish.id}`"
            class="ingredient row"
            :class="[
              `column-ingredient-${chosenNutrientsLength}`,
              {
                active: currentDishId === dish.id,
                toggled: dishFormShown,
                inactive: dish.name === 'Potrawa bez nazwy'
              }
            ]"
            @click="showDishForm(dish.id)"
          >
            <div class="column private">
              <BaseIcon
                v-if="!dish.is_public"
                name="lock"
              />
            </div>
            <div
              class="column ingredient__name"
              :title="dish.name"
            >
              {{ dish.name }}
            </div>
            <div class="column base">
              <div class="value-positioned">
                {{ dish.quantity ? dish.quantity : 0 }}
              </div>
            </div>
            <div class="column ingredient">
              <div class="value-positioned">
                {{ getValue(dish.calories, 1) }}
              </div>
            </div>
            <div
              v-for="value in chosenNutrients"
              :key="`nutrientValue-${value}`"
              class="column ingredient"
            >
              <div class="value-positioned">
                {{
                  value === 'ratio_n_3_n_6'
                    ? getN3N6Ratio(dish.ratio_n_3_n_6)
                    : getValue(dish[value], 1)
                }}
              </div>
            </div>
            <div class="column favorite">
              <BaseTooltip class="base-tooltip--right base-tooltip--top">
                <template #tooltip>
                  {{
                    !isDishFavorite(dish.id) ? 'Dodaj do ulubionych' : 'Usuń z ulubionych'
                  }}
                </template>
                <IconButton
                  type="close"
                  :icon-variant="!isDishFavorite(dish.id) ? 'outline' : 'fill'"
                  name="favorite"
                  @click.stop="toggleFavorite(dish.id)"
                />
              </BaseTooltip>
            </div>
            <div
              class="row__actions"
              :class="{ 'two-items': dish.is_public }"
            >
              <div class="ingredient__actions">
                <Space
                  direction="columns"
                  :space="2"
                >
                  <BaseTooltip class="base-tooltip--right base-tooltip--top">
                    <template #tooltip>
                      Skopiuj potrawę
                    </template>
                    <IconButton
                      :disabled="loading"
                      icon-size="18"
                      name="filter_none"
                      @click.stop="copyDish(dish.id)"
                    />
                  </BaseTooltip>
                  <BaseTooltip class="base-tooltip--right base-tooltip--top">
                    <template #tooltip>
                      {{
                        dish.is_public ? 'Zobacz potrawę' : 'Edytuj potrawę'
                      }}
                    </template>
                    <IconButton
                      icon-size="18"
                      :name="dish.is_public ? 'search' : 'edit'"
                      @click="showDishForm(dish.id)"
                    />
                  </BaseTooltip>
                  <BaseTooltip
                    v-if="!dish.is_public"
                    class="base-tooltip--right base-tooltip--top"
                  >
                    <template #tooltip>
                      Usuń potrawę
                    </template>
                    <IconButton
                      class="delete"
                      name="delete"
                      variant="outline"
                      type="error"
                      icon-size="18"
                      @click.stop="showDishDeleteModal(dish.id)"
                    />
                  </BaseTooltip>
                </Space>
              </div>
            </div>
          </div>
        </div>
        <BasePagination
          :count="dishesFilterByCounted"
          :current-page="currentPage"
          :per-page="perPage"
          @on-page-change="(v) => (currentPage = v)"
        />
      </div>
      <div
        v-if="currentDishId && searchIngredients"
        id="dynamic-container"
        ref="dynamicContainer"
        class="dynamic-container dish-form"
        :class="{ 'divided ': dishFormShown, full: extendedForm }"
        :style="!extendedForm ? `height: calc(100vh - ${height}px` : 'height: auto'"
      >
        <DishesDishOrIngredient
          :dish-index="currentDishId"
          :extended-form="extendedForm"
          @close-form="hideDishForm"
          @toggle-extended="toggleExtended"
          @copy-dish="copyDish($event)"
          @delete-dish="showDishDeleteModal($event)"
        />
      </div>
    </div>
    <BaseModal
      v-if="dishToDelete"
      @close="hideDishDeleteModal"
      @confirm="deleteDish"
    >
      <template #title>
        Usuwanie potrawy
      </template>
      <div class="ingredient-delete-modal-info">
        Czy na pewno chcesz usunąć tę potrawę?
      </div>
      <template #actions>
        <StyledButton
          class="base-button--secondary base-button--outline"
          @click.once="hideDishDeleteModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton
          class="base-button--primary"
          @click.once="deleteDish"
        >
          Usuń
        </StyledButton>
      </template>
    </BaseModal>
    <BaseModal
      v-if="createNewDishModal"
      @close="hideNewDishModal"
      @confirm="showCreateNewDishModal"
    >
      <template #title>
        Dodaj szczegóły lub nową potrawę
      </template>
      <div class="ingredient-delete-modal-info">
        Zauważyliśmy, że masz 5 nienazwanych potraw na Twojej liście. Aby uzyskać najlepsze
        rezultaty, każda potrawa wymaga swojej nazwy i szczegółów. Czy chcesz uzupełnić szczegóły
        ostatniej nienazwanej potrawy teraz?
      </div>
      <template #actions>
        <StyledButton
          class="base-button--secondary base-button--outline"
          @click.once="showCreateNewDishModal"
        >
          Dodaj nową potrawę
        </StyledButton>
        <StyledButton
          class="base-button--primary"
          @click.once="editLastBlankDish"
        >
          Edytuj ostatnią potrawę
        </StyledButton>
      </template>
    </BaseModal>
    <ChosenNutrientsModal
      v-if="chosenNutrientsModalVisible"
      :mode="'dishes'"
      :chosen-nutrients="chosenNutrients"
      @close="hideChosenNutrientsModal"
      @load-custom-nutrients="loadCustomNutrients"
    />
    <DishesTagList
      v-if="tagListShown"
      :mode="'search'"
      :chosen-tags="dishTypesForFilters"
      @set-dish-type="setDishTypeForFilters($event)"
      @search-all-tags="searchAllTags($event)"
      @hide-tag-list="hideDishTagList"
    />
  </div>
</template>

<script lang="ts" setup>
import type { DishByIngredient } from '@/services/dishService'
import type { DishInFormType, MealTypes } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'

import { A, G } from '@mobily/ts-belt'
import { useQueryClient } from '@tanstack/vue-query'
import { useEventListener } from '@vueuse/core'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { match } from 'ts-pattern'
import { computed, nextTick, ref, watch } from 'vue'

import BaseBadge from '@/components/BaseBadge.vue'
import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInputSearch from '@/components/BaseInputSearch.vue'
import BaseModal from '@/components/BaseModal.vue'
import BasePagination from '@/components/BasePagination.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import DishesExtendedFilters from '@/components/dishes/DishesExtendedFilters.vue'
import DishesTagList from '@/components/dishes/DishesTagList.vue'
import { IconButton } from '@/components/IconButton'
import ChosenNutrientsModal from '@/components/ingredients/ChosenNutrientsModal.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { useFuseSearch } from '@/hooks/useFuseSearch'
import DishesDishOrIngredient from '@/pages/dishes/DishesDishOrIngredient.vue'
import { useGlobalStore } from '@/store/globalStore'
import { dishTypes } from '@/utils/common'
import { nutrientsMap } from '@/utils/nutrients'
import { reportError } from '@/utils/reportError'

import { useDishListProvider } from './provider'

interface DishTypeWithName {
  id: number
  name: string
}

const globalStore = useGlobalStore()
const { hasPerm, showAppUpgradePlanModal } = globalStore
const { user } = storeToRefs(globalStore)

const dynamicContainer = ref<HTMLDivElement>()

const height = ref(1)
const currentPage = ref(1)
const perPage = ref(20)

// TODO: typings
const queryClient = useQueryClient()

const extendedFilters = ref(false)
const inputedPage = ref('')
const inputedPageRef = ref<HTMLInputElement | string>()
const dishesType = ref('all')
const searchedPhrase = ref('')
const kcalMinValue = ref('')
const kcalMaxValue = ref('')
const preparationTimeMinValue = ref('')
const preparationTimeMaxValue = ref('')
const portionsMinValue = ref('')
const portionsMaxValue = ref('')
const resetFiltersValue = ref(0)
const dishSizeForFilters = ref<number[]>([])
const mealTypesForFilters = ref<MealTypes>([])
const dishTypesForFilters = ref<DishByIngredient['dish_types']>([])
const searchByIngredientName = ref<boolean>(false)
const lastAddedNewDish = ref<number | undefined>()
const tagListShown = ref(false)
const createNewDishModal = ref(false)

const dishFormShown = computed(() => {
  return !!currentDishId.value
})

const {
  dishes,
  searchAllTagsStatus,
  toggleFavorite,
  showChosenNutrientsModal,
  searchIngredients,
  chosenNutrientsLength,
  chosenNutrients,
  loading,
  isDishFavorite,
  hideChosenNutrientsModal,
  chosenNutrientsModalVisible,
  loadCustomNutrients,
  dishesWithIngredients,
  favoriteDishesIds,
  currentDishId,
  extendedForm,
  hideDishForm,
  showDishForm,
  showDishDeleteModal,
  dishToDelete,
  hideDishDeleteModal,
  deleteDishAsync,
  addNewDishAsync,
  copyDishAsync,
  toggleExtended,
  dishesWithIngredientsLoaded,
  sortBy,
  sortByReverse,
  setSortBy,
  sort,
  getIconBySort
} = useDishListProvider()

const deleteDish = async () => {
  try {
    if (dishToDelete.value) {
      await deleteDishAsync(dishToDelete.value)
    }
  } catch (err) {
    reportError(err, 'Error during deleteDish', { dishId: dishToDelete.value })
  }
}

const getLatestBlankDish = () => {
  const emptyDishes = dishes.value
    .filter((d) => d.name === 'Potrawa bez nazwy')
    .map((dish) => dish.id)
  const id = Math.max(...emptyDishes)
  return {
    id,
    index: dishesFilterByList.value.findIndex((d) => d.id === id)
  }
}
const blankDishes = computed(() => {
  return dishes.value.filter((d) => d.name === 'Potrawa bez nazwy').length
})
const getDishTypeNames = computed(() => {
  return dishTypesForFilters.value.reduce<DishTypeWithName[]>((acc, type) => {
    const dishType = dishTypes.find((obj) => obj.value === type)
    if (dishType) {
      acc.push({ name: dishType.text, id: type })
    }
    return acc
  }, [])
})

const dishTypesForFiltersLength = computed(() => {
  return dishTypesForFilters.value.length
})

const hasAddDishPermission = computed(() => {
  const dishesPrivateCount = privateDishes.value.length
  if (!user?.value?.is_superuser) {
    if (hasPerm('accounts.max_dish_bo')) {
      return true
    } else if (hasPerm('accounts.max_dish_2000')) {
      return dishesPrivateCount < 2000
    } else if (hasPerm('accounts.max_dish_1500')) {
      return dishesPrivateCount < 1500
    } else if (hasPerm('accounts.max_dish_1000')) {
      return dishesPrivateCount < 100
    } else if (hasPerm('accounts.max_dish_10')) {
      return dishesPrivateCount < 10
    }
    return false
  }
  return true
})
const kcalMin = computed(() => {
  const data = dishes.value.filter((dish) => !isNaN(dish.calories))
  const minKcal = round(Math.min(...data.map((o) => o.calories)), 1)
  return minKcal
})
const kcalMax = computed(() => {
  const data = dishes.value.filter((dish) => !isNaN(dish.calories))
  const getKcalMaxValue = round(Math.max(...data.map((o) => o.calories)), 1)
  return getKcalMaxValue
})
const preparationTimeMin = computed(() => {
  const data = dishes.value
  return round(Math.min(...data.map((o) => o.preparation_time), 1))
})
const preparationTimeMax = computed(() => {
  const data = dishes.value
  return round(Math.max(...data.map((o) => o.preparation_time), 1))
})
const portionsMin = computed(() => {
  const data = dishes.value.filter((dish) => !isNaN(dish.portions || 1))
  return round(Math.min(...data.map((o) => o.portions || 1), 1))
})
const portionsMax = computed(() => {
  const data = dishes.value.filter((dish) => !isNaN(dish.portions || 1))
  return round(Math.max(...data.map((o) => o.portions || 1), 1))
})

const getFirstListIndex = computed(() => {
  return (currentPage.value - 1) * perPage.value
})
const getLastListIndex = computed(() => {
  return currentPage.value * perPage.value
})
const dishesFilterBySliced = computed(() => {
  return dishesFilterByList.value.slice(getFirstListIndex.value, getLastListIndex.value)
})
const dishesFilterByCounted = computed(() => {
  return dishesFilterByList.value.length
})
const calculateHeight = () => {
  if (dynamicContainer.value) {
    const heightDifference = round(dynamicContainer.value.getBoundingClientRect().top + 20)
    if (heightDifference !== height.value) {
      height.value = heightDifference
    }
  }
}
const editLastBlankDish = async () => {
  const latestBlankDish = getLatestBlankDish()
  if (dishesType.value !== 'all') {
    dishesType.value = 'all'
  }

  hideNewDishModal()

  currentPage.value = Math.ceil(latestBlankDish.index / perPage.value)
  await nextTick(() => {
    showDishForm(latestBlankDish.id)
  })
}
const hideNewDishModal = () => {
  createNewDishModal.value = false
}
const addOrRemoveFromList = (valuesList: number[], value: number) => {
  const index = valuesList.indexOf(value)
  if (index === -1) {
    valuesList.push(value)
  } else {
    valuesList.splice(index, 1)
  }
}

const publicDishes = computed(() => {
  return dishes.value.filter((dish) => dish.is_public)
})
const privateDishes = computed(() => {
  return dishes.value.filter((dish) => !dish.is_public)
})
const favoriteDishes = computed(() => {
  return dishes.value.filter((dish) => favoriteDishesIds.value.includes(dish.id))
})
const dishesFilterByList = ref<DishInFormType[]>([...dishes.value])
const parsedDishes = computed(() => {
  return match(dishesType.value)
    .with('is_public', () => publicDishes.value)
    .with('is_private', () => privateDishes.value)
    .with('is_favorite', () => favoriteDishes.value)
    .otherwise(() => dishes.value)
    .map((dish) => {
      const getDishWithIngredients = dishesWithIngredients.value[dish.id.toString()]
      if (getDishWithIngredients) {
        return {
          ...dish,
          ingredients: getDishWithIngredients.ingredients.map(({ ingredient_name }) => ({
            name: ingredient_name
          }))
        }
      }
      return dish
    }) as DishInFormType[]
})

const keysToSearch = computed(() => {
  if (searchByIngredientName.value) {
    return ['ingredients.name']
  }
  return ['name']
})

const searchList = useFuseSearch<DishInFormType>(parsedDishes, searchedPhrase, keysToSearch)

const updateDishesFilterByList = () => {
  const lastAddedDish = dishes.value.find((v) => v.id === lastAddedNewDish.value)
  let dishesList = searchList.value
  if (lastAddedDish) {
    dishesList = dishesList.filter((dish) => dish.id !== lastAddedDish.id)
  }

  if (kcalMinValue.value !== '') {
    dishesList = dishesList.filter((dish) => {
      return dish.calories >= parseFloat(kcalMinValue.value)
    })
  }
  if (kcalMaxValue.value !== '') {
    dishesList = dishesList.filter((dish) => {
      return dish.calories <= parseFloat(kcalMaxValue.value)
    })
  }

  if (preparationTimeMinValue.value !== '') {
    dishesList = dishesList.filter((dish) => {
      return dish.preparation_time >= parseFloat(preparationTimeMinValue.value)
    })
  }
  if (preparationTimeMaxValue.value !== '') {
    dishesList = dishesList.filter((dish) => {
      return dish.preparation_time <= parseFloat(preparationTimeMaxValue.value)
    })
  }
  if (portionsMinValue.value !== '') {
    dishesList = dishesList.filter((dish) => {
      return (dish.portions ?? Number.MIN_SAFE_INTEGER) >= parseFloat(portionsMinValue.value)
    })
  }
  if (portionsMaxValue.value !== '') {
    dishesList = dishesList.filter((dish) => {
      return (dish.portions ?? Number.MAX_SAFE_INTEGER) <= parseFloat(portionsMaxValue.value)
    })
  }
  if (dishSizeForFilters.value.length > 0) {
    dishesList = dishesList.filter((dish) => dishSizeForFilters.value.includes(dish.size))
  }
  if (mealTypesForFilters.value.length > 0) {
    mealTypesForFilters.value.forEach((tag) => {
      dishesList = dishesList.filter((dish) => {
        const getDishWithIngredients = dishesWithIngredients.value[dish.id]
        if (getDishWithIngredients) {
          return (
            getDishWithIngredients.meal_types.includes(tag) ||
            getDishWithIngredients.meal_types.length === 0
          )
        }
        return false
      })
    })
  }
  if (dishTypesForFiltersLength.value > 0) {
    const findInDishType =
      (dish: DishByIngredient) => (element: DishByIngredient['dish_types'][number]) => {
        return dish.dish_types.includes(element)
      }
    dishesList = dishesList.filter((dish) => {
      const dishWithIngredients = dishesWithIngredients.value[dish.id]

      if (dishWithIngredients) {
        if (searchAllTagsStatus.value) {
          return A.every(dishTypesForFilters.value, findInDishType(dishWithIngredients))
        } else {
          return A.some(dishTypesForFilters.value, findInDishType(dishWithIngredients))
        }
      } else {
        return false
      }
    })
  }
  if (lastAddedNewDish.value && lastAddedDish) {
    dishesList.unshift(lastAddedDish)
  }
  dishesFilterByList.value = dishesList
}

const showNewDishModal = async () => {
  try {
    if (blankDishes.value > 4) {
      createNewDishModal.value = true
    } else {
      await showCreateNewDishModal()
    }
  } catch (err) {
    reportError(err, 'Error during showNewDishModal')
  }
}

const showCreateNewDishModal = async () => {
  hideNewDishModal()
  if (!hasAddDishPermission.value) {
    showAppUpgradePlanModal()
  } else {
    currentPage.value = 1
    const { id } = await addNewDishAsync({
      id: 0,
      calories: 0,
      dish_types: [],
      ingredients: [],
      is_public: false,
      max_portions: 1,
      meal_types: [],
      name: 'Potrawa bez nazwy',
      preparation_time: 5,
      preparation_steps: '',
      size: 2,
      type: 'dish',
      used_portions: 1
    })
    lastAddedNewDish.value = id
    updateDishesFilterByList()
    scrollToTop()
  }
}

const copyDish = async (dishId: number | undefined) => {
  if (dishId) {
    if (!hasAddDishPermission.value) {
      showAppUpgradePlanModal()
    } else {
      const copiedDishId = await copyDishAsync(dishId)
      const index = dishesFilterByList.value.findIndex((v) => v.id === dishId) + 1

      if (index % perPage.value === 0) {
        currentPage.value = Math.ceil(index / perPage.value) + 1
      }
      void nextTick(() => {
        currentDishId.value = copiedDishId.id
      })
      if (dishesType.value === 'is_public') {
        const copiedDish = dishes.value.find((v) => v.id === copiedDishId.id)
        if (copiedDish) {
          dishesFilterByList.value.splice(index, 0, copiedDish)
        }
      }
      scrollToTop()
    }
  }
}

const areFiltersActive = (dishesTypeArg: string) => {
  return (
    dishesType.value === dishesTypeArg &&
    (searchedPhrase.value ||
      dishTypesForFiltersLength.value > 0 ||
      mealTypesForFilters.value.length > 0 ||
      dishSizeForFilters.value.length > 0 ||
      kcalMinValue.value ||
      kcalMaxValue.value ||
      preparationTimeMinValue.value ||
      preparationTimeMaxValue.value ||
      portionsMinValue.value ||
      portionsMaxValue.value)
  )
}

const setSearchByIngredientName = () => {
  searchByIngredientName.value = !searchByIngredientName.value
  currentPage.value = 1
  scrollToTop()
}
const resetKcalMinMax = () => {
  kcalMinValue.value = ''
  kcalMaxValue.value = ''
}
const resetPreparationTimeMinMax = () => {
  preparationTimeMinValue.value = ''
  preparationTimeMaxValue.value = ''
}
const resetPortionsMinMax = () => {
  portionsMinValue.value = ''
  portionsMaxValue.value = ''
}

const setDishSizeForFilters = (dishSizeList: number[]) => {
  dishSizeForFilters.value = dishSizeList
  currentPage.value = 1
  scrollToTop()
  hideDishForm()
}
const removeSingleTag = (id: number) => {
  addOrRemoveFromList(dishTypesForFilters.value, id)
}
const setDishTypeForFilters = (list: DishByIngredient['dish_types']) => {
  dishTypesForFilters.value = list
  tagListShown.value = false
}
const setMealTypeForFilters = (mealTypesList: MealTypes) => {
  mealTypesForFilters.value = mealTypesList
}
const setKcalMin = (value: string) => {
  kcalMinValue.value = value
}
const setKcalMax = (value: string) => {
  kcalMaxValue.value = value
}
const setPreparationTimeMin = (value: string) => {
  preparationTimeMinValue.value = value
}
const setPreparationTimeMax = (value: string) => {
  preparationTimeMaxValue.value = value
}
const setPortionsMin = (value: string) => {
  portionsMinValue.value = value
}
const setPortionsMax = (value: string) => {
  portionsMaxValue.value = value
}
const resetFilters = () => {
  resetFiltersValue.value++
  dishTypesForFilters.value = []
  mealTypesForFilters.value = []
  dishSizeForFilters.value = []
  searchedPhrase.value = ''
}
const toggleExtendedFilters = () => {
  if (hasPerm('accounts.diet_extended_search')) {
    extendedFilters.value = !extendedFilters.value
  } else {
    showAppUpgradePlanModal()
  }
}

const getN3N6Ratio = (ratio: string | number | undefined | null) => {
  if (ratio === 0 || ratio === null || ratio === undefined) {
    return '0 : 0'
  } else {
    return ratio
  }
}
const getValue = (value: number | undefined, precision: number) => {
  if (G.isNotNullable(value) && !isNaN(value)) {
    value = round(value, precision)
  } else {
    value = 0
  }
  return value
}
const scrollToTop = () => {
  window.scrollTo(0, 0)
}

const setDishesFilterByType = (type: string) => {
  dishesType.value = type
  resetFilters()
  resetInputedPage()
}

const getNutrientHeader = (nutrient: Nutrients) => {
  let measurement = ''
  if (nutrientsMap[nutrient].measurement) {
    measurement = '(' + nutrientsMap[nutrient].measurement + ')'
  }
  return nutrientsMap[nutrient].shortcut + measurement
}

const resetInputedPage = () => {
  if (inputedPageRef.value) {
    inputedPageRef.value = ''
  }
  inputedPage.value = ''
}

const searchAllTags = (status: boolean) => {
  searchAllTagsStatus.value = status
}
const showDishTagList = () => {
  tagListShown.value = true
}
const hideDishTagList = () => {
  tagListShown.value = false
}

const updateSortedCache = () => {
  queryClient.setQueryData(['dishes', chosenNutrients], (oldData: DishInFormType[]) => {
    return A.sort(oldData, sort)
  })
}

useEventListener('scroll', calculateHeight)

watch(
  [
    searchedPhrase,
    searchAllTagsStatus,
    dishTypesForFilters,
    mealTypesForFilters,
    dishSizeForFilters,
    kcalMinValue,
    kcalMaxValue,
    preparationTimeMinValue,
    preparationTimeMaxValue,
    portionsMinValue,
    portionsMaxValue,
    dishesType,
    searchByIngredientName
  ],
  () => {
    scrollToTop()
    hideDishForm()
    currentPage.value = 1
    lastAddedNewDish.value = undefined
    updateSortedCache()
    updateDishesFilterByList()
  },
  { deep: true }
)

watch([currentPage], () => {
  currentDishId.value = undefined
})

watch([sortBy, sortByReverse], () => {
  lastAddedNewDish.value = undefined
  updateSortedCache()
  dishesFilterByList.value = [...dishesFilterByList.value].sort(sort)
})

watch(
  dishes,
  (next, prev) => {
    if (prev.length !== next.length) {
      updateSortedCache()

      if (dishesType.value !== 'is_public') {
        updateDishesFilterByList()
      }
    } else {
      if (currentDishId.value) {
        dishesFilterByList.value = dishesFilterByList.value.map((dish) => {
          if (dish.id === currentDishId.value) {
            return next.find((d) => d.id === currentDishId.value) || dish
          }
          return dish
        })
      }
    }
  },
  { deep: true }
)
</script>
<style scoped>
.ingredients-list {
  margin: 38px 0 34px;
  min-width: 950px;
}

.column .lock-icon {
  cursor: pointer;
}

.diet-detail-save::before {
  content: '';
  flex: auto;
}

.diet-detail-save {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 10px;
  flex-grow: 1;
  color: #a6a6a6;
  white-space: nowrap;
  position: absolute;
  top: 29px;
  right: 135px;
}

.diet-detail-save .not-saved {
  color: #f7606e;
  font-family: Montserrat-SemiBold;
}

.diet-detail-save :deep(.base-loader),
.diet-detail-save :deep(.base-icon) {
  margin-right: 4px;
}

.column__sortable .base-icon {
  cursor: pointer;
}

.public .base-icon {
  font-size: 21px;
  padding-top: 1px;
}

.empty-results .base-icon {
  margin-right: 5px;
}

.column__sortable.disabled .base-icon {
  display: none;
}

.column {
  text-align: right;
}

.header .column {
  color: var(--color-primary-text);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header .column :deep(.base-icon) {
  margin-left: 4px;
}

.base-button.outlined .base-icon,
.base-icon.outlined {
  font-family: 'Material Symbols Outlined';
}

.ingredient.active .base-icon {
  color: #fff;
}

.column.ingredient__name {
  text-align: left;
  display: block;
  width: 100%;
  color: #2b2b2b;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  padding-right: 10px;
}

.ingredient.active .ingredient__name {
  color: #fff !important;
}

.pagination .navigate_next .base-icon {
  color: #2b2b2b;
}

.base-button.base-button--icon.ingredient-action__delete:hover {
  background-color: #fccfd3;
}

.ingredient-action__delete:hover .base-icon {
  color: #f55f6d !important;
  background-color: #fccfd3;
}

.empty-products span.green .base-icon {
  margin-top: 1px;
}

.ingredient .column.favorite .base-icon {
  color: #a6a6a6;
}

.ingredient:hover .column.favorite .base-icon {
  color: #009b9d;
}

.column__sortable.active,
.header .column .column__sortable.active .base-icon {
  color: var(--color-main-100);
}

.diet-detail-save :deep(.base-loader) .base-loader__circle::before {
  background-color: #ffbd21;
}

.search-box .search-input {
  font-size: 16px;
}

.nav .add-dish {
  margin-left: auto;
}

.nav {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-top: 27px;
  top: 88px;
  z-index: 150;
  background: #f8f8f8;
}

.nav__element {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  padding: 14px 15px 10px;
  text-decoration: none;
  border-bottom: 4px solid transparent;
  cursor: pointer;
}

.nav__name {
  font-family: Montserrat-Medium;
  font-size: 14px;
  line-height: 16px;
  color: #a6a6a6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 5px;
}

.nav__element:hover .nav__name {
  color: #2b2b2b;
}

.nav__element.nav__element--active .nav__name {
  color: #2b2b2b;
}

.base-badge {
  padding: 3px 6px;
}

.nav__element:hover .base-badge {
  background: #2b2b2b;
}

.nav__element.nav__element--active .base-badge {
  background: #009b9d;
}

.nav__element .lock-icon,
.nav__element .favorite-icon {
  color: #a6a6a6;
  cursor: pointer;
}

.nav__element.nav__element--active :deep(.base-icon) {
  color: var(--color-main-100);
}

.nav__drop {
  padding: 4px 15px;
  margin: 4px 0 0;
}

.nav__drop--hover {
  background-color: #eef8f7;
  border-radius: 4px;
  padding: 3px 14px;
  margin: 4px 0;
  border: 1px solid #5dbab3;
}

.nav__drop-name {
  font-size: 9px;
  line-height: 12px;
}

.nav__element--active {
  border-bottom: 4px solid #009b9d;
}

.nav-actions {
  display: flex;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search {
  padding: 28px 28px 28px 42px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  top: 0;
  z-index: 0;
}

.search .row-f {
  display: flex;
  align-items: center;
}

.search .row-f.main-row {
  justify-content: space-between;
}

.search .search-box {
  margin-right: 48px;
}

.search .row-f.extra .base-checkbox {
  white-space: nowrap;
}

.list-and-form {
  display: flex;
  column-gap: 8px;
  max-height: 916px;
  margin-bottom: 17px;
}

.ingredients-list .list-and-form.full {
  max-height: max-content !important;
}

.list {
  background: #fff;
  padding: 0 12px 12px;
  font-size: 12px;
  position: relative;
  margin-bottom: 17px;
  height: max-content;
}

.list-and-form .list.hidden {
  display: none;
}

.list-and-form .list {
  width: 100%;
}

.list-and-form .list.divided {
  width: 40%;
  overflow-x: hidden;
}

.list-and-form :deep(.dish-or-ingredient) {
  max-height: 100%;
}

.dynamic-container {
  position: sticky;
  top: 20px;
  overflow-x: hidden;
  width: 60%;
  display: none;
  scrollbar-width: thin;
}

.dynamic-container.divided {
  display: block;
}

.dynamic-container.divided.full {
  width: 100%;
  position: relative;
  overflow: visible;
  margin-bottom: 17px;
  top: 0;
}

.list-and-form .dish-form :deep(.dish-name) {
  margin-top: 16px;
  width: calc(100% - 144px);
}

.list-and-form .dish-form .menu {
  position: absolute;
  display: flex;
  right: 16px;
  top: 20px;
}

.list-loader {
  background: rgb(255 255 255 / 40%);
  display: flex;
  position: absolute;
  inset: 0;
  align-items: top;
  justify-content: center;
  z-index: 1000;
  cursor: wait;
}

.list-loader-bar {
  background-color: transparent;
  border-radius: 9999px;
  width: calc(100% - 24px);
  margin: 52px 12px;
  height: 2px;
  position: relative;
  overflow: hidden;
}

.list-loader-bar__progress {
  background-color: #009b9d;
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: progress-bar;
}

@keyframes progress-bar {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}

.row {
  display: grid;
  align-items: center;
  grid-column-gap: 10px;
  height: 40px;
  max-height: 40px;
}

.row__actions {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, transparent, var(--color-main-10) 50px);
}

.header.row.toggled {
  display: flex;
  justify-content: space-between;
}

.header.row.toggled .column.header__name {
  margin-left: 38px;
}

.column.settings .base-button.base-button--icon .base-icon {
  color: #a6a6a6;
}

.header.row.toggled .column.extend,
.header.row.toggled .column.extend .base-icon {
  cursor: pointer;
}

.header.row.toggled .column:not(.header__name, .extend) {
  display: none;
}

.ingredient:hover .row__actions {
  display: block;
}

.ingredients .ingredient.row.toggled {
  grid-template-columns: 28px calc(100% - 28px) repeat(9, 0);
}

.ingredients .ingredient.row.toggled .column:not(.ingredient__name, .private),
.ingredients .ingredient.row.toggled .row__actions {
  display: none;
}

.ingredients .ingredient.row.toggled .column.private {
  width: 29.5px;
}

.column-ingredient-6 {
  grid-template-columns: 28px minmax(150px, auto) 55px repeat(6, 100px) 20px 105px;
}

.column-ingredient-5 {
  grid-template-columns: 28px minmax(150px, auto) 55px repeat(5, 100px) 20px 105px;
}

.column-ingredient-4 {
  grid-template-columns: 28px minmax(150px, auto) 55px repeat(4, 100px) 20px 105px;
}

.column-ingredient-3 {
  grid-template-columns: 28px minmax(150px, auto) 55px repeat(3, 100px) 20px 105px;
}

.column-ingredient-2 {
  grid-template-columns: 28px minmax(150px, auto) 55px repeat(2, 100px) 20px 105px;
}

.column-ingredient-1 {
  grid-template-columns: 28px minmax(150px, auto) 55px repeat(1, 100px) 20px 105px;
}

.row__actions.two-items {
  margin-right: 33px;
}

.column.base {
  font-family: Montserrat-Medium;
}

.column__sortable {
  height: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.column__sortable.disabled {
  pointer-events: none;
  margin-right: 14px;
}

.header {
  height: 52px;
  min-height: 52px;
  border-bottom: 2px solid #eaeaea;
  margin-bottom: 12px;
  font-family: Montserrat-Medium;
  color: #009b9d;
  z-index: 150;
  top: 136px;
  background: #fff;
}

.column.settings {
  margin-left: 8px;
}

.header__name {
  justify-content: flex-start !important;
}

.ingredients-empty {
  display: flex;
  gap: 10px;
  padding: 20px 0;
  align-items: center;
}

.ingredients-loader {
  position: absolute;
  inset: 0;
  z-index: 10000;
  background: rgb(255 255 255 / 60%);
  cursor: wait;
}

.ingredient {
  position: relative;
  cursor: pointer;
}

.ingredient.row.inactive .ingredient__name {
  color: rgb(43 43 43 / 70%);
}

.ingredient.active,
.ingredient.active .column.ingredient {
  background: #009b9d;
  color: #fff;
}

.ingredient:hover,
.ingredient--selected {
  background: #e5f5f5;
}

.ingredient.active:hover {
  background: #009b9d;
}

.header-nutrient-name {
  white-space: nowrap;
}

.ingredient__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: 10px;
}

.copy-ingredient-wrapper {
  position: relative;
}

.copy-ingredient-menu {
  top: 12px;
  right: 12px;
}

.ghost-image {
  position: absolute;
  background-color: #5dbab3;
  border-radius: 5px;
  color: #fff;
  font-family: Montserrat-SemiBold;
  font-size: 12px;
  padding: 6px;
  display: flex;
  align-items: center;
  top: -500px;
}

.ghost-image__icon {
  padding-right: 6px;
  color: #fff;
}

.search-buttons {
  display: flex;
  column-gap: 16px;
  margin-left: 24px;
}

.search-buttons.extended {
  position: absolute;
  bottom: 16px;
  right: 16px;
  column-gap: 43px;
}

.empty-results {
  display: flex;
  align-items: center;
}

.izz-confirmed-box {
  min-height: 65px;
}

.izz-confirmed-box,
.izz-unconfirmed-box {
  padding: 8px 18px 16px;
  background: #fff;
  color: #2b2b2b;
  max-width: 460px;
  font-size: 12px;
  font-family: Montserrat-Regular;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.izz-unconfirmed-box {
  padding-top: 18px;
}

.izz-confirmed-box .izz-content {
  line-height: 1.67;
  max-width: 336px;
}

.izz-unconfirmed-box .izz-content {
  line-height: 1.67;
  max-width: 355px;
}

.izz-unconfirmed-box .base-button {
  min-width: 96px;
  margin-left: auto;
  margin-top: -15px;
}

.izz-confirmed-box .base-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.izz-confirmed-box .modal-link,
.izz-unconfirmed-box .modal-link {
  font-family: Montserrat-SemiBold;
  color: #009b9d;
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
}

.izz-unconfirmed-box .modal-link {
  margin-left: 0;
}

.izz-unconfirmed-box .izz-color {
  color: #009b9d;
  font-family: Montserrat-SemiBold;
}

.izz-unconfirmed-box img,
.izz-confirmed-box img {
  height: 60px;
  width: 115px;
  box-sizing: border-box;
  padding-bottom: 9px;
}

.empty-products {
  font-family: Montserrat-Medium;
  border: 2px dashed #009b9d;
  margin: 32px 0 20px;
  padding: 19px 0;
  text-align: center;
  line-height: 1.4;
}

.empty-products span {
  font-family: Montserrat-Bold;
}

.empty-products span.green {
  color: #009b9d;
}

.value-positioned {
  text-align: right;
  margin-right: 23px;
  min-width: 30px;
}

.column__sortable .column-positioned {
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.new-tab-info {
  position: sticky;
  top: 88px;
  z-index: 150;
  background: #fff;
}

.new-tab-info .row-f {
  display: flex;
  align-items: center;
  padding: 0 16px 24px 20px;
  margin: 24px auto 18px;
}

.new-tab-info .margin {
  height: 24px;
  background: #f8f8f8;
}

.new-tab-info.sticky-extended {
  top: 423px;
}

.new-tab-info .text-content {
  font-family: Montserrat-Medium;
  margin: 0 16px;
  font-size: 14px;
  line-height: 22px;
  color: #2b2b2b;
}

.new-tab-info .button {
  font-family: Montserrat-SemiBold;
  color: #2b2b2b;
  background: #f0f0f0;
  margin-right: 16px;
  min-width: 153px;
  min-height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 16px;
  padding: 0 16px;
  cursor: pointer;
  border-radius: 4px;
  text-decoration: none;
  border: 1px solid #f0f0f0;
}

.new-tab-info .button:hover {
  border: 1px solid #8d8d8d;
}

.new-tab-info .close-icon {
  font-size: 32px;
  color: #ccc;
  cursor: pointer;
}

.dish-tags {
  color: #2b2b2b;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  font-family: Montserrat-Medium;
}

.dish-tags span:nth-of-type(2) {
  font-family: Montserrat-Medium;
  color: rgb(43 43 43 / 70%);
  margin: 8px 0 0;
}

.show-tags {
  margin-top: 8px;
  color: #000;
  background: #f0f0f0;
  width: 204px;
  height: 36px;
  border: none;
  font-size: 14px;
  font-family: Montserrat-SemiBold;
  padding: 0 12px 0 10px !important;
}

.dish-tags .base-button i {
  color: #8d8d8d;
  font-size: 20px;
}

.chosen-types {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.chosen-type-box {
  border-radius: 4px;
  border: 1px solid #f0f0f0;
  padding: 1px 12px;
  width: max-content;
  display: flex;
  align-items: center;
  height: 28px;
}

.chosen-type-box:hover {
  background: #f7f7f7;
}

.remove-tag {
  cursor: pointer;
  margin-left: 10px;
  color: rgb(43 43 43 / 36%);
}

.disabled-button {
  pointer-events: none;
}

@media screen and (width <= 1580px) {
  .column-ingredient-6 {
    grid-template-columns: 28px minmax(150px, auto) 74px repeat(6, 74px) 20px 105px;
  }

  .column-ingredient-5 {
    grid-template-columns: 28px minmax(150px, auto) 74px repeat(5, 74px) 20px 105px;
  }

  .column-ingredient-4 {
    grid-template-columns: 28px minmax(150px, auto) 74px repeat(4, 74px) 20px 105px;
  }

  .column-ingredient-3 {
    grid-template-columns: 28px minmax(150px, auto) 74px repeat(3, 74px) 20px 105px;
  }

  .column-ingredient-2 {
    grid-template-columns: 28px minmax(150px, auto) 74px repeat(2, 74px) 20px 105px;
  }

  .column-ingredient-1 {
    grid-template-columns: 28px minmax(150px, auto) 74px repeat(1, 74px) 20px 105px;
  }

  .column__sortable .column-positioned {
    word-break: break-all;
  }
}

@media screen and (width <= 1444px) {
  .nav.sticky-extended {
    top: 388px;
  }

  .nav.sticky-extended.with-dish-types {
    top: 420px;
  }

  .header.sticky-extended {
    top: 435px;
  }

  .header.sticky-extended.with-dish-types {
    top: 466px;
  }
}

.delete {
  color: var(--color-main-100);
}

.ingredient.row--selected {
  background: var(--color-main-10);
}

.ingredient.row:hover .favorite :deep(.base-icon) {
  color: var(--color-main-100);
}
</style>
