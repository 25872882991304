import type { DishSearchByIngredientResponse, DishSearchIngredient } from '@/services/dishService'
import type { DishInFormType } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'
import type { MutateFunction, QueryObserverResult, RefetchOptions } from '@tanstack/vue-query'
import type { ComputedRef, InjectionKey, Ref } from 'vue'

import { inject } from 'vue'

export interface DishListProvider {
  addNewDishAsync: MutateFunction<{ id: number }, Error, DishInFormType>
  chosenNutrients: Ref<Nutrients[]>
  chosenNutrientsLength: Ref<number>
  chosenNutrientsModalVisible: Ref<boolean>
  copyDishAsync: MutateFunction<{ id: number }, Error, number>
  currentDishId: Ref<number | undefined>
  deleteDishAsync: MutateFunction<unknown, Error, number>
  dishToDelete: Ref<number | undefined>
  dishes: Ref<DishInFormType[]>
  dishesWithIngredients: Ref<DishSearchByIngredientResponse>
  dishesWithIngredientsLoaded: Ref<boolean>
  extendedForm: Ref<boolean>
  favoriteDishesIds: Ref<number[]>
  getIconBySort: ComputedRef<
    (by: keyof DishInFormType) => 'expand_less' | 'expand_more' | 'unfold_more'
  >
  hideChosenNutrientsModal: () => void
  hideDishDeleteModal: () => void
  hideDishForm: () => void
  isDishFavorite: (id: number) => boolean
  loadCustomNutrients: (nutrientsList: Nutrients[]) => void
  loading: Ref<boolean>
  refetchDishes: (options?: RefetchOptions) => Promise<QueryObserverResult<DishInFormType[]>>
  searchAllTagsStatus: Ref<boolean>
  searchIngredients: Ref<DishSearchIngredient[]>
  setSortBy: (type: keyof DishInFormType) => void
  showChosenNutrientsModal: () => void
  showDishDeleteModal: (dishId: number) => void
  showDishForm: (dishId: number) => void
  sort: (a: DishInFormType, b: DishInFormType) => number
  sortBy: Ref<keyof DishInFormType>
  sortByReverse: Ref<boolean>
  toggleExtended: (value?: boolean) => void
  toggleFavorite: (id: number) => Promise<void>
}

export const DishListInject = Symbol() as InjectionKey<DishListProvider>

export const useDishListProvider = () => inject(DishListInject) as DishListProvider
