<template>
  <Notivue v-slot="item">
    <Space
      :padding-x="8"
      :padding-y="8"
      :space="6"
      :class="$style.container"
      direction="columns"
      align-y="start"
      align-x="between"
    >
      <Icon
        :class="[
          {
            [$style.success]: item.type === 'success',
            [$style.info]: item.type === 'info',
            [$style.warning]: item.type === 'warning',
            [$style.error]: item.type === 'error'
          }
        ]"
        :size="28"
        :name="myIcons[item.type]"
      />
      <Space
        :fill="true"
        :class="$style.contentContainer"
        align-x="start"
        :space="6"
      >
        <T
          v-if="item.title"
          as="span"
          size="lg"
          type="bold"
        >
          {{ item.title }}
        </T>
        <T
          v-if="item.message"
          as="span"
        >
          {{ item.message }}
        </T>
      </Space>
      <KcalmarIconButton
        :class="$style.closeIcon"
        variant="transparent"
        name="close"
        size="small"
        icon-size="28"
        icon-color="fill400"
        @click="item.clear"
      />
    </Space>
  </Notivue>
</template>

<script lang="ts" setup>
import 'notivue/animations.css'

import type { NotificationType } from 'notivue'

import { Notivue } from 'notivue'
import { useCssModule } from 'vue'

import { Icon } from '@ui/Icon'
import { KcalmarIconButton } from '@ui/KcalmarIconButton'
import { Space } from '@ui/Space'
import { T } from '@ui/T'

const myIcons: Record<NotificationType, RemixIconName> = {
  success: 'checkbox-circle-fill',
  warning: 'question-fill',
  info: 'information-fill',
  'promise-reject': 'alert-fill',
  'promise-resolve': 'checkbox-circle-fill',
  error: 'alert-fill',
  promise: 'information-fill'
}

const $style = useCssModule()
</script>

<style lang="scss" module scoped>
.container {
  box-shadow:
    0 1px 0 0 $colors-alpha-50,
    0 2px 4px -1px $colors-alpha-50;
  border-radius: 15px;
  border: 1px solid $state-base-border;
  background: #fff;
  min-width: 380px;
}

.closeIcon {
  width: 28px;
  height: 28px;
}

.contentContainer {
  max-width: 268px;
}

.success {
  color: $state-success-active;
}

.warning {
  color: $state-highlight-active;
}

.info {
  color: $state-primary-active;
}

.error {
  color: $state-destructive-active;
}
</style>
