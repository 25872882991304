<template>
  <input
    ref="inputRef"
    class="base-input number-input"
    type="number"
    :value="internalValue"
    :step="step"
    @input="inputHandler"
    @blur="blurHandler"
  >
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { useDebounceFn } from '@vueuse/core'
import round from 'lodash/round'
import { onMounted, ref, toRef, watch } from 'vue'

import { clamp } from '@/utils/common'

const props = defineProps({
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  precision: {
    type: Number,
    default: 0
  },
  debounceAfter: {
    type: Number,
    default: 500
  },
  modelValue: {
    type: Number,
    required: true
  },
  step: {
    type: Number,
    default: 1
  },
  getRef: {
    type: Function as PropType<(ref: HTMLInputElement) => void>
  }
})
const clampValue = (value: number) => {
  return clamp(round(value, props.precision), props.min, props.max)
}

const internalValue = toRef(clampValue(props.modelValue))
const lastValue = ref(0)
const inputRef = ref<HTMLInputElement>()

const emit = defineEmits(['update:modelValue', 'inputStatus'])

const updateValue = useDebounceFn((event: Event) => {
  const target = event.target as HTMLInputElement
  lastValue.value = internalValue.value
  if (target.value !== '') {
    const value = Number(target.value)
    const clamped = clampValue(value)
    internalValue.value = value
    internalValue.value = clamped

    emit('update:modelValue', clamped)
  }

  emit('inputStatus', 'finished')
}, props.debounceAfter)

const inputHandler = (event: Event) => {
  emit('inputStatus', 'inprogress')
  void updateValue(event)
}
const blurHandler = (event: Event) => {
  const value = (event.target as HTMLInputElement).value
  if (value === '') {
    // @ts-expect-error
    internalValue.value = value
    internalValue.value = lastValue.value
  }
}

onMounted(() => {
  if (props.getRef && inputRef.value) {
    props.getRef(inputRef.value)
  }
})

watch(
  () => props.modelValue,
  (value) => {
    internalValue.value = value
  }
)
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: textfield;
}
</style>
