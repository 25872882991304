<template>
  <div class="add-dish-or-ingredient">
    <input
      ref="search"
      class="search"
      placeholder="Wpisz nazwę"
      :value="searchValue"
      @input="searchHandler($event)"
      @click.stop.prevent
    >
    <div
      ref="options"
      class="options"
      :class="{
        'options-to-right-edge': optionsToRightEdge
      }"
      @scroll="scrollHandler"
      @keydown="focusSearch"
    >
      <template
        v-if="
          mode !== 'ingredients' &&
            unusedPortionsExist &&
            searchDishesAndIngredientsStatus === 'success'
        "
      >
        <div class="unused-portions-title">
          Niewykorzystane porcje:
        </div>
        <div
          v-for="[hash, dish] in unusedPortions"
          :key="hash"
          class="option"
          tabindex="0"
          @keyup.enter="selectUnusedDishPortion(dish)"
          @click="selectUnusedDishPortion(dish)"
        >
          <div class="unused">
            <BaseIcon
              size="12"
              name="cached"
            />
          </div>
          <div class="name">
            {{ dish.name }}
          </div>
          <div class="properties">
            <div class="portions">
              {{ dish.maxPortions }}P
            </div>
            <div
              v-if="!dish.isPublic"
              class="private"
            >
              <BaseIcon
                size="12"
                name="lock"
              />
            </div>
          </div>
        </div>
        <div class="separator" />
      </template>

      <Space
        v-if="searchDishesAndIngredientsStatus === 'error'"
        align-y="center"
        :padding-y="10"
      >
        <T
          as="span"
          size="sm"
        >
          Nie udało się pobrać wyników
        </T>
        <StyledButton
          size="small"
          @click.stop.prevent="refetchSearchDishesAndIngredients"
        >
          Spróbuj ponownie
        </StyledButton>
      </Space>
      <div
        v-else-if="searchDishesAndIngredientsLoading && !props.searchIngredients"
        class="loading"
      >
        <BaseLoader class="base-loader--size-12" />
        <div class="loading-name">
          Ładowanie wyników
        </div>
      </div>
      <div
        v-else-if="searchResults.length === 0"
        class="no-results"
      >
        Brak wyników
      </div>
      <template v-else>
        <div
          v-for="dishOrIngredient in searchResults"
          :key="`${dishOrIngredient.type}-${dishOrIngredient.id}`"
          class="option"
          tabindex="0"
          @keyup.enter="
            selectDishOrIngredient(
              $event,
              dishOrIngredient.id,
              dishOrIngredient.type,
              dishOrIngredient.name
            )
          "
          @click="
            selectDishOrIngredient(
              $event,
              dishOrIngredient.id,
              dishOrIngredient.type,
              dishOrIngredient.name
            )
          "
        >
          <div class="type">
            <BaseIcon
              size="12"
              :name="getTypeIcon(dishOrIngredient.type)"
            />
          </div>
          <div
            class="name"
            :title="dishOrIngredient.name"
          >
            {{ dishOrIngredient.name }}
          </div>
          <div class="properties">
            <div
              v-if="'max_portions' in dishOrIngredient && dishOrIngredient.max_portions > 1"
              class="portions"
            >
              {{ dishOrIngredient.max_portions }}P
            </div>
            <div
              v-if="!dishOrIngredient.is_public"
              class="private"
            >
              <BaseIcon
                size="12"
                name="lock"
              />
            </div>
            <div
              v-if="dishOrIngredient.is_favourite"
              class="favourite"
            >
              <BaseIcon
                size="12"
                name="star"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { SearchIngredient } from '@/services/dishService'

import { F } from '@mobily/ts-belt'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'

import { T } from '@ui/T'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { useFuseSearch } from '@/hooks/useFuseSearch'
import { useDietDetailProvider } from '@/pages/diets/provider'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import {
  type DietSearchDishOrIngredientWithType,
  type DietSearchDishWithType,
  type DietSearchIngredientWithType,
  type DishSearchFilters,
  type RelatedDish
} from '@/types/Diet'
import { reportError } from '@/utils/reportError'

interface Props {
  dishFlags?: DishSearchFilters
  dishMealType?: number
  mode: 'all' | 'dishes' | 'ingredients'
  relatedDishes?: Record<string, RelatedDish>
  searchIngredients?: SearchIngredient[]
}
const props = defineProps<Props>()

const options = ref<HTMLDivElement>()
const search = ref<HTMLInputElement>()
const relatedDishes = ref(props.relatedDishes)
const dishFlags = ref(props.dishFlags)
const dishMealType = ref(props.dishMealType)
const mode = ref(props.mode)

const emit = defineEmits(['select', 'select-unused'])
const searchValue = ref('')
const searchLimit = ref(50)
const unusedPortions = ref<[string, RelatedDish][]>([])
const unusedPortionsExist = ref(false)
const optionsToRightEdge = ref(false)

const dietStore = useDietsStore()
const globalStore = useGlobalStore()
const { user } = storeToRefs(globalStore)
const { sendProductToFile } = dietStore
const { hasPerm } = globalStore
const {
  searchDishesAndIngredients,
  searchDishesAndIngredientsLoading,
  searchDishesAndIngredientsStatus,
  refetchSearchDishesAndIngredients
} = useDietDetailProvider()

const dishFlagsAsList = computed(() => {
  let value = null
  if (dishFlags.value && dishFlags.value !== undefined) {
    const dishFlagsContainsFilter = Object.values(dishFlags.value).some((v) => v)
    if (dishFlagsContainsFilter) {
      value = Object.entries(dishFlags.value)
    }
  }
  return value
})

const dishesAndIngredients$ = computed(() => {
  let dishes: DietSearchDishWithType[] = []
  let ingredients: DietSearchIngredientWithType[] = []
  if (props.searchIngredients) {
    ingredients = props.searchIngredients.map((_ingredient) => {
      const ingredient = F.coerce<DietSearchIngredientWithType>(_ingredient)

      return { ...ingredient, type: 'ingredient' } as DietSearchIngredientWithType
    })
  } else {
    if (searchDishesAndIngredients.value) {
      console.log(dishes)
      if (mode.value !== 'ingredients') {
        dishes = searchDishesAndIngredients.value.dishes.map((_dish) => {
          const dish = F.coerce<DietSearchDishWithType>({ ..._dish })
          if (typeof dish.is_vegan === 'number') dish.is_vegan = dish.is_vegan === 0
          if (typeof dish.is_vegetarian === 'number') dish.is_vegetarian = dish.is_vegetarian === 0
          if (typeof dish.is_diary_free === 'number') dish.is_diary_free = dish.is_diary_free === 0
          if (typeof dish.is_gluten_free === 'number')
            dish.is_gluten_free = dish.is_gluten_free === 0
          dish.type = 'dish'
          return dish
        })
      }
      if (mode.value !== 'dishes') {
        ingredients = searchDishesAndIngredients.value.ingredients.map((_ingredient) => {
          const ingredient = F.coerce<DietSearchIngredientWithType>(_ingredient)

          return { ...ingredient, type: 'ingredient' } as DietSearchIngredientWithType
        })
      }
    }
  }
  return [...dishes, ...ingredients] as DietSearchDishOrIngredientWithType[]
})
const _searchResult = useFuseSearch(dishesAndIngredients$, searchValue, ['name'])

const searchResults = computed(() => {
  let results = []
  const dishesAndIngredients = [..._searchResult.value]

  dishesAndIngredients.sort((a, b) => {
    // sort by name
    const aName = a.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/\u0142/g, 'l')
    const bName = b.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/\u0142/g, 'l')
    if (aName < bName) return -1
    if (aName > bName) return 1
    return 0
  })
  dishesAndIngredients.sort((a, b) => {
    // sort by type
    if (a.type < b.type) return -1
    if (a.type > b.type) return 1
    return 0
  })
  dishesAndIngredients.sort((a, b) => {
    // sort by favourite
    return -1 * (Number(a.is_favourite) - Number(b.is_favourite))
  })
  dishesAndIngredients.sort((a, b) => {
    // sort favourites by name
    if (a.is_favourite && b.is_favourite && a.type === b.type) {
      const aName = a.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      const bName = b.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      if (aName < bName) return -1
      if (aName > bName) return 1
    }
    return 0
  })

  results = dishesAndIngredients.filter((dishOrIngredient) => {
    let flagCondition = true
    let mealTypeCondition = true

    if (dishFlagsAsList.value && hasPerm('accounts.diet_settings_search_in')) {
      for (const [flagName, flagValue] of dishFlagsAsList.value) {
        const flag = `is_${flagName}` as keyof typeof dishOrIngredient
        if (flagValue && flag in dishOrIngredient && !dishOrIngredient[flag]) {
          flagCondition = false
        }
      }
    }

    if (flagCondition && dishMealType.value) {
      if ('dish_types_ids' in dishOrIngredient) {
        if (
          dishOrIngredient.meal_types_ids.length > 0 &&
          !dishOrIngredient.meal_types_ids.includes(dishMealType.value)
        ) {
          mealTypeCondition = false
        }
      }
    }
    return flagCondition && mealTypeCondition
  })

  return results.slice(0, Math.min(searchLimit.value, _searchResult.value.length))
})

const searchHandler = (event: Event) => {
  searchLimit.value = 50
  searchValue.value = (event.target as HTMLInputElement).value
}

const getTypeIcon = (type: 'dish' | 'ingredient') => {
  if (type === 'dish') return 'restaurant'
  return 'local_grocery_store'
}

const scrollHandler = ({ target }: Event) => {
  const { scrollTop, clientHeight, scrollHeight } = target as HTMLDivElement

  if (Math.ceil(scrollTop + clientHeight) >= scrollHeight * 0.95) {
    searchLimit.value += 50
  }
}

const focusSearch = (event: KeyboardEvent) => {
  if (event.key !== 'Tab' && event.key !== 'Shift' && event.key !== 'Enter') {
    search.value?.focus()
  }
}

const updateUnusedPortions = () => {
  if (relatedDishes.value) {
    unusedPortions.value = Object.entries(relatedDishes.value)
      .filter(([, dish]) => dish.unusedPortions > 0)
      .sort(([, dish1], [, dish2]) => {
        const dish1Name = dish1.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/\u0142/g, 'l')
        const dish2Name = dish2.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/\u0142/g, 'l')
        if (dish1Name < dish2Name) return -1
        if (dish1Name > dish2Name) return 1
        return 0
      })
    if (Object.keys(unusedPortions.value).length > 0) unusedPortionsExist.value = true
  }
}

const selectDishOrIngredient = async (
  _: Event,
  dishOrIngredientId: number,
  dishOrIngredientType: 'dish' | 'ingredient',
  dishOrIngredientName: string
) => {
  emit('select', {
    dishOrIngredientId,
    dishOrIngredientType
  })
  if (dishOrIngredientType === 'ingredient' && user?.value) {
    await sendProductToFile({
      email: user.value.email,
      productName: dishOrIngredientName,
      productId: dishOrIngredientId
    })
  }
}

const selectUnusedDishPortion = (dish: RelatedDish) => {
  if (dish.coordinates[0]?.[0]) {
    emit('select-unused', {
      mealIndex: dish.coordinates[0][0].mealIndex,
      dayIndex: dish.coordinates[0][0].dayIndex,
      dishOrIngredientIndex: dish.coordinates[0][0].dishOrIngredientIndex
    })
  }
}

onMounted(async () => {
  const rect = options.value?.getBoundingClientRect()
  if (rect && rect.right > window.innerWidth) {
    optionsToRightEdge.value = true
  }
  search.value?.focus()
  updateUnusedPortions()

  if (searchDishesAndIngredientsStatus.value === 'error') {
    try {
      await refetchSearchDishesAndIngredients()
    } catch (err) {
      reportError(err, 'Error while fetching dishes and ingredients')
    }
  }
})
</script>

<style scoped>
.add-dish-or-ingredient {
  position: relative;
}

.search {
  width: 100%;
  height: 32px;
  line-height: 18px;
  font-size: 14px;
  padding: 7px 8px;
  background: var(--color-main-10);
  border: 0;
  border-bottom: 1px solid var(--color-main-100);
  border-radius: 4px 4px 0 0;
  outline: 0;
  box-sizing: border-box;
}

.search::placeholder {
  color: var(--color-tertiary-text);
  font-size: 12px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgb(155 155 155 / 50%);
  max-height: 400px;
  overflow: hidden auto;
  scrollbar-width: thin;
  padding: 0;
  width: 400px;
}

.options.options-to-right-edge {
  right: 0;
  left: inherit;
}

.options::-webkit-scrollbar {
  width: 6px;
}

.unused-portions-title {
  font-family: Montserrat-Medium;
  font-size: 12px;
  padding: 4px;
  pointer-events: none;
  user-select: none;
}

.separator {
  height: 1px;
  width: calc(100% + 12px);
  margin-left: -6px;
  background: var(--color-tertiary-text);
}

.loading {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  height: 24px;
  justify-content: left;
  align-items: center;
  color: var(--color-secondary-text);
  width: 326px;
  padding: 0 6px;
  pointer-events: none;
  user-select: none;
}

.loading-name {
  margin-left: 4px;
}

.loading .base-loader__circle::before {
  background-color: var(--color-secondary-text);
}

.no-results {
  height: 24px;
  line-height: 24px;
  width: 326px;
  padding: 0 4px;
  font-size: 12px;
  color: var(--color-secondary-text);
  pointer-events: none;
  user-select: none;
}

.option {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  font-size: 12px;
  height: 24px;
  width: 100%;
  padding: 0 8px;
  z-index: 101;
  cursor: pointer;
  box-sizing: border-box;
}

.option:hover,
.option:focus {
  outline: none;
  background: var(--color-main-10);
}

.type {
  pointer-events: none;
  display: inline-flex;
}

.type .base-icon {
  pointer-events: none;
  color: var(--color-main-100);
}

.unused .base-icon {
  color: var(--color-destructive-100);
}

.name {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 4px;
}

.properties {
  margin-right: 4px;
  display: flex;
  gap: 2px;
  flex-direction: row;
  align-items: center;
}

.properties div {
  display: flex;
  align-items: center;
}

.portions {
  color: var(--color-main-100);
  font-family: Montserrat-Bold;
  font-size: 10px;
}

.private .base-icon,
.favourite .base-icon {
  color: var(--color-tertiary-text);
}
</style>
