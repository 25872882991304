<template>
  <div class="extended-filters">
    <div class="row-f calories">
      <div class="filter">
        <span>Kcal od (min)</span>
        <BaseInputNumber
          class="filter-number"
          :min="kcalMin"
          :max="kcalMax"
          :precision="1"
          :step="0.1"
          :model-value="kcalMinValue"
          @update:model-value="setKcalMin"
        />
      </div>
      <div class="filter">
        <span>Kcal do (max)</span>
        <BaseInputNumber
          class="filter-number"
          :min="kcalMin"
          :max="kcalMax"
          :precision="1"
          :step="0.1"
          :model-value="kcalMaxValue"
          @update:model-value="setKcalMax"
        />
      </div>
    </div>
    <div class="row-f">
      <div class="filter-box category-box">
        <span>Rodzaj produktu</span>
        <BaseCheckbox
          v-for="category in categoryChoices"
          :key="category.value"
          :label="category.text"
          :class="{ disabled: category.disabled }"
          :model-value="category.active"
          @update:model-value="setIngredientType(category.value)"
        />
      </div>
      <div class="filter-box base-box">
        <span>Baza produktów</span>
        <div
          v-for="ingredientBase in ingredientBasesChoices"
          :key="ingredientBase.value"
          class="ingredient-base-row"
        >
          <BaseCheckbox
            :label="ingredientBase.text"
            :class="{ disabled: ingredientBase.disabled }"
            :model-value="ingredientBase.active"
            @update:model-value="setIngredientBase(ingredientBase.value)"
          />
          <div
            v-if="ingredientBase.disabled"
            class="unlock"
            @click="showIngredientsIzzModal()"
          >
            Odblokuj
          </div>
        </div>
      </div>
      <div class="filter-box ingredient-category-box">
        <span>Kategoria</span>
        <div class="filter-box-wrapped">
          <BaseCheckbox
            v-for="ingredientCategory in ingredientCategoriesChoices"
            :key="ingredientCategory.value"
            :label="ingredientCategory.text"
            :model-value="ingredientCategory.active"
            @update:model-value="setIngredientCategory(ingredientCategory.value)"
          />
        </div>
      </div>
      <div class="filter-box tag-box">
        <span>Tagi systemowe</span>
        <BaseCheckbox
          v-for="ingredientTag in ingredientTagsChoices"
          :key="ingredientTag.value"
          :label="ingredientTag.text"
          :model-value="ingredientTag.active"
          @update:model-value="setIngredientTag(ingredientTag.value)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { IngredientType } from '@/types/IngredientList'

import { A, D } from '@mobily/ts-belt'
import { computed, onBeforeMount, ref, watch } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import { useIngredientsProvider } from '@/types/IngredientList'
import { ingredientBases, ingredientCategories, ingredientTags } from '@/utils/common'

interface List {
  text: string
  value: number
}
export interface CategoryChoice {
  active: boolean
  disabled: boolean
  text: string
  value: IngredientType
}
export interface Choice {
  active: boolean
  text: string
  value: string
}

export interface IngredientBaseChoice {
  active: boolean
  disabled: boolean
  text: string
  value: number
}

const {
  kcalMax,
  kcalMin,
  ingredientsType,
  izzConditionsConfirmed,
  showIngredientsIzzModal,
  resetFiltersValue,
  setIngredientCategoryForFilters: _setIngredientCategoryForFilters,
  setIngredientBaseForFilters: _setIngredientBaseForFilters,
  setIngredientTagForFilters: _setIngredientTagForFilters,
  setIngredientTypeForFilters: _setIngredientTypeForFilters,
  setKcalMin: _setKcalMin,
  setKcalMax: _setKcalMax,
  resetKcalMinMax
} = useIngredientsProvider()

const categoryValues = ref<IngredientType[]>([])
const ingredientTagsValues = ref<string[]>([])
const ingredientBasesValues = ref<number[]>([])
const ingredientCategoriesValues = ref<number[]>([])
const ingredientBasesList = ref<List[]>([])
const kcalMinValue = ref(0)
const kcalMaxValue = ref(9999)

const categoryChoices = computed<CategoryChoice[]>(() => {
  const choices: CategoryChoice[] = [
    {
      value: 'is_public',
      text: 'Publiczne',
      active: false,
      disabled: ingredientsType.value === 'is_private' || ingredientsType.value === 'is_public'
    },
    {
      value: 'is_private',
      text: 'Prywatne',
      active: false,
      disabled: ingredientsType.value === 'is_public' || ingredientsType.value === 'is_private'
    },
    {
      value: 'is_favorite',
      text: 'Ulubione',
      active: false,
      disabled: ingredientsType.value === 'is_favorite'
    }
  ]
  return choices.map((choice) => {
    choice.active =
      categoryValues.value.includes(choice.value) || choice.value === ingredientsType.value
    return choice
  })
})

const ingredientTagsChoices = computed(() => {
  const choices: Choice[] = []
  A.forEach(D.keys(ingredientTags), (tag) => {
    choices.push({
      value: tag,
      text: ingredientTags[tag],
      active: ingredientTagsValues.value.includes(tag)
    })
  })

  return choices
})

const ingredientCategoriesChoices = computed(() => {
  return ingredientCategories.map((choice) => {
    const active = ingredientCategoriesValues.value.includes(choice.value)
    return {
      value: choice.value,
      text: choice.text,
      active
    }
  })
})

const ingredientBasesChoices = computed<IngredientBaseChoice[]>(() => {
  return ingredientBases.map((choice) => {
    const active = ingredientBasesValues.value.includes(choice.value)
    const disabled = !izzConditionsConfirmed.value && choice.text === 'IŻŻ'
    return {
      value: choice.value,
      text: choice.text,
      active,
      disabled
    }
  })
})

const setKcalMin = (value: number) => {
  kcalMinValue.value = value
  _setKcalMin(value)
}
const setKcalMax = (value: number) => {
  kcalMaxValue.value = value
  _setKcalMax(value)
}
const addOrRemoveFromList = <T>(valuesList: T[], value: T) => {
  const index = valuesList.indexOf(value)
  if (index === -1) {
    valuesList.push(value)
  } else {
    valuesList.splice(index, 1)
  }
}
const setIngredientTag = (value: string) => {
  addOrRemoveFromList(ingredientTagsValues.value, value)
  _setIngredientTagForFilters(ingredientTagsValues.value)
}
const setIngredientType = (value: string) => {
  addOrRemoveFromList(categoryValues.value, value)
  _setIngredientTypeForFilters(categoryValues.value)
}
const setIngredientCategory = (value: number) => {
  addOrRemoveFromList(ingredientCategoriesValues.value, value)
  _setIngredientCategoryForFilters(ingredientCategoriesValues.value)
}
const setIngredientBase = (value: number) => {
  addOrRemoveFromList(ingredientBasesValues.value, value)
  _setIngredientBaseForFilters(ingredientBasesValues.value)
}
const resetExtendedFilters = () => {
  categoryValues.value = []
  ingredientTagsValues.value = []
  ingredientCategoriesValues.value = []
  ingredientBasesValues.value = []
  kcalMinValue.value = kcalMin.value
  kcalMaxValue.value = kcalMax.value
  resetKcalMinMax()
}

onBeforeMount(() => {
  kcalMaxValue.value = kcalMax.value
  kcalMinValue.value = kcalMin.value
  ingredientBasesList.value = ingredientBases
  ingredientBasesList.value.push({
    value: 7,
    text: 'Kcalmar.pro'
  })
})
watch(ingredientsType, () => {
  resetExtendedFilters()
})
watch(resetFiltersValue, () => {
  resetExtendedFilters()
})
watch(kcalMin, (newValue) => {
  kcalMinValue.value = newValue
})
watch(kcalMax, (newValue) => {
  kcalMaxValue.value = newValue
})
</script>

<style scoped>
.extended-filters {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: 23px;
}

.extended-filters .row-f.calories {
  margin-bottom: 23px;
  column-gap: 16px;
  justify-content: flex-start;
}

.extended-filters .row-f {
  display: flex;
  column-gap: 49px;
}

.extended-filters .filter-box {
  display: flex;
  flex-direction: column;
}

.extended-filters .filter-box .filter-box-wrapped {
  display: flex;
  flex-flow: column wrap;
  max-height: 250px;
  justify-content: space-between;
  column-gap: 49px;
}

.extended-filters .filter-box span,
.extended-filters .filter span {
  margin-bottom: 16px;
  font-size: 14px;
  font-family: Montserrat-Medium;
}

.extended-filters .filter .base-input {
  width: 50px;
  text-align: center;
}

.extended-filters .filter-box .base-checkbox {
  margin-bottom: 19px;
}

.category-box,
.tag-box,
.base-box {
  width: 20%;
}

.ingredient-category-box {
  width: 40%;
}

.ingredient-base-row {
  display: flex;
}

.ingredient-base-row .unlock {
  color: var(--color-destructive-100);
  font-size: 8px;
  font-family: Montserrat-SemiBold;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid var(--color-destructive-100);
  padding: 2px;
  height: 15px;
  margin-left: 8px;
  cursor: pointer;
}
</style>
