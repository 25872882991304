<template>
  <div class="pagination">
    <div class="row-f">
      <IconButton
        :disabled="currentPage === 1"
        name="navigate_before"
        icon-size="18"
        @click.stop="decrementCurrentPage()"
      />
      <div
        v-for="(pageNumber, pageNumberIndex) in getNumbersForPagination"
        :key="`pagination-${pageNumberIndex}`"
        class="number-box"
        :class="{ active: currentPage === pageNumber, disabled: pageNumber === '...' }"
        @click="setCurrentPage(pageNumber), resetInputedPage()"
      >
        {{ pageNumber }}
      </div>
      <IconButton
        :disabled="currentPage === getNumberOfPages"
        name="navigate_next"
        icon-size="18"
        @click.stop="incrementCurrentPage()"
      />
    </div>
    <div class="inputed-page">
      Idź do strony
      <BaseInput
        :get-ref="
          (el) => {
            inputedPageRef = el
          }
        "
        :type="'Number'"
        :min="1"
        :max="getNumberOfPages"
        :placeholder="''"
        :model-value="inputedPage"
        @update:model-value="setCurrentPageDebounced"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { A, N } from '@mobily/ts-belt'
import { useDebounceFn } from '@vueuse/core'
import { computed, ref, toRef } from 'vue'

import BaseInput from '@/components/BaseInput.vue'
import { IconButton } from '@/components/IconButton'

const props = defineProps({
  perPage: {
    type: Number,
    default: 20
  },
  currentPage: {
    type: Number,
    default: 1
  },
  count: {
    type: Number,
    required: true
  }
})

const inputedPageRef = ref<HTMLInputElement>()
const inputedPage = ref('')
const currentPage = toRef(props, 'currentPage')
const emit = defineEmits(['onPageChange'])
const getNumberOfPages = computed(() => {
  return Math.ceil(props.count / props.perPage)
})

const scrollToTop = () => {
  window.scrollTo(0, 0)
}
const decrementCurrentPage = () => {
  if (currentPage.value > 1) {
    setCurrentPage(currentPage.value - 1)
    resetInputedPage()
  }
}
const incrementCurrentPage = () => {
  if (currentPage.value < getNumberOfPages.value) {
    setCurrentPage(currentPage.value + 1)
    resetInputedPage()
  }
}
const resetInputedPage = () => {
  if (inputedPageRef.value) {
    inputedPageRef.value.value = ''
  }
  inputedPage.value = ''
  scrollToTop()
}

const getNumbersForPagination = computed(() => {
  const pagesNumber = getNumberOfPages.value
  const currPage = currentPage.value
  if (pagesNumber <= 7) {
    return A.makeWithIndex(pagesNumber, N.succ)
  } else if (currPage < 5) {
    return [1, 2, 3, 4, 5, '...', pagesNumber]
  } else if (currPage + 4 > pagesNumber) {
    return [
      1,
      '...',
      pagesNumber - 4,
      pagesNumber - 3,
      pagesNumber - 2,
      pagesNumber - 1,
      pagesNumber
    ]
  } else {
    return [1, '...', currPage - 1, currPage, currPage + 1, '...', pagesNumber]
  }
})

const setCurrentPage = (pn: string | number) => {
  let pageNumber = Number(pn)

  if (pageNumber < 1 || isNaN(pageNumber)) {
    pageNumber = 1
  }
  if (pageNumber > getNumberOfPages.value) {
    pageNumber = getNumberOfPages.value
  }
  inputedPage.value = pageNumber.toString()
  scrollToTop()
  emit('onPageChange', pageNumber)
}
const setCurrentPageDebounced = useDebounceFn(setCurrentPage, 500)
</script>
<style scoped>
.pagination {
  margin-top: 12px;
  display: flex;
}

.pagination .row-f {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.pagination .number-box {
  padding: 4px;
  min-width: 20px;
  cursor: pointer;
  font-family: Montserrat-Medium;
}

.pagination .number-box.disabled {
  pointer-events: none;
}

.pagination .number-box.active {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-main-100);
  border-radius: 5px;
  color: #fff;
}

.inputed-page {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-family: Montserrat-Medium;
}

.inputed-page input {
  margin-left: 5px;
  max-width: 35px;
  padding: 5px 2px;
  text-align: center;
}

.inputed-page input::-webkit-outer-spin-button,
.inputed-page input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.inputed-page input[type='number'] {
  appearance: textfield;
}

.pagination .navigate_next .base-icon {
  color: var(--color-primary-text);
}
</style>
