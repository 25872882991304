<template>
  <div class="basic-informations">
    <span class="title">Podstawowe informacje dotyczące produktu</span>
    <div class="row-name">
      <div class="column-f name">
        <div class="label">
          <span>*</span>Nazwa produktu
        </div>
        <BaseInput
          placeholder="Wpisz nazwę produktu"
          max-length="150"
          :model-value="ingredient.name"
          @update:model-value="handleUpdateIngredientName($event)"
        />
      </div>
      <div class="column-f categories">
        <div class="label">
          <span>*</span>Kategoria
        </div>
        <BaseSelect
          class="categories__select"
          :options="ingredientCategories"
          :model-value="ingredient.category"
          @update:model-value="handleUpdateIngredientCategory($event)"
        />
      </div>
      <div class="column-f measurements">
        <div class="label">
          <span>*</span>Jednostka miary
        </div>
        <BaseSelect
          class="categories__select"
          :options="ingredientMeasurements"
          :model-value="ingredient.measurement"
          @update:model-value="handleUpdateIngredientMeasurement($event)"
        />
      </div>
      <div class="column-f grams">
        <div class="label">
          <span>*</span>Waga jednostki miary [g]
        </div>
        <BaseInputNumber
          :type="'Number'"
          :min="1"
          :max="9999"
          :model-value="ingredient.grams"
          @update:model-value="handleUpdateIngredientGrams($event)"
        />
      </div>
    </div>
    <div class="row-tags">
      <div class="subtitle">
        Tagi przypisane do produktu <span>(opcjonalne) :</span>
      </div>
      <div class="row-f">
        <BaseCheckbox
          :label="'Wegański'"
          :model-value="ingredient.is_vegan"
          @update:model-value="handleUpdateIngredientTags($event, 'is_vegan')"
        />
        <BaseCheckbox
          :label="'Wegetariański'"
          :model-value="ingredient.is_vegetarian"
          @update:model-value="handleUpdateIngredientTags($event, 'is_vegetarian')"
        />
        <BaseCheckbox
          :label="'Bezmleczny'"
          :model-value="ingredient.is_diary_free"
          @update:model-value="handleUpdateIngredientTags($event, 'is_diary_free')"
        />
        <BaseCheckbox
          :label="'Bezglutenowy'"
          :model-value="ingredient.is_gluten_free"
          @update:model-value="handleUpdateIngredientTags($event, 'is_gluten_free')"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ListIngredient } from '@/services/ingredientsService'
import type { IngredientCategory, Measurements } from '@/types/Diet'
import type { PropType } from 'vue'

import { toRef } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseInput from '@/components/BaseInput.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseSelect from '@/components/BaseSelect.vue'

export interface Category {
  text: IngredientCategory['name']
  value: IngredientCategory['id']
}
export interface Measurement {
  text: Measurements['name']
  value: Measurements['id']
}

const props = defineProps({
  ingredient: {
    type: Object as PropType<ListIngredient>,
    required: true
  },
  ingredientCategories: {
    type: Array as PropType<Category[]>,
    required: true
  },
  ingredientMeasurements: {
    type: Array as PropType<Measurement[]>,
    required: true
  }
})

const ingredient = toRef(props, 'ingredient')
const ingredientCategories = toRef(props, 'ingredientCategories')
const ingredientMeasurements = toRef(props, 'ingredientMeasurements')

const emit = defineEmits([
  'handleUpdateIngredientName',
  'handleUpdateIngredientGrams',
  'handleUpdateIngredientCategory',
  'handleUpdateIngredientMeasurement',
  'handleUpdateIngredientTags'
])
const handleUpdateIngredientName = (value: string) => {
  emit('handleUpdateIngredientName', value)
}
const handleUpdateIngredientGrams = (value: number) => {
  emit('handleUpdateIngredientGrams', value)
}
const handleUpdateIngredientCategory = (value: boolean) => {
  emit('handleUpdateIngredientCategory', value)
}
const handleUpdateIngredientMeasurement = (value: number) => {
  emit('handleUpdateIngredientMeasurement', value)
}
const handleUpdateIngredientTags = (value: boolean, tagName: string) => {
  emit('handleUpdateIngredientTags', { value, tagName })
}
</script>

<style scoped>
.basic-informations {
  background: #fff;
  padding: 20px 24px 23px 16px;
}

.row-tags .subtitle span {
  color: var(--color-tertiary-text);
}

.basic-informations span.title {
  font-family: Montserrat-Medium;
  font-size: 24px;
}

.basic-informations .row-name {
  display: flex;
  column-gap: 24px;
  margin-top: 19px;
  margin-bottom: 29px;
}

.basic-informations .row-name .column-f {
  display: flex;
  flex-direction: column;
}

.basic-informations .row-name .column-f .label {
  font-size: 10px;
  color: var(--color-tertiary-text);
  margin-bottom: 4px;
}

.basic-informations .row-name .column-f .label span {
  color: var(--color-destructive-100);
  margin-right: 3px;
}

.basic-informations .row-name .column-f.name {
  width: 100%;
}

.basic-informations .row-name .column-f.categories {
  min-width: 200px;
}

.basic-informations .row-name .column-f.measurements {
  min-width: 150px;
}

.basic-informations .row-name .column-f.grams {
  max-width: 150px;
}

.row-tags .subtitle {
  font-family: Montserrat-Medium;
  font-size: 12px;
  color: var(--color-primary-text);
  margin-bottom: 16px;
}

.row-tags .row-f {
  display: flex;
  column-gap: 25px;
}
</style>
