<template>
  <BaseModal
    type="form"
    @close="modalClose"
  >
    <template #title>
      Dodawanie jadłospisu użytkownika
    </template>
    <Form
      v-slot="{ meta, handleSubmit }"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      :on-submit="submit"
    >
      <Space
        :space="6"
        align="stretch"
        fill
      >
        <FormInput
          type="text"
          name="dietName"
          label="Wprowadź nazwę jadłospisu"
          placeholder="Wprowadź nazwę jadłospisu"
          :force-focus="true"
        />
        <FormSelect
          :options="groups"
          name="group"
          label="Wybierz grupę:"
          placeholder="Wybierz lub utwórz grupę dla swojego jadłospisu"
        />
        <Space
          align-x="end"
          direction="columns"
        >
          <StyledButton
            variant="outline"
            @click="modalClose"
          >
            Anuluj
          </StyledButton>
          <StyledButton
            :disabled="!meta.valid"
            @click="handleSubmit(submit)"
          >
            Dodaj jadłospis
          </StyledButton>
        </Space>
      </Space>
    </Form>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { PartialDeep } from 'type-fest'
import type { SubmissionHandler } from 'vee-validate'

import { toTypedSchema } from '@vee-validate/zod'
import { Form } from 'vee-validate'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import * as zod from 'zod'

import BaseModal from '@/components/BaseModal.vue'
import FormInput from '@/components/FormInput.vue'
import FormSelect from '@/components/FormSelect.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { dashboardService } from '@/services/dashboardService'
import { useDashboardStore } from '@/store/dashboardStore'
import { useGlobalStore } from '@/store/globalStore'

const globalStore = useGlobalStore()
const dashboardStore = useDashboardStore()
const router = useRouter()
const formSubmited = ref(false)

const groups = computed(() =>
  dashboardStore.userGroups.map((item) => ({
    label: item.name,
    value: item.id
  }))
)
const emit = defineEmits(['close'])

const schema = zod.object({
  dietName: zod
    .string()
    .max(85, 'To pole może mieć maksymalnie 85 znaków.')
    .min(1, 'To pole jest wymagane'),
  group: zod.number({
    required_error: 'Wybierz opcję',
    invalid_type_error: 'Wybierz opcję'
  })
})
const validationSchema = toTypedSchema(schema)

const initialValues = {
  dietName: '',
  group: 0
}

const modalClose = () => {
  emit('close')
}

const submit: SubmissionHandler<PartialDeep<zod.input<typeof schema>>> = async (values) => {
  if (!formSubmited.value) {
    formSubmited.value = true
    try {
      const response = await dashboardService.addDiet({
        name: values.dietName ?? '',
        group: values.group === 0 ? null : values.group ?? 0
      })

      void router.push({
        name: 'DietDetailWeek',
        params: { dietType: 'user', dietId: response.data.id }
      })
      globalStore.createMessage({ title: 'Dodano jadłospis' })
    } catch (err) {
      console.error(err)
    }
  }
}

onMounted(async () => {
  await dashboardStore.fetchUserGroups()
})
</script>
