<template>
  <div
    class="search-box"
    :class="{ focused: focused }"
  >
    <input
      ref="baseSearchInput"
      class="base-input search-input"
      :placeholder="placeholder"
      :value="internalValue"
      @focus="focused = true"
      @blur="focused = false"
      @input="inputHandler($event)"
    >
    <div class="search-icon">
      <BaseIcon
        name="search"
        type="disabled"
        size="24"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core'
import { onMounted, ref, toRef, watch } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'

const props = defineProps({
  modelValue: {
    required: true,
    type: String
  },
  placeholder: {
    default: '',
    type: String
  },
  debounceAfter: {
    type: Number,
    default: 500
  },
  forceFocus: {
    type: Boolean,
    default: false
  }
})
const baseSearchInput = ref<HTMLInputElement>()
const focused = ref(false)
const internalValue = toRef(props.modelValue)
const emit = defineEmits(['update:modelValue'])

const updateValue = useDebounceFn(() => {
  emit('update:modelValue', internalValue.value)
}, props.debounceAfter)

const inputHandler = (event: Event) => {
  internalValue.value = (event.target as HTMLInputElement).value
  void updateValue()
}

defineExpose({
  focus: () => {
    if (baseSearchInput.value) {
      baseSearchInput.value.focus()
    }
  }
})

onMounted(() => {
  if (props.forceFocus && baseSearchInput.value) {
    baseSearchInput.value.focus()
  }
})

watch(
  () => props.modelValue,
  (value) => {
    internalValue.value = value
  }
)
</script>

<style scoped>
.search-box {
  position: relative;
  font-family: Montserrat-Medium;
  height: 32px;
  width: 100%;
}

.search-input {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 23px;
  padding: 4px 32px 4px 8px;
}

.search-input::placeholder {
  color: var(--color-tertiary-text);
  font-size: 14px;
}

.search-icon {
  position: absolute;
  right: 4px;
  top: 4px;
  color: var(--color-tertiary-text);
}

.focused .search-icon {
  color: var(--color-main-100);
}
</style>
