import type { Nutrients } from './utils/nutrients'

// GLOBAL
export const APP_ERROR_TITLE = 'Wystąpił błąd aplikacji'
export const APP_ERROR_CONTENT = `Odśwież stronę i spróbuj ponownie. W przypadku dodatkowych pytań skontaktuj się z
                                  nami pod adresem kontakt@kcalmar.com.`
export const OFFLINE_ERROR_TITLE = 'Błąd połączenia internetowego'
export const OFFLINE_ERROR_CONTENT = `Ups... Wygląda na to, że nie masz połączenia z internetem. Zmiany wprowadzane w
                                      aplikacji nie zostaną zapisane. Sprawdź połączenie internetowe i spróbuj
                                      odświeżyć stronę.`
export const SERVER_ERROR_TITLE = 'Wystąpił błąd serwera'
export const SERVER_ERROR_CONTENT = `Ups... Wygląda na to, że wystąpił błąd serwera. Zmiany wprowadzane w
                                     aplikacji mogą nie zostać zapisane. Odśwież stronę i spróbuj ponownie. W przypadku
                                     dodatkowych pytań skontaktuj się z nami pod adresem kontakt@kcalmar.com.`

export const BROWSER_ERROR_TITLE = 'Nieprawidłowa przeglądarka'
export const BROWSER_ERROR_CONTENT = `Ups... Twoja przeglądarka nie wspiera technologii Web Worker. Spróbuj użyć innej
                                      przeglądarki. W przypadku dodatkowych pytań skontaktuj się z nami pod adresem
                                      kontakt@kcalmar.com.`

export const WORKER_ERROR_TITLE = 'Wystąpił błąd Web Worker'
export const WORKER_ERROR_CONTENT = `Ups... Wystąpił błąd Web Workera. Aplikacja może nie działać prawidłowo. Odśwież
                                     stronę i spróbuj ponownie. W przypadku dodatkowych pytań skontaktuj się z nami pod
                                     adresem kontakt@kcalmar.com.`

export const daysMap = [
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
  'Niedziela'
] as const
export const DAYS_INDEX = [0, 1, 2, 3, 4, 5, 6] as const

export const hours = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30'
] as const

export const mealTypes = [
  { value: 1, text: 'Śniadanie' },
  { value: 2, text: 'Drugie śniadanie' },
  { value: 3, text: 'Obiad' },
  { value: 4, text: 'Podwieczorek' },
  { value: 5, text: 'Kolacja' },
  { value: 6, text: 'Przekąska' }
] as const

export const DEFAULT_NUTRIENTS: Nutrients[] = [
  'protein',
  'fat',
  'carbohydrates',
  'fiber',
  'carbohydrate_exchanger'
] as const
