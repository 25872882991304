<template>
  <div class="form-field">
    <label class="form-field__label">{{ label }}</label>
    <div
      class="form-field__select"
      :class="{
        'form-field__select--disabled': disabled
      }"
    >
      <div
        tabindex="0"
        class="form-field__select-selected"
        :class="{
          'form-field__select-selected--active': isOpen,
          'form-field__select-selected--disabled': disabled
        }"
        @click="handlePress"
      >
        {{ selectedOption?.label || placeholder || 'Wybierz opcję' }}
        <BaseIcon
          name="expand_more"
          type="secondary"
          class="form-field__select-icon"
        />
      </div>
      <div
        v-if="isOpen"
        class="form-field__options"
      >
        <div
          v-for="(option, index) in options"
          ref="target"
          :key="index"
          class="form-field__option"
          :class="{ 'form-field__option--selected': selectedOption?.value === option.value }"
          @click="choose(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup generic="T extends string | number">
import type { PropType } from 'vue'

import { onClickOutside, useToggle } from '@vueuse/core'
import { useField } from 'vee-validate'
import { computed, ref, toRef } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'

interface Option<T> {
  label: string
  value: T
}
const props = defineProps({
  type: {
    type: String,
    default: 'text'
  },
  options: {
    type: Array as PropType<Option<T>[]>,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  onDisabledPress: {
    type: Function as PropType<() => void>
  }
})
const [isOpen, toggleOpen] = useToggle(false)
const target = ref(null)
const name = toRef(props, 'name')

const { value: inputValue, handleChange } = useField<T>(name)

const choose = (option: Option<T>) => {
  isOpen.value = false
  handleChange(option.value)
}

const selectedOption = computed<Option<T> | undefined>(() => {
  return props.options.find((option) => option.value === inputValue.value)
})

const handlePress = () => {
  if (!props.disabled) {
    toggleOpen()
  } else {
    if (props.onDisabledPress) {
      props.onDisabledPress()
    }
  }
}

onClickOutside(target, () => {
  isOpen.value = false
})
</script>
