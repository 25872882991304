<template>
  <div>
    <div
      v-if="editor"
      class="editor"
    >
      <div class="editor-toolbar">
        <div class="editor-toolbar-actions editor-toolbar-actions__left">
          <BaseEditorButton
            :active="editor.isActive('bold')"
            :icon="'format_bold'"
            :type="'bold'"
            :tooltip="'Pogrubienie'"
            component-name="Bold"
            @action="editor.chain().toggleBold().focus().run()"
          />
          <BaseEditorButton
            :active="editor.isActive('underline')"
            :icon="'format_underlined'"
            :type="'underline'"
            :tooltip="'Podkreślenie'"
            component-name="Underline"
            @action="editor.chain().toggleUnderline().focus().run()"
          />
          <BaseEditorButton
            :active="editor.isActive('strike')"
            :icon="'format_strikethrough'"
            :type="'strike'"
            :tooltip="'Przekreślenie'"
            component-name="Strike"
            @action="editor.chain().toggleStrike().focus().run()"
          />
          <BaseEditorButton
            :icon="'format_color_text'"
            :type="'color'"
            :tooltip="'Kolor tekstu'"
            component-name="Color"
            @action="editor.chain().focus().setColor($event.target.value).run()"
          />
          <BaseEditorButton
            :disabled="listIsActiveInSelection()"
            :active="editor.isActive('bulletList')"
            :icon="'format_list_bulleted'"
            :type="'bulletList'"
            :tooltip="'Lista punktowa'"
            component-name="BulletList"
            @action="editor.chain().focus().toggleBulletList().run()"
          />
          <BaseEditorButton
            :disabled="listIsActiveInSelection()"
            :active="editor.isActive('orderedList')"
            :icon="'format_list_numbered'"
            :type="'orderedList'"
            :tooltip="'Lista numerowana'"
            component-name="OrderedList"
            @action="editor.chain().focus().toggleOrderedList().run()"
          />
          <BaseEditorButton
            :icon="'image'"
            :type="'image'"
            :tooltip="'Wstaw obraz'"
            component-name="Image"
            @action="editor.chain().focus().setImage({ src: $event }).run()"
          />
        </div>
        <div class="editor-toolbar-actions editor-toolbar-actions__right">
          <BaseEditorButton
            :disabled="!editor.can().undo()"
            :icon="'undo'"
            :type="'undo'"
            :tooltip="'Cofnij'"
            component-name="Undo"
            @action="editor.chain().focus().undo().run()"
          />
          <BaseEditorButton
            :disabled="!editor.can().redo()"
            :icon="'redo'"
            :type="'redo'"
            :tooltip="'Ponów'"
            component-name="Redo"
            @action="editor.chain().focus().redo().run()"
          />
          <BaseEditorButton
            :disabled="editor.isEmpty"
            :icon="'delete_forever'"
            :type="'delete_forever'"
            :tooltip="'Usuń komentarz'"
            component-name="Delete"
            @action="editor.commands.clearContent(true)"
          />
        </div>
      </div>
      <EditorContent :editor="editor" />
      <div class="counter">
        {{ editor.storage.characterCount.characters() }}/{{ limit }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Bold from '@tiptap/extension-bold'
import BulletList from '@tiptap/extension-bullet-list'
import CharacterCount from '@tiptap/extension-character-count'
import Color from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import Dropcursor from '@tiptap/extension-dropcursor'
import Gapcursor from '@tiptap/extension-gapcursor'
import HardBreak from '@tiptap/extension-hard-break'
import History from '@tiptap/extension-history'
import Image from '@tiptap/extension-image'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/vue-3'
import { onMounted, toRef, watch } from 'vue'

import BaseEditorButton from '@/components/BaseEditorButton.vue'
import { lastActiveNodes } from '@/utils/tiptap/lastActiveNodes'

const CustomListItem = ListItem.extend({
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitListItem(this.name)
    }
  }
})

const props = defineProps({
  value: {
    type: String,
    required: true
  }
})
const valueProp = toRef(props, 'value')
const emit = defineEmits(['input'])

const limit = 50000
const editor = useEditor({
  content: props.value.replace(/\n/g, '<br/>'),
  extensions: [
    Document,
    Paragraph,
    Text,
    Bold,
    Underline,
    Strike,
    TextStyle,
    Color,
    CustomListItem,
    OrderedList,
    BulletList,
    Image,
    History,
    Gapcursor,
    Dropcursor,
    HardBreak,
    CharacterCount.configure({ limit })
  ],
  onUpdate: (v) => {
    emit('input', v.editor.getHTML())
  }
})

const listIsActiveInSelection = () => {
  if (editor.value) {
    const listActive = editor.value.isActive('bulletList') || editor.value.isActive('orderedList')
    const nodeActive = lastActiveNodes(editor.value.state, 'list')
    if (listActive) {
      return false
    } else {
      if (nodeActive.length === 0) {
        return false
      }
      return true
    }
  }
  return false
}

watch(valueProp, (value) => {
  if (editor.value && editor.value.getHTML() !== value) {
    editor.value.commands.setContent(value, false)
  }
})

onMounted(() => {
  editor.value?.commands.focus()
})
</script>

<style scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.editor {
  width: 100%;
  padding-top: 5px;
}

.editor-toolbar {
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.editor-toolbar-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.editor-toolbar .base-icon {
  color: #6a6a6a;
}

.counter.alert {
  color: var(--color-destructive-110);
}

.counter {
  color: var(--color-main-110);
  text-align: right;
}

:deep(.tiptap) {
  outline: 0;
  padding: 24px;
  border: 1px solid #f0f0f0;
  border-radius: 0 0 8px 8px;
  border-top: 0;
  height: 300px;
  overflow: hidden scroll;
}
</style>
