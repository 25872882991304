import { A, F, S } from '@mobily/ts-belt'
import * as Sentry from '@sentry/vue'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { createNotivue } from 'notivue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'

import App from '@/App.vue'
import { router } from '@/router'

import { authService } from './services/authService'
import { queryClient } from './services/queryClient'

import 'v-calendar/style.css'
import 'virtual:uno.css'
import 'normalize.min.css'
import '@/assets/css/variables.css'

import { G } from '@mobily/ts-belt'

const app = createApp(App)
const pinia = createPinia()
const notivue = createNotivue({
  position: 'bottom-right',
  enqueue: true,
  limit: 3,
  avoidDuplicates: true,
  transition: 'transform 0.35s cubic-bezier(0.5, 1, 0.25, 1)'
})

pinia.use(({ options, store: piniaStore }) => {
  if (options.debounce) {
    // we are overriding the actions with new ones
    return Object.keys(options.debounce).reduce(
      (debouncedActions: Record<string, unknown>, action) => {
        if (options.debounce && action in options.debounce) {
          debouncedActions[action] = F.debounce(piniaStore[action], options.debounce[action] ?? 0)
        }
        return debouncedActions
      },
      {}
    )
  }
  return options
})
app.use(notivue)
app.use(router)
app.use(pinia)
app.use(VueQueryPlugin, {
  queryClient
})

const ALLOWED_ATTRIBUTES = [
  'sentry',
  'test-id',
  'testId',
  'cy',
  'component',
  'qa',
  'data-component'
]

Sentry.init({
  Vue: app,
  dsn: 'https://32825b732cc74ddf0cce5f3434a08e80@s3n7ry.hermax.pl/3',
  environment: import.meta.env.VUE_APP_SENTRY_ENV,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.extraErrorDataIntegration()
  ],
  ignoreErrors: ['timeout exceeded', 'Request failed with status code 401'],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  trackComponents: true,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category?.startsWith('ui') && hint) {
      let target = (hint.event as Event).target as HTMLInputElement

      if (breadcrumb.category === 'ui.click') {
        const descriptors = []
        const msg = '<Clicks on:'

        let parentsVisited = 0
        let attributes = ''

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        while (target && parentsVisited < 10) {
          if (
            target.localName &&
            (target.localName == 'a' || target.localName.includes('button'))
          ) {
            // Collect inner text, to try to identify the button
            descriptors.push(target.localName)
            if (target.innerText) {
              descriptors.push(`"${target.innerText}"`)
            }
          }

          if (target.localName == 'input') {
            descriptors.push(target.localName)
            if (target.placeholder.length > 0) {
              descriptors.push(`"${target.placeholder}"`)
            }
            if (target.accept.length > 0) {
              descriptors.push(`"${target.accept}"`)
            }
          }
          Object.keys(target.dataset)
            .filter((v) => ALLOWED_ATTRIBUTES.includes(v))
            .forEach((key) => {
              attributes += `[data-${key}="${target.dataset[key]}"]`
            })

          target = target.parentNode as HTMLInputElement
          parentsVisited++
        }
        if (S.isNotEmpty(attributes) || A.isNotEmpty(descriptors)) {
          breadcrumb.message = `${msg} ${descriptors.join(' ')} ${attributes}>`
        }
      }
    }

    return breadcrumb
  },
  tunnel: import.meta.env.VUE_APP_SENTRY_TUNNEL
})

app.mount('#app')

window.addEventListener('storage', (event) => {
  if ((event.key === 'accessToken' || G.isNullable(event.key)) && G.isNullable(event.newValue)) {
    authService.deleteTokens()
    authService.redirectToLogoutPage()
  }
})
