<template>
  <div
    v-cloak
    id="app-layout"
    class="app-layout"
  >
    <div
      class="app-nav"
      :class="[collapsed]"
    >
      <div class="app-nav-header">
        <router-link
          class="app-nav-logo"
          :to="{ name: 'DashboardBase' }"
        >
          <img
            src="@/assets/img/logo.png"
            alt="Kcalmar logo"
          >
        </router-link>
        <button
          class="app-nav-hamburger"
          @click="toggleCollapsed"
        >
          <BaseIcon
            size="24"
            variant="outline"
            name="keyboard_double_arrow_left"
          />
        </button>
        <div
          v-cloak
          class="app-nav-profile"
        >
          <div class="app-nav-avatar">
            <div
              class="avatar"
              :style="{ backgroundImage: userAvatarUrl }"
              alt="Avatar"
            />
            <div
              v-if="user?.is_vip"
              class="app-nav-avatar-vip"
            >
              <BaseIcon
                name="star"
                size="16"
              />
            </div>
          </div>
          <div class="app-nav-name">
            <a :href="`${domain}/dietetyk/account/info/`">
              {{ user?.first_name ?? '' }} {{ user?.last_name ?? '' }}
            </a>
          </div>
          <div class="app-nav-email">
            <a :href="`${domain}/dietetyk/account/info/`">
              {{ user?.email ?? '' }}
            </a>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="app-nav-links">
          <router-link
            :class="{ 'router-link-forced-inactive': route.params.dietType === 'patient' }"
            :to="{ name: 'Diet' }"
          >
            <BaseIcon
              size="24"
              name="assignment"
            /> <span>Jadłospisy</span>
          </router-link>
          <router-link :to="{ name: 'DishesList' }">
            <BaseIcon
              size="24"
              name="restaurant_menu"
            /> <span>Potrawy</span>
          </router-link>
          <router-link :to="{ name: 'IngredientsList' }">
            <BaseIcon
              size="24"
              name="local_grocery_store"
            /> <span>Produkty</span>
          </router-link>
          <a
            :class="{ 'router-link-active': route.params.dietType === 'patient' }"
            :href="`${domain}/dietetyk/patient/table/`"
          >
            <BaseIcon
              size="24"
              name="group"
            /> <span>Pacjenci</span>
          </a>
          <a :href="`${domain}/dietetyk/szkolenia/aplikacja/koszyk/2023/`">
            <BaseIcon
              size="24"
              name="movie_filter"
            /> <span>Szkolenia</span>
          </a>
          <a :href="`${domain}/dietetyk/profil/`">
            <BaseIcon
              size="24"
              name="public"
            /> <span>Profil publiczny</span>
          </a>
          <a
            :href="`${domain}/dietetyk/zapytaj-dietetyka/lista/`"
            :title="
              user?.counters.questions ?? 0 > 0 ? `Nowe pytania: ${user?.counters.questions}` : ''
            "
          >
            <div class="relative">
              <BaseIcon
                size="24"
                name="sms"
              />
              <div
                v-if="user?.counters.questions ?? 0 > 0"
                class="dot"
              />
            </div>
            <span>Zapytaj dietetyka</span>
          </a>
          <a
            v-if="user?.is_leader"
            :href="`${domain}/dietetyk/university/`"
          >
            <BaseIcon
              name="school"
              size="24"
            /> <span>Uczelnie</span>
          </a>
          <a
            v-if="user?.is_internal_dietitian"
            :href="`${domain}/dietetyk/nutrition/program/public/`"
          >
            <BaseIcon
              name="folder_shared"
              size="24"
            />
            <span>Publiczne programy <br>
              odżywiania</span>
          </a>
        </div>
        <div class="app-nav-footer">
          <div
            class="item"
            :class="{ active: showContactInfo }"
            @click="toggleShowContactInfo"
          >
            <BaseIcon
              size="24"
              name="call"
            />
            <span>Kontakt</span>
          </div>
          <a
            class="item"
            :href="`${domain}/dietetyk/account/info/`"
          >
            <BaseIcon
              size="24"
              name="settings"
            /> <span>Ustawienia</span>
          </a>
        </div>
      </div>
    </div>
    <div class="app-content">
      <div
        v-if="showContactInfo"
        class="contact-info-overlay"
        :class="{ 'menu-collapsed': collapsed === 'collapsed' }"
        @click="toggleShowContactInfo"
      >
        <div
          class="contact-info-box"
          @click.stop
        >
          <span class="title">
            Kontakt
            <BaseIcon
              name="close"
              @click="toggleShowContactInfo"
            />
          </span>
          <span>Jesteśmy do Twojej dyspozycji<br>od poniedziałku do piątku<br>w godz. 8:00-16:00 w
            dni robocze.</span>
          <span>Twój osobisty doradca:</span>
          <span>
            Katarzyna | 782 093 159<br>
            <a href="mailto:kkolasa@kcalmar.com">kkolasa@kcalmar.com</a>
          </span>
          <span>
            +48 783 539 157<br>
            <a href="mailto:kontakt@kcalmar.com">kontakt@kcalmar.com</a>
          </span>
        </div>
      </div>
      <div class="app-content-header">
        <AppBreadcrumbs />
        <div id="app-content-header-portal" />
        <AppLogout />
      </div>
      <router-view />
    </div>
    <AppUpgradePlanModal />
    <AppMessages />
    <KcalmarNotification />
    <div id="base-modal-portal" />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'

import '@/assets/css/base.css'

import { KcalmarNotification } from '@ui/KcalmarNotification'

import AppBreadcrumbs from '@/components/AppBreadcrumbs.vue'
import AppLogout from '@/components/AppLogout.vue'
import AppMessages from '@/components/AppMessages.vue'
import AppUpgradePlanModal from '@/components/AppUpgradePlanModal.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import { useGlobalStore } from '@/store/globalStore'

type Collapsed = 'not-collapsed' | 'collapsed'

const globalStore = useGlobalStore()
const user = computed(() => globalStore.user)
const collapsed = ref<Collapsed>(
  (localStorage.getItem('navCollapsed') as Collapsed) ?? 'not-collapsed'
)
const showContactInfo = ref(false)
const userAvatarUrl = computed(() => {
  const url =
    user.value?.avatar ?? new URL('@/assets/img/leftnav/default_avatar.png', import.meta.url).href
  return `url(${url})`
})

const toggleCollapsed = () => {
  if (collapsed.value === 'not-collapsed') {
    collapsed.value = 'collapsed'
  } else {
    collapsed.value = 'not-collapsed'
  }
  localStorage.setItem('navCollapsed', collapsed.value)
}

const toggleShowContactInfo = () => {
  showContactInfo.value = !showContactInfo.value
}

const domain = import.meta.env.VUE_APP_HOST
const route = useRoute()
onMounted(() => {
  if (window.innerWidth < 1200) {
    collapsed.value = 'collapsed'
  }
})
</script>

<style>
.app-layout {
  display: flex;
}

.app-nav {
  position: sticky;
  top: 0;
  width: 220px;
  min-width: 220px;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.app-nav .content {
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  padding-bottom: 16px;
  scrollbar-width: thin;
  flex-grow: 1;
  flex-shrink: 100;
}

.app-nav .content::-webkit-scrollbar {
  width: 8px;
}

.app-nav-header {
  background: var(--color-main-100);
  height: auto;
  min-height: 204px;
  padding-bottom: 16px;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.app-nav-logo {
  position: absolute;
  top: 16px;
  left: 16px;
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  display: block;
  width: 34px;
  height: 64px;
  transition: all 0.3s ease-in-out;
}

.app-nav-logo img {
  width: 34px;
  height: 60px;
  -webkit-user-drag: none;
  user-select: none;
}

.app-nav-hamburger {
  position: absolute;
  right: 0;
  top: 16px;
  border-radius: 4px 0 0 4px;
  display: block;
  background-color: #2aacad;
  width: 24px;
  height: 24px;
  padding: 0;
  cursor: pointer;
  border: 0;
}

.app-nav-hamburger .base-icon {
  color: #fff;
  cursor: pointer;
}

.app-nav-profile {
  padding: 81px 16px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.app-nav-profile a {
  color: #fff;
  text-decoration: none;
}

.app-nav-avatar {
  height: 64px;
  width: 64px;
  border-radius: 29px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.app-nav-avatar a {
  position: relative;
}

.app-nav-avatar .avatar {
  width: 64px;
  height: 64px;
  background-size: cover;
  background-position: center;
  border-radius: 32px;
  overflow: hidden;
  border: 2px solid #f0f0f0;
  box-sizing: border-box;
}

.app-nav-avatar-vip {
  position: absolute;
  right: -3px;
  bottom: -2px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: var(--color-main-10);
  text-align: center;
}

.app-nav-avatar-vip .base-icon {
  font-size: 16px;
  position: relative;
  top: 2px;
  color: #facc14;
}

.app-nav-name {
  font-family: Montserrat-SemiBold;
  color: #fff;
  font-size: 14px;
  margin-top: 17px;
  line-height: 22px;
  transition: all 0.3s ease-in-out;
  word-break: break-word;
}

.app-nav-email {
  font-family: Montserrat-Medium;
  color: #fff;
  font-size: 12px;
  margin-top: 4px;
  line-height: 16px;
  transition: all 0.3s ease-in-out;
  word-break: break-word;
}

.app-nav-links {
  flex-grow: 1;
  margin-top: 16px;
}

.app-nav-footer .item {
  cursor: pointer;
}

.app-nav-links a,
.app-nav-footer .item {
  display: flex;
  height: 44px;
  font-size: 14px;
  align-items: center;
  text-decoration: none;
  color: rgb(43 43 43 / 70%);
  letter-spacing: -0.084px;
  padding-left: 10px;
  box-sizing: border-box;
  white-space: nowrap;
  font-family: Montserrat-Medium;
  width: 204px;
  margin: 0 auto 2px;
  border-radius: 8px;
}

.app-nav-consultant-contact,
.app-nav-consultant-contact a {
  color: var(--color-tertiary-text);
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.app-content .contact-info-box a {
  text-decoration: none;
  color: inherit;
}

.app-nav-links span,
.app-nav-footer .item span {
  display: inline-block;
  margin-left: 8px;
  line-height: 1.5;
}

.app-content .contact-info-box span:nth-child(2) {
  margin-bottom: 8px;
  line-height: 1.58;
}

.app-content .contact-info-box span:nth-child(3) {
  margin-bottom: 8px;
  line-height: 1.58;
  color: var(--color-main-100);
}

.app-content .contact-info-box span:nth-child(4) {
  margin-bottom: 8px;
  line-height: 1.58;
}

.app-content .contact-info-box span:nth-child(5) {
  line-height: 1.58;
}

.app-nav-links a:hover,
.app-nav-footer .item:hover {
  background-color: #f7f7f7;
}

.app-nav-links a:active,
.app-nav-footer .item:active {
  background-color: #55bcbe;
  color: #fff;
}

.app-nav.collapsed .app-nav-links a,
.app-nav.collapsed .app-nav-footer .item {
  width: 44px;
  padding-left: 0;
  justify-content: center;
}

.app-nav-links .base-icon,
.app-nav-footer .item .base-icon {
  color: rgb(43 43 43 / 40%);
  font-size: 24px;
  cursor: pointer;
}

.app-nav-footer .item.active {
  background-color: var(--color-main-100);
  color: #fff;
}

.app-nav-links a:active .base-icon,
.app-nav-footer .item:active .base-icon {
  color: #fff;
}

.app-nav-footer .item.active .base-icon {
  color: #fff;
}

.app-nav-links a.router-link-active:not(.router-link-forced-inactive) {
  background-color: var(--color-main-100);
  color: #fff;
}

.app-nav-links a.router-link-active:not(.router-link-forced-inactive):hover,
.app-nav-footer .item.active:hover {
  background-color: var(--color-main-100);
}

.app-nav-consultant-title {
  color: var(--color-main-100);
  margin-bottom: 8px;
  white-space: nowrap;
  transition: all 0.3s ease-in-out;
}

.app-nav-consultant-contact {
  margin-bottom: 8px;
}

.app-nav-consultant-contact:last-child {
  margin-bottom: 0;
}

/* Collapsed classes */
.app-nav.collapsed {
  width: 60px;
  min-width: 60px;
}

.app-nav.collapsed .app-nav-hamburger {
  transform: rotate(180deg);
  border-radius: 0 4px 4px 0;
}

.app-nav.collapsed .app-nav-logo {
  top: 70px;
  left: 13px;
}

.app-nav.collapsed .app-nav-profile {
  padding: 154px 16px 10px;
}

.app-nav.collapsed .app-nav-avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.app-nav.collapsed .app-nav-avatar .avatar {
  width: 40px;
  height: 40px;
}

.app-nav.collapsed .app-nav-avatar-vip {
  height: 18px;
  width: 18px;
  border-radius: 9px;
}

.app-nav.collapsed .app-nav-avatar-vip .base-icon {
  font-size: 14px;
  top: 2px;
  left: 1px;
}

.app-nav.collapsed .app-nav-name {
  display: none;
}

.app-nav.collapsed .app-nav-email {
  display: none;
}

.app-nav.collapsed .app-nav-links span,
.app-nav.collapsed .app-nav-footer .item span {
  opacity: 0;
  width: 0;
  margin-left: 0;
}

.app-nav.collapsed .app-nav-consultant-title {
  opacity: 0;
}

.app-nav.collapsed .app-nav-consultant-contact {
  opacity: 0;
}

.app-content {
  background: #f8f8f8;
  flex-grow: 1;
  padding: 0 24px;
}

.app-content-header {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 24px 0;
  align-items: center;
}

#app-content-header-portal {
  flex-grow: 1;
  min-width: 300px;
}

.app-content-header .app-breadcrumbs {
  max-width: 742px;
}

.app-content .contact-info-box {
  position: absolute;
  width: 286px;
  height: auto;
  background: #fff;
  border-radius: 16px;
  z-index: 9;
  bottom: 64px;
  left: 24px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  font-family: Montserrat-Medium;
  font-size: 14px;
  box-shadow: 0 1.75px 4px -1px rgb(0 0 0 / 14%);
}

.app-content .contact-info-box .title {
  font-size: 16px;
  margin-bottom: 12px;
  height: 24px;
  line-height: 24px;
  display: flex;
  justify-content: space-between;
}

.app-content .contact-info-box .title .base-icon {
  color: var(--color-primary-text);
  cursor: pointer;
}

.app-nav-links a.router-link-active:not(.router-link-forced-inactive) .base-icon {
  color: #fff;
}

.contact-info-overlay {
  position: fixed;
  inset: 0 0 0 220px;
  background-color: rgb(0 0 0 / 30%);
  z-index: 99993;
  transition: all 0.3s ease-in-out;
}

.contact-info-overlay.menu-collapsed {
  left: 60px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--color-main-100);
  position: absolute;
  right: 0;
  bottom: -1px;
  border: 2px solid #fff;
}

.relative {
  position: relative;
}

@media (width >= 1366px) {
  .app-content-header .app-breadcrumbs {
    max-width: 828px;
  }
}

@media (width >= 1536px) {
  .app-content-header .app-breadcrumbs {
    max-width: 998px;
  }
}

.app-content-header .app-logout {
  width: 100px;
}
</style>
