
<template>
  <div class="app-breadcrumbs">
    <template
      v-for="(breadcrumb, breadcrumbIndex) in breadcrumbs"
      :key="`separator-${breadcrumbIndex}`"
    >
      <div class="app-breadcrumb">
        <BaseTooltip
          v-if="breadcrumb.tooltip"
          class="base-tooltip--bottom base-tooltip--center"
        >
          <a
            v-if="breadcrumb.href"
            :href="breadcrumb.href"
            class="breadcrumb-name"
            :class="{ shorter: shorterBreadcrumbs }"
          >
            {{ breadcrumb.name }}
          </a>
          <router-link
            v-else-if="breadcrumb.to"
            :to="breadcrumb.to"
            class="breadcrumb-name"
            :class="{ shorter: shorterBreadcrumbs }"
          >
            {{ breadcrumb.name }}
          </router-link>
          <template v-else>
            <div
              class="breadcrumb-name"
              :class="{
                shorter: shorterBreadcrumbs,
                'not-last': breadcrumbIndex !== breadcrumbs.length - 1
              }"
            >
              {{ breadcrumb.name }}
            </div>
          </template>
          <template #tooltip>
            {{ breadcrumb.tooltip }}
          </template>
        </BaseTooltip>
        <template v-else>
          <div
            class="breadcrumb-name"
            :class="{ 'not-last': breadcrumbIndex !== breadcrumbs.length - 1 }"
          >
            {{ breadcrumb.name }}
          </div>
        </template>
      </div>
      <div
        v-if="breadcrumbIndex < breadcrumbs.length - 1"
        class="app-breadcrumb-separator"
      >
        /
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useRoute } from 'vue-router'

import BaseTooltip from '@/components/BaseTooltip.vue'
import { daysMap } from '@/const'
import { useDietsStore } from '@/store/dietsStore'

const domain = import.meta.env.VUE_APP_HOST

const route = useRoute()
const dietStore = useDietsStore()
const { diet, patientId, patientName } = storeToRefs(dietStore)
const dietName = computed(() => diet.value?.data.name ?? '')

const dietGroupName = computed(() => {
  if (diet.value && route.params.dietType !== 'patient' && route.params.dietType !== 'system') {
    if ('group_name' in diet.value) {
      return (diet.value.group_name as string) ?? 'Katalog główny'
    }
  }
  return ''
})
const shorterBreadcrumbs = computed(() => {
  return route.params.dietType === 'patient'
})
const breadcrumbs = computed(() => {
  const breadcrumbsList: Breadcrumbs[] = []
  if (route.path === '/dashboard') {
    return [
      {
        name: 'Dashboard'
      }
    ]
  }
  if (route.matched.some((v) => v.name?.toString().includes('IngredientsList'))) {
    breadcrumbsList.push({ name: 'Produkty' })
  }
  if (route.matched.some((v) => v.name?.toString().includes('DietList'))) {
    breadcrumbsList.push({ name: 'Jadłospisy' })
  }
  if (route.matched.some((v) => v.name?.toString().includes('DishesList'))) {
    breadcrumbsList.push({ name: 'Potrawy' })
  }
  if (route.matched.some((v) => v.name === 'DietDetail')) {
    if (route.params.dietType === 'patient') {
      breadcrumbsList.push({
        name: 'Pacjenci',
        href: `${domain}/dietetyk/patient/table/`,
        tooltip: 'Przejdź do listy Pacjentów'
      })
      breadcrumbsList.push({
        name: patientName.value ?? '',
        href: `${domain}/dietetyk/patient/${patientId.value}/detail/status/`,
        tooltip: 'Przejdź do zakładki Pacjenta'
      })
      breadcrumbsList.push({
        name: 'Jadłospisy',
        href: `${domain}/dietetyk/patient/${patientId.value}/detail/diets/`,
        tooltip: 'Przejdź do listy jadłospisów Pacjenta'
      })
    } else {
      breadcrumbsList.push({
        name: 'Jadłospisy',
        to: {
          path: `/diet/${route.params.dietType}`
        },
        tooltip: 'Przejdź do listy jadłospisów'
      })
    }
    if (dietGroupName.value) {
      breadcrumbsList.push({
        name: dietGroupName.value,
        tooltip: dietGroupName.value
      })
    }
    if (dietName.value) {
      if (route.name === 'DietDetailDay') {
        breadcrumbsList.push({
          name: dietName.value,
          to: {
            name: 'DietDetailWeek',
            params: {
              dietType: route.params.dietType,
              dietId: route.params.dietId
            }
          },
          tooltip: 'Przejdź do widoku tygodniowego'
        })
        breadcrumbsList.push({
          name: daysMap[parseInt(route.params.dayIndex as string)] ?? ''
        })
      } else {
        breadcrumbsList.push({
          name: dietName.value,
          tooltip: dietName.value
        })
      }
    }
    if (route.name === '') {
      breadcrumbsList.push({
        name: 'Dashboard'
      })
    }
  }
  return breadcrumbsList
})
</script>

<style>
.app-breadcrumbs {
  font-family: Montserrat-Medium;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.app-content-header .app-breadcrumbs {
  overflow: inherit;
}

.app-breadcrumb a {
  display: block;
  text-decoration: none;
  color: var(--color-tertiary-text);
}

.app-breadcrumb .breadcrumb-name {
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.app-breadcrumb .breadcrumb-name.not-last {
  color: var(--color-tertiary-text);
}

@media (width <= 1366px) {
  .app-breadcrumb .breadcrumb-name.shorter {
    max-width: 105px;
  }
}

@media (width >= 1367px) {
  .app-breadcrumb .breadcrumb-name {
    max-width: 310px;
  }

  .app-breadcrumb .breadcrumb-name.shorter {
    max-width: 150px;
  }
}

@media (width >= 1536px) {
  .app-breadcrumb .breadcrumb-name {
    max-width: 400px;
  }
}

.app-breadcrumb-separator {
  margin: 0 6px;
  color: var(--color-tertiary-text);
}

.app-breadcrumb-separator:nth-last-child(2) {
  color: var(--color-primary-text);
}
</style>
