<template>
  <RouterView />
</template>

<script lang="ts" setup>
import { RouterView, useRoute } from 'vue-router'
import { watch, onMounted, onBeforeMount, onBeforeUnmount, onErrorCaptured } from 'vue'

import { APP_ERROR_CONTENT, APP_ERROR_TITLE } from '@/const'
import eventBus from '@/eventBus'

import { useGlobalStore } from './store/globalStore'
import { checkOutdatedBrowser } from './utils/vue/checkBrowserVersion'

const { checkSavedChosenNutrientsList, createMessage } = useGlobalStore()
const route = useRoute()

watch(
  () => route,
  (to) => {
    let title: string = (to.meta.title as string) || ''
    if (title === '') {
      title = import.meta.env.VUE_APP_TITLE
    } else {
      title = `${to.meta.title as string} - ${import.meta.env.VUE_APP_TITLE}`
    }
    document.title = title
  },
  { immediate: true }
)

onMounted(() => {
  checkOutdatedBrowser()
  checkSavedChosenNutrientsList()
})

onBeforeMount(() => {
  eventBus.on('showError', ({ error, title, content, timeout }) => {
    if (!title) {
      title = error?.code !== undefined ? error.code : error?.name ?? 'Error'
      content = error?.message
    }
    createMessage({
      type: 'error',
      title,
      content,
      timeout
    })
  })
})
onBeforeUnmount(() => {
  eventBus.off('showError')
})
onErrorCaptured((error, _, info) => {
  eventBus.emit('showError', {
    title: APP_ERROR_TITLE,
    content: `${APP_ERROR_CONTENT}<br/><br/>Error: ${JSON.stringify(error)}<br/>Info: ${info}`
  })
  return true
})
</script>
