<template>
  <div
    v-if="schedule"
    ref="rootElem"
    class="schedule"
  >
    <div
      class="row row-sticky"
      :class="{ extended: averageActive }"
    >
      <div
        v-for="(day, dayIndex) in schedule.days"
        :key="dayIndex"
        :ref="(el) => $refs[dayIndex]?.push(el as HTMLDivElement)"
        class="day-header col"
        @mouseenter="setActiveDay(dayIndex)"
        @mouseleave="setActiveDay()"
      >
        <div
          class="day-norm base-tooltip base-tooltip--top base-tooltip--center"
          @mouseenter="setActiveTooltip(`day-norm-${dayIndex}`)"
          @mouseleave="setActiveTooltip()"
        >
          <div
            class="day-norm__icon"
            :class="day.normClass"
          />
          <div
            v-if="checkActiveTooltip(`day-norm-${dayIndex}`)"
            class="base-tooltip__content"
          >
            <div class="day-norm__title">
              Odchylenia
            </div>
            <div class="day-norm__deviations">
              <div class="day-norm__box day-norm__box--ok" />
              0 - 10 %
            </div>
            <div class="day-norm__deviations">
              <div class="day-norm__box day-norm__box--yellow" />
              10 - 20 %
            </div>
            <div class="day-norm__deviations">
              <div class="day-norm__box day-norm__box--red" />
              > 20 %
            </div>
          </div>
        </div>
        <div
          class="day-link base-tooltip base-tooltip--top base-tooltip--center"
          @mouseenter="setActiveTooltip(`day-link-${dayIndex}`)"
          @mouseleave="setActiveTooltip()"
        >
          <router-link
            :to="{
              name: 'DietDetailDay',
              params: {
                dietType: $route.params.dietType,
                dietId: $route.params.dietId,
                dayIndex: dayIndex.toString()
              }
            }"
          >
            {{ day.name }}
          </router-link>
          <div
            v-if="checkActiveTooltip(`day-link-${dayIndex}`)"
            class="base-tooltip__content"
          >
            <div>Przejdź do widoku dziennego</div>
          </div>
        </div>
        <div class="day-menu">
          <IconButton
            name="more_vert"
            @click.stop="setActiveMenu(`day-menu-${dayIndex}`)"
          />
          <div
            v-if="checkActiveMenu(`day-menu-${dayIndex}`)"
            class="day-menu__items"
            :class="{ reversed: dayIndex == 6 && !averageActive }"
          >
            <div
              class="day-menu__item"
              @click.once="copyDay(dayIndex)"
            >
              <BaseIcon
                size="16"
                name="filter_none"
              /> <span>Skopiuj dzień</span>
            </div>
            <div
              v-if="canDietBeEdited"
              class="day-menu__item"
              :class="{ disabled: !copiedDayStatus }"
              @click.once="pasteDay(dayIndex)"
            >
              <BaseIcon
                size="16"
                name="queue"
              /> <span>Wklej dzień</span>
            </div>
            <div
              v-if="canDietBeEdited"
              class="day-menu__item"
              @click.once="setActiveDayDeleteModal(dayIndex)"
            >
              <BaseIcon
                size="16"
                name="delete"
              /> <span>Usuń dzień</span>
            </div>
          </div>
          <BaseModal
            v-if="activeDayDeleteModal === dayIndex"
            @close="setActiveDayDeleteModal()"
          >
            <template #title>
              Czy na pewno chcesz wyczyścić dzień?
            </template>
            Wyczyszczenie dnia usunie aktualnie dodane potrawy w kolumnie <span>{{ day.name }}</span>.
            <template #actions>
              <StyledButton
                variant="outline"
                @click.once="setActiveDayDeleteModal()"
              >
                Anuluj
              </StyledButton>
              <StyledButton @click.once="setActiveDayDeleteModal(), deleteDay(dayIndex)">
                Wyczyść
              </StyledButton>
            </template>
          </BaseModal>
        </div>
      </div>
      <div class="average-header">
        <div
          class="average-button base-tooltip--top base-tooltip--right"
          @mouseenter="setActiveTooltip(`average-button`)"
          @mouseleave="setActiveTooltip()"
        >
          <IconButton
            :name="averageActive ? 'chevron_right' : 'chevron_left'"
            icon-size="24"
            @click="toggleAverageActive()"
          />
          <div
            v-if="checkActiveTooltip(`average-button`)"
            class="base-tooltip__content"
          >
            Pokaż podsumowanie tygodniowe
          </div>
        </div>
        <div class="average-title">
          Średnia
        </div>
      </div>
    </div>
    <div
      v-for="(meal, mealIndex) in schedule.meals"
      :key="`meal-${mealIndex}`"
      class="meal row"
      :class="{ extended: averageActive }"
    >
      <div class="meal-name">
        {{ meal.name }}
      </div>
      <div
        v-for="(day, dayIndex) in meal.days"
        :key="`meal-${mealIndex}-day-${dayIndex}`"
        :ref="(el) => $refs[dayIndex]?.push(el as HTMLDivElement)"
        class="day col"
        :data-day="`day-${dayIndex}`"
        @mouseenter="setActiveDay(dayIndex)"
        @mouseleave="setActiveDay()"
        @drop="canDietBeEdited ? dropDishOrIngredientHandler($event, mealIndex, dayIndex) : ''"
        @dragover.prevent
      >
        <div class="energy-distribution-hour">
          <div
            class="day-hour base-select"
            :class="{
              'is-disabled': !canDietBeEdited
            }"
          >
            <div
              class="base-select__selected"
              :class="{
                'base-select__selected--active': checkActiveMenu(
                  `day-hour-${mealIndex}-${dayIndex}`
                )
              }"
              @click.stop="setActiveMenu(`day-hour-${mealIndex}-${dayIndex}`, true)"
            >
              {{ day.hour }}
            </div>
            <div
              v-if="checkActiveMenu(`day-hour-${mealIndex}-${dayIndex}`)"
              class="base-select__options"
              :class="[`day-hour-${mealIndex}-${dayIndex}-options`]"
            >
              <div
                v-for="dayHour in hours"
                :key="dayHour"
                class="base-select__option"
                :class="{ 'base-select__option--selected': dayHour === day.hour }"
                @click="updateDayHour(mealIndex, dayIndex, dayHour)"
              >
                {{ dayHour }}
              </div>
            </div>
          </div>
          <div
            v-if="schedule.energyDistribution && day.dishesAndIngredients.length > 0"
            class="energy-distribution base-tooltip base-tooltip--top base-tooltip--right"
            @mouseenter="setActiveTooltip(`energy-distribution-${mealIndex}-${dayIndex}`)"
            @mouseleave="setActiveTooltip()"
          >
            <div
              class="energy-distribution__dot"
              :class="day.energyDistributionClass"
            />
            <div
              v-if="checkActiveTooltip(`energy-distribution-${mealIndex}-${dayIndex}`)"
              class="base-tooltip__content"
            >
              <div class="energy-distribution__title">
                Rozkład energii
              </div>
              <div class="energy-distribution__data">
                {{ day.energyDistribution }} %
                <span class="energy-distribution__data-target">({{ meal.energyDistribution }} %)</span>
                - {{ day.calories }} kcal
                <span class="energy-distribution__data-target">({{ meal.energyDistributionCalories }} kcal)</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="(dishOrIngredient, dishOrIngredientIndex) in day.dishesAndIngredients"
          :key="`dish-or-ingredient-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`"
          class="dish-or-ingredient"
          :draggable="true ? canDietBeEdited : false"
          :class="{ repeated: dishOrIngredient.repeated }"
          @click="redirectToDietDetailDay(mealIndex, dayIndex, dishOrIngredientIndex)"
          @mousedown.ctrl="updateDragMode('copy')"
          @dragstart="
            dragStartDishOrIngredientHandler($event, mealIndex, dayIndex, dishOrIngredientIndex)
          "
        >
          <div
            v-if="hasPerm('accounts.diet_btw_bar')"
            class="nutrients-bar base-tooltip base-tooltip--top base-tooltip--center"
            @mouseenter="
              setActiveTooltip(`nutrient-bar-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
            "
            @mouseleave="setActiveTooltip()"
          >
            <div class="nutrients-bar__content">
              <div
                class="nutrients-bar__bar nutrients-bar__protein"
                :style="
                  nutrientBarWidthStyle(dishOrIngredient.calories, dishOrIngredient.proteinPercent)
                "
              >
                <span
                  :class="
                    nutrientBarHideClass(dishOrIngredient.calories, dishOrIngredient.proteinPercent)
                  "
                >B</span>
              </div>
              <div
                class="nutrients-bar__bar nutrients-bar__fat"
                :style="
                  nutrientBarWidthStyle(dishOrIngredient.calories, dishOrIngredient.fatPercent)
                "
              >
                <span
                  :class="
                    nutrientBarHideClass(dishOrIngredient.calories, dishOrIngredient.fatPercent)
                  "
                >T</span>
              </div>
              <div
                class="nutrients-bar__bar nutrients-bar__carbohydrates"
                :style="
                  nutrientBarWidthStyle(
                    dishOrIngredient.calories,
                    dishOrIngredient.carbohydratesPercentBar
                  )
                "
              >
                <span
                  :class="
                    nutrientBarHideClass(
                      dishOrIngredient.calories,
                      dishOrIngredient.carbohydratesPercentBar
                    )
                  "
                >W</span>
              </div>
            </div>
            <div
              v-if="
                checkActiveTooltip(`nutrient-bar-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
              "
              class="base-tooltip__content"
            >
              <div>
                <div class="nutrients-bar__box nutrients-bar__protein" />
                {{ dishOrIngredient.proteinPercent }}% - Białko
              </div>
              <div>
                <div class="nutrients-bar__box nutrients-bar__fat" />
                {{ dishOrIngredient.fatPercent }}% - Tłuszcze
              </div>
              <div>
                <div class="nutrients-bar__box nutrients-bar__carbohydrates" />
                {{ dishOrIngredient.carbohydratesPercent }}% - Węglowodany
              </div>
            </div>
          </div>
          <div class="dish-or-ingredient__name">
            {{ dishOrIngredient.name }}
          </div>
          <div class="dish-or-ingredient__footer">
            <div class="dish-or-ingredient__calories">
              {{ dishOrIngredient.calories }} kcal
            </div>
            <div
              v-if="canDietBeEdited"
              class="dish-or-ingredient__actions"
            >
              <div
                v-if="repeatedDishesAndIngredientsActive && dishOrIngredient.repeated"
                :class="{
                  'base-tooltip base-tooltip--top base-tooltip--center': dayIndex !== 6,
                  'base-tooltip base-tooltip--top base-tooltip--right': dayIndex === 6
                }"
                @mouseenter="
                  setActiveTooltip(`assignment-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
                "
                @mouseleave="setActiveTooltip()"
              >
                <IconButton
                  size="small"
                  icon-size="16"
                  name="assignment"
                  :type="dishOrIngredient.repeated ? 'warning' : 'default'"
                  @click.stop="
                    showRepeatedDishDietsModal(dishOrIngredient.name, dishOrIngredient.type)
                  "
                />
                <div
                  v-if="
                    checkActiveTooltip(
                      `assignment-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`
                    )
                  "
                  class="base-tooltip__content"
                >
                  Wyświetl
                  <span v-if="dishOrIngredient.type == 'dish'"> powtarzającą się potrawę</span>
                  <span v-else>powtarzający się produkt</span>
                </div>
              </div>
              <div
                :class="{
                  'base-tooltip base-tooltip--top base-tooltip--center': dayIndex !== 6,
                  'base-tooltip base-tooltip--top base-tooltip--right': dayIndex === 6
                }"
                @mouseenter="
                  setActiveTooltip(`copy-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
                "
                @mouseleave="setActiveTooltip()"
              >
                <IconButton
                  name="filter_none"
                  size="small"
                  icon-size="14"
                  :type="dishOrIngredient.repeated ? 'warning' : 'default'"
                  @click.stop="
                    copyDishOrIngredientHandler(mealIndex, dayIndex, dishOrIngredientIndex)
                  "
                  @mousedown="updateDragMode('copy'), setActiveTooltip()"
                />
                <div
                  v-if="
                    checkActiveTooltip(`copy-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
                  "
                  class="base-tooltip__content"
                >
                  W celu skopiowania kliknij w ikonę lub przeciągnij posiłek
                </div>
              </div>
              <div
                :class="{
                  'base-tooltip base-tooltip--top base-tooltip--center': dayIndex !== 6,
                  'base-tooltip base-tooltip--top base-tooltip--right': dayIndex === 6
                }"
                @mouseenter="
                  setActiveTooltip(`delete-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
                "
                @mouseleave="setActiveTooltip()"
              >
                <IconButton
                  name="delete"
                  hover-type="destructive"
                  :type="dishOrIngredient.repeated ? 'warning' : 'default'"
                  size="small"
                  icon-size="16"
                  @click.stop="deleteDishOrIngredient(mealIndex, dayIndex, dishOrIngredientIndex)"
                />
                <div
                  v-if="
                    checkActiveTooltip(`delete-${mealIndex}-${dayIndex}-${dishOrIngredientIndex}`)
                  "
                  class="base-tooltip__content"
                >
                  W celu usunięcia posiłku kliknij w ikonę
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="canDietBeEdited"
          class="add-dish-or-ingredient"
        >
          <StyledButton
            v-if="
              !checkActiveAddDishOrIngredient(`add-dish-or-ingredient-${mealIndex}-${dayIndex}`)
            "
            class="button"
            variant="outline"
            left-icon="add"
            @click.stop="
              setActiveAddDishOrIngredient(`add-dish-or-ingredient-${mealIndex}-${dayIndex}`)
            "
            @keyup.enter="
              setActiveAddDishOrIngredient(`add-dish-or-ingredient-${mealIndex}-${dayIndex}`)
            "
          >
            Dodaj pozycję
          </StyledButton>
          <DietDishOrIngredientAddInput
            v-else
            :mode="'all'"
            :dish-meal-type="meal.type"
            :dish-flags="dishFlags"
            :related-dishes="relatedDishes"
            @select="
              addDishOrIngredient(
                $event.dishOrIngredientId,
                $event.dishOrIngredientType,
                mealIndex,
                dayIndex
              )
            "
            @select-unused="
              addUnusedDishPortion(
                $event.mealIndex,
                $event.dayIndex,
                $event.dishOrIngredientIndex,
                mealIndex,
                dayIndex
              )
            "
            @keyup.esc="setActiveAddDishOrIngredient()"
          />
        </div>
      </div>
      <div
        class="meal-average"
        :class="{ inactive: !averageActive }"
      >
        <template v-if="averageActive && meal.mealExists">
          <div class="meal-average__kcal">
            <span>{{ meal.average.calories }}</span>
            <span
              v-if="energyDistributionEnabled"
              class="meal-target__kcal"
            >/ {{ meal.energyDistributionCalories }}</span>
            <span :class="{ regular: energyDistributionEnabled }">kcal</span>
          </div>
          <div
            v-if="energyDistributionEnabled"
            class="meal-average__percent"
          >
            <span>{{ meal.actualEnergyDistribution }}</span>
            <span class="meal-target__percent">/ {{ meal.energyDistribution }}</span>%
          </div>
          <div class="meal-average__nutrients">
            <div class="meal-average__nutrient">
              <div class="meal-average__nutrient-name">
                Białko
              </div>
              <div class="meal-average__nutrient-value">
                {{ meal.average.protein }}g
              </div>
              <div class="meal-average__nutrient-percent">
                {{ meal.average.proteinPercent }}%
              </div>
            </div>
            <div class="meal-average__nutrient">
              <div class="meal-average__nutrient-name">
                Tłuszcze
              </div>
              <div class="meal-average__nutrient-value">
                {{ meal.average.fat }}g
              </div>
              <div class="meal-average__nutrient-percent">
                {{ meal.average.fatPercent }}%
              </div>
            </div>
            <div class="meal-average__nutrient">
              <div class="meal-average__nutrient-name">
                Wp
              </div>
              <div class="meal-average__nutrient-value">
                {{ meal.average.carbohydrates }}g
              </div>
              <div class="meal-average__nutrient-percent">
                {{ meal.average.carbohydratesPercent }}%
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <BaseModal
      v-if="repeatedDishDietsModal"
      @close="hideRepeatedDishDietsModal"
    >
      <template #title>
        <span v-if="repeatedDishDataType == 'dish'">Powtarzająca się potrawa</span>
        <span v-else>Powtarzający się produkt</span>
      </template>
      <div class="doi-name">
        {{ repeatedDishDataName }}
      </div>
      <div class="doi-description">
        <span v-if="repeatedDishDataType == 'dish'"> Ta potrawa </span>
        <span v-else> Ten produkt </span>
        występuje w następujących jadłospisach pacjenta (opcja uwzględnia do 5 jadłospisów wstecz):
      </div>
      <div
        v-for="repeatedDiet in repeatedDishDietsData"
        :key="`diet-${repeatedDiet.id}`"
        class="repeated-dish-diets-row"
      >
        <a
          :href="repeatedDiet.url"
          target="_blank"
        >
          {{ repeatedDiet.name }} - {{ repeatedDiet.start_date }} - {{ repeatedDiet.end_date }} (liczba wystąpień:
          {{ repeatedDiet.number_of_occurences }})
        </a>
      </div>
    </BaseModal>
  </div>
</template>

<script lang="ts" setup>
import type { RepeatedUsedDiet } from '@/services/dietsService'

import { A, G } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, nextTick, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseModal from '@/components/BaseModal.vue'
import DietDishOrIngredientAddInput from '@/components/diets/DietDishOrIngredientAddInput.vue'
import { hours } from '@/const'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { reportError } from '@/utils/reportError'

import { IconButton } from '../IconButton'
import { StyledButton } from '../StyledButton'

const rootElem = ref<HTMLDivElement>()
const activeTooltip = ref<string>()
const activeMenu = ref<string>()

const $refs = ref<Record<number, HTMLDivElement[]>>({
  0: [],
  1: [],
  2: [],
  3: [],
  4: [],
  5: [],
  6: []
})
const activeDayDeleteModal = ref<number>()
const activeAddDishOrIngredient = ref<string>()
const repeatedDishDietsModal = ref(false)
const repeatedDishDietsData = ref<RepeatedUsedDiet[]>()
const repeatedDishDataName = ref('')
const repeatedDishDataType = ref('')

const $router = useRouter()
const $route = useRoute()
const dietStore = useDietsStore()
const globalStore = useGlobalStore()
const {
  schedule,
  averageActive,
  copiedDayStatus,
  dragMode,
  relatedDishes,
  diet,
  repeatedDishesAndIngredientsActive,
  canDietBeEdited
} = storeToRefs(dietStore)

const { hasPerm } = storeToRefs(globalStore)

const {
  updateDragMode,
  copyDay,
  pasteDay,
  deleteDay,
  updateDayHour,
  fetchAndAddDishOrIngredient,
  moveDishOrIngredient,
  copyDishOrIngredient,
  deleteDishOrIngredient,
  toggleAverageActive
} = dietStore

const dishFlags = computed(() => diet.value?.data.dish_search_filters)
const energyDistributionEnabled = computed(() => diet.value?.data.energy_distribution ?? false)

const showRepeatedDishDietsModal = async (doiName: string, doiType: 'dish' | 'ingredient') => {
  try {
    const response = await dietsService.fetchPatientDietRepeatedDishDiets(
      parseInt($route.params.dietId as string),
      doiName
    )

    repeatedDishDietsModal.value = true
    repeatedDishDietsData.value = response.data
    repeatedDishDataName.value = doiName
    repeatedDishDataType.value = doiType
  } catch (err) {
    reportError(err, 'Error while fetching repeated dish diets data', {
      dietId: $route.params.dietId,
      doiName,
      doiType
    })
  }
}
const hideRepeatedDishDietsModal = () => {
  repeatedDishDietsModal.value = false
  repeatedDishDietsData.value = undefined
  repeatedDishDataName.value = ''
  repeatedDishDataType.value = ''
}
const setActiveDay = (dayIndex?: number) => {
  activeMenu.value = undefined
  setTimeout(() => {
    document.querySelectorAll('.col').forEach((col) => {
      col.classList.remove('active')
    })
    if (G.isNotNullable(dayIndex)) {
      A.forEach($refs.value[dayIndex] ?? [], (col) => {
        col.classList.add('active')
      })
    }
  }, 0)
}
const setActiveTooltip = (tooltip?: string) => {
  activeTooltip.value = tooltip
}
const checkActiveTooltip = (tooltip: string) => {
  return activeTooltip.value === tooltip
}
const setActiveMenu = async (menu?: string, hourMenu = false) => {
  activeAddDishOrIngredient.value = undefined
  if (activeMenu.value === menu || !menu) {
    activeMenu.value = undefined
  } else {
    activeMenu.value = menu
    if (hourMenu) {
      await nextTick()

      const activeOptions = rootElem.value?.querySelector(`.${activeMenu.value}-options`)
      const activeElement = rootElem.value?.querySelector('.base-select__option--selected')

      if (activeOptions instanceof HTMLElement && activeElement instanceof HTMLElement) {
        activeOptions.scrollTo(0, activeElement.offsetTop - activeOptions.offsetHeight / 2)
      }
    }
  }
}
const checkActiveMenu = (menu: string) => {
  return activeMenu.value === menu
}
const setActiveAddDishOrIngredient = (dishOrIngredient?: string) => {
  activeMenu.value = undefined
  activeAddDishOrIngredient.value = dishOrIngredient
}
const checkActiveAddDishOrIngredient = (dishOrIngredient: string) => {
  return activeAddDishOrIngredient.value === dishOrIngredient
}
const setActiveDayDeleteModal = (dayIndex?: number) => {
  activeDayDeleteModal.value = dayIndex
}

const addDishOrIngredient = async (
  dishOrIngredientId: number,
  dishOrIngredientType: 'dish' | 'ingredient',
  mealIndex: number,
  dayIndex: number
) => {
  try {
    await fetchAndAddDishOrIngredient({
      id: dishOrIngredientId,
      type: dishOrIngredientType,
      mealIndex,
      dayIndex
    })

    setActiveAddDishOrIngredient()
  } catch (err) {
    reportError(err, 'Error during fetching and adding dish or ingredient', {
      id: dishOrIngredientId,
      type: dishOrIngredientType,
      mealIndex,
      dayIndex
    })
  }
}
const addUnusedDishPortion = (
  mealIndex: number,
  dayIndex: number,
  dishOrIngredientIndex: number,
  targetMealIndex: number,
  targetDayIndex: number
) => {
  copyDishOrIngredient(
    targetMealIndex,
    targetDayIndex,
    mealIndex,
    dayIndex,
    dishOrIngredientIndex,
    true
  )
}
const dragStartDishOrIngredientHandler = (
  event: DragEvent,
  mealIndex: number,
  dayIndex: number,
  dishOrIngredientIndex: number
) => {
  setActiveTooltip()
  if (event.dataTransfer) {
    event.dataTransfer.effectAllowed = 'copyMove'
    event.dataTransfer.dropEffect = dragMode.value as DataTransfer['dropEffect']
    event.dataTransfer.setData('mealIndex', mealIndex.toString())
    event.dataTransfer.setData('dayIndex', dayIndex.toString())
    event.dataTransfer.setData('dishOrIngredientIndex', dishOrIngredientIndex.toString())
  }
}
const copyDishOrIngredientHandler = (
  mealIndex: number,
  dayIndex: number,
  dishOrIngredientIndex: number
) => {
  copyDishOrIngredient(mealIndex, dayIndex, mealIndex, dayIndex, dishOrIngredientIndex)
  updateDragMode('move')
}
const dropDishOrIngredientHandler = (event: DragEvent, mealIndex: number, dayIndex: number) => {
  event.preventDefault()
  if (event.dataTransfer) {
    const eventMealIndex = parseInt(event.dataTransfer.getData('mealIndex'))
    const eventDayIndex = parseInt(event.dataTransfer.getData('dayIndex'))
    const eventDishOrIngredientIndex = parseInt(event.dataTransfer.getData('dishOrIngredientIndex'))
    if (
      Number.isNaN(eventMealIndex) ||
      Number.isNaN(eventDayIndex) ||
      Number.isNaN(eventDishOrIngredientIndex)
    ) {
      reportError(new Error('Invalid data transfer data'), 'Invalid data transfer data', {
        eventMealIndex,
        eventDayIndex,
        eventDishOrIngredientIndex
      })
      return
    }
    if (dragMode.value === 'copy') {
      copyDishOrIngredient(
        mealIndex,
        dayIndex,
        eventMealIndex,
        eventDayIndex,
        eventDishOrIngredientIndex
      )
    } else {
      moveDishOrIngredient(
        mealIndex,
        dayIndex,
        eventMealIndex,
        eventDayIndex,
        eventDishOrIngredientIndex
      )
    }
  }
  updateDragMode('move')
}

const redirectToDietDetailDay = async (
  mealIndex: number,
  dayIndex: number,
  dishOrIngredientIndex: number
) => {
  sessionStorage.setItem('redirectMealIndex', mealIndex.toString())
  sessionStorage.setItem('redirectDishOrIngredientIndex', dishOrIngredientIndex.toString())
  try {
    await $router.push({
      name: 'DietDetailDay',
      params: {
        dietType: $route.params.dietType,
        dietId: $route.params.dietId,
        dayIndex
      }
    })
  } catch (err) {
    reportError(err, 'Error while redirecting to diet detail day', {
      mealIndex,
      dayIndex,
      dishOrIngredientIndex
    })
  }
}

const nutrientBarWidthStyle = (calories: number, percent: number) => {
  if (calories) return { width: `${percent}%` }
  return {}
}
const nutrientBarHideClass = (calories: number, percent: number) => {
  if (calories && percent < 10) return 'hide'
  return {}
}
const hideMenu = () => {
  activeMenu.value = undefined
  activeAddDishOrIngredient.value = undefined
}

useEventListener('click', hideMenu)
</script>

<style scoped>
.row {
  display: grid;
  grid-template-columns: repeat(7, minmax(130px, 1fr)) 30px;
}

.row.extended {
  grid-template-columns: repeat(7, minmax(130px, 1fr)) 142px;
}

.row-sticky {
  position: sticky;
  top: 0;
  z-index: 11;
  background: #fff;
}

/* Day header */
.day-header {
  position: relative;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-header.active::after {
  content: '';
  position: absolute;
  inset: 0;
  box-shadow: 0 4px 5px 3px rgb(222 222 222 / 50%);
  clip-path: inset(0 -15px 0 -15px);
  pointer-events: none;
}

.repeated-dish-diets-row a {
  display: flex;
  color: var(--color-main-100);
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}

.day-link a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Montserrat-Medium;
  font-size: 12px;
  line-height: 18px;
  color: var(--color-primary-text);
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.repeated-dish-diets-row a:hover {
  text-decoration: underline;
  color: var(--color-main-110);
}

/* Day */
.day {
  padding: 32px 6px 8px;
  position: relative;
}

.day.active::after {
  content: '';
  position: absolute;
  inset: 0;
  box-shadow: 0 4px 5px 3px rgb(222 222 222 / 50%);
  clip-path: inset(0 -15px 0 -15px);
  pointer-events: none;
}

/* Day norm */
.day-norm {
  position: absolute;
  top: 11px;
  left: 6px;
}

.day-norm__icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center;
}

.day-norm__icon.red-up,
.day-norm__icon.red-down {
  background-image: url('@/assets/img/diets/norm-red.svg?url');
}

.day-norm__icon.yellow-up,
.day-norm__icon.yellow-down {
  background-image: url('@/assets/img/diets/norm-yellow.svg?url');
}

.day-norm__icon.yellow-down,
.day-norm__icon.red-down {
  transform: rotate(180deg);
}

.day-norm__icon.ok {
  height: 15px;
  width: 15px;
  background-size: 15px 15px;
  background-image: url('@/assets/img/diets/norm-green.svg?url');
}

.day-norm__title {
  margin-bottom: 8px;
  text-transform: uppercase;
}

.day-norm__box {
  display: inline-block;
  width: 3px;
  height: 9px;
}

.day-norm__box.day-norm__box--ok {
  background: #80e6ab;
}

.day-norm__box.day-norm__box--yellow {
  background: #ffaa39;
}

.day-norm__box.day-norm__box--red {
  background: #f56879;
}

/* Day link */

.day-link {
  text-align: center;
  height: 18px;
  max-width: calc(100% - 60px);
}

.day-header.active .day-link a {
  color: var(--color-main-100);
}

/* Day menu */
.day-menu {
  position: absolute;
  top: 5px;
  right: 1px;
  height: 28px;
  width: 28px;
  background: #fff;
  display: none;
}

.day-header.active .day-menu {
  display: block;
}

.day-menu__items {
  position: absolute;
  display: flex;
  z-index: 20;
  background: #fff;
  flex-direction: column;
  left: 0;
  top: 28px;
  box-shadow: 0 1px 6px 0 rgb(155 155 155 / 50%);
}

.day-menu__items.reversed {
  right: 0;
  left: initial;
}

.day-menu__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  white-space: nowrap;
  height: 20px;
  font-size: 12px;
  padding: 3px 6px;
  cursor: pointer;
}

.day-menu__item:hover {
  background: #f8f8f8;
}

.day-menu__item.disabled {
  color: var(--color-disabled-text);
  pointer-events: none;
}

.day-menu__item.disabled .base-icon {
  color: var(--color-disabled-text);
}

.day-menu__item span {
  margin-left: 6px;
}

/* Average column */
.average-header {
  position: relative;
  height: 38px;
}

.average-button {
  position: absolute;
  top: 5px;
  left: 1px;
}

.average-title {
  display: none;
  font-family: Montserrat-Medium;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 38px;
  text-align: center;
}

.extended .average-title {
  display: block;
}

/* Meal */
.meal {
  position: relative;
}

.meal-name {
  position: absolute;
  top: 0;
  left: 4px;
  right: 4px;
  height: 32px;
  line-height: 32px;
  letter-spacing: 1.4px;
  text-align: center;
  pointer-events: none;
  background: #f8f8f8;
  font-family: Montserrat-SemiBold;
  font-size: 14px;
  z-index: 10;
}

/* Energy distribution/hour */
.energy-distribution-hour {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  margin: 2px 8px 7px;
}

.day-hour .base-select__selected {
  padding: 1px 12px 1px 2px;
  border-bottom: 1px solid #fff;
  color: var(--color-tertiary-text);
}

.day-hour.base-select.is-disabled,
.day.active .day-hour.base-select.is-disabled {
  pointer-events: none;
  cursor: text;
}

.day-hour.base-select.is-disabled .base-select__selected {
  color: var(--color-tertiary-text);
}

.day.active .day-hour .base-select__selected {
  color: var(--color-primary-text);
}

.day.active .day-hour .base-select__selected:hover {
  border-bottom: 1px solid var(--color-primary-text);
}

.day.active .day-hour.base-select.is-disabled .base-select__selected {
  color: var(--color-tertiary-text);
}

.day-hour .base-select__selected::after {
  margin-top: -2px;
  top: 7px;
  right: 2px;
  display: none;
}

.day-hour .base-select__selected--active::after {
  top: 10px;
}

.day.active .day-hour .base-select__selected::after {
  /* display: block; */
}

.day.active .day-hour .base-select__selected:hover::after {
  display: block;
}

.day.active .day-hour .base-select__selected--active,
.day.active .day-hour .base-select__selected--active:hover {
  color: var(--color-primary-text);
  border-bottom: 1px solid var(--color-main-100);
}

.day.active .day-hour .base-select__selected--active::after {
  display: block;
}

.day-hour .base-select__options {
  z-index: 20;
  width: 55px;
}

.day-hour .base-select__option {
  padding-right: 5px;
}

/* Energy distribution */
.energy-distribution {
  height: 8px;
  width: 8px;
}

.energy-distribution__dot {
  height: 8px;
  width: 8px;
  border-radius: 4px;
}

.energy-distribution__dot.green {
  background: var(--color-success-100);
}

.energy-distribution__dot.yellow {
  background: var(--color-warning-100);
}

.energy-distribution__dot.red {
  background: var(--color-destructive-100);
}

.energy-distribution__title {
  margin-bottom: 4px;
}

.energy-distribution__data {
  font-family: Montserrat-Medium;
}

.energy-distribution__data-target {
  color: var(--color-tertiary-text);
}

/* DishOrIngredient */
.dish-or-ingredient {
  position: relative;
  background: var(--color-main-10);
  padding: 12px 7px 2px 8px;
  min-height: 57px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  word-break: break-word;
  box-sizing: content-box;
}

.dish-or-ingredient.repeated {
  background: var(--color-warning-30);
}

.dish-or-ingredient.repeated .dish-or-ingredient__copy-button:hover,
.dish-or-ingredient.repeated .dish-or-ingredient__assignment-button:hover {
  background: var(--color-warning-60);
}

.dish-or-ingredient.repeated .dish-or-ingredient__copy-button .icon-button,
.dish-or-ingredient.repeated .dish-or-ingredient__assignment-button .icon-button {
  color: var(--color-warning-100);
}

.dish-or-ingredient.repeated .dish-or-ingredient__delete-button .icon-button {
  color: var(--color-warning-100);
}

.dish-or-ingredient.repeated .dish-or-ingredient__copy-button:hover .base-icon {
  color: #e5aa1d;
}

.dish-or-ingredient.repeated .dish-or-ingredient__delete-button:hover .base-icon {
  color: #f55f6d;
}

.nutrients-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 9px;
  width: 100%;
  display: none;
}

.nutrients-bar__content {
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 4px 4px 0 0;
  overflow: hidden;
  font-family: Montserrat-SemiBold;
  font-size: 8px;
  line-height: 9px;
  color: #fff;
}

.nutrients-bar span {
  width: 17px;
  max-width: 100%;
  height: 9px;
  text-align: center;
}

.nutrients-bar span.hide {
  display: none;
}

.nutrients-bar__bar {
  width: calc(100% / 3);
  height: 9px;
  vertical-align: top;
  display: flex;
  align-items: center;
}

.nutrients-bar__box {
  height: 9px;
  width: 3px;
  margin-right: 3px;
  display: inline-block;
}

.nutrients-bar__protein {
  background: #f16595;
}

.nutrients-bar__fat {
  background: #f1c365;
}

.nutrients-bar__carbohydrates {
  background: #9f98ff;
}

.dish-or-ingredient__footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 22px;
}

.dish-or-ingredient__calories {
  font-size: 10px;
  color: var(--color-secondary-text);
}

.dish-or-ingredient__actions {
  display: none;
}

.day.active .dish-or-ingredient__actions {
  display: flex;
}

.day.active .nutrients-bar {
  display: block;
}

.add-dish-or-ingredient {
  height: 30px;
}

.add-dish-or-ingredient .button {
  display: none;
}

.day.active .add-dish-or-ingredient .button {
  display: block;
}

/* Meal average */
.meal-average {
  padding: 32px 6px 8px;
  background: #f2fafa;
  margin-right: 4px;
  text-align: center;
}

.meal-average.inactive {
  background: #fff;
}

.meal-average__kcal {
  margin-top: 27px;
}

.meal-average__percent {
  margin-top: 8px;
}

.meal-average__kcal,
.meal-average__percent {
  font-size: 12px;
}

.meal-average__kcal span,
.meal-average__percent span {
  font-family: Montserrat-SemiBold;
  font-size: 14px;
}

.meal-average__kcal span.regular {
  font-family: Montserrat-Regular, sans-serif;
  font-size: 13px;
}

.meal-average__kcal .meal-target__kcal,
.meal-average__percent .meal-target__percent {
  font-family: Montserrat-Regular, sans-serif;
  font-size: 13px;
}

.meal-average__nutrients {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.meal-average__nutrient {
  display: flex;
  flex-direction: column;
  width: max-content;
  text-align: center;
}

.meal-average__nutrient-name {
  font-size: 10px;
  margin-top: 4px;
}

.meal-average__nutrient-value {
  font-size: 12px;
  font-family: Montserrat-SemiBold;
  margin-top: 4px;
}

.meal-average__nutrient-percent {
  font-size: 10px;
  margin-top: 4px;
}

.doi-name {
  font-size: 16px;
  color: var(--color-primary-text);
  margin-bottom: 13px;
}

.doi-description {
  font-size: 12px;
  color: var(--color-primary-text);
}
</style>
