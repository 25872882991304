import type { DashboardState, UserGroup } from '@/store/types'
import type { Period } from './types'

import { defineStore } from 'pinia'

import { dashboardService } from '@/services/dashboardService'
import { dietsService } from '@/services/dietsService'
import { reportError } from '@/utils/reportError'

export const useDashboardStore = defineStore('dashboardStore', {
  state: (): DashboardState => ({
    userDashboard: {
      content: [],
      last_activity: {},
      stats: {}
    },
    patientsOptions: {
      gender: [],
      pollPattern: []
    },
    userGroups: [],
    dietType: 'user'
  }),
  getters: {
    getDashboardStats: (state) => (period: Period) => {
      return state.userDashboard.stats[period]
    },
    getDashboardLastActivity:
      (state) =>
      <T extends keyof DashboardState['userDashboard']['last_activity']>(type: T) => {
        return state.userDashboard.last_activity[type]
      }
  },
  actions: {
    updateUserDashboard(data: DashboardState['userDashboard']) {
      this.userDashboard.content = data.content
      this.userDashboard.last_activity = data.last_activity
      this.userDashboard.stats = data.stats
    },
    updatePatientsOptions(data: DashboardState['patientsOptions']) {
      this.patientsOptions.gender = data.gender
      this.patientsOptions.pollPattern = data.pollPattern
    },
    updateUserGroups(data: UserGroup[]) {
      this.userGroups = data
    },
    // fetching user stats - last activities, dynamic content and stats
    async fetchStats() {
      const response = await dashboardService.fetchStats()

      if (response.data) {
        this.updateUserDashboard(response.data)
      }
    },
    // patients options for select field
    async fetchPatientsOptions() {
      const response = await dashboardService.fetchPatientsOptions()
      const options = {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        gender: response.data.actions.POST.gender.choices,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        pollPattern: response.data.actions.POST.pattern_name.choices
      }
      this.updatePatientsOptions(options)
    },
    // user groups for select options
    async fetchUserGroups() {
      try {
        const { data } = await dietsService.fetchGroups({ dietType: this.dietType })

        this.updateUserGroups(data)
      } catch (err) {
        reportError(err, 'Error fetching user groups', { dietType: this.dietType })
      }
    }
  }
})
