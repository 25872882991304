import type { RouteRecordSingleViewWithChildren } from 'vue-router'

import AppLayoutVue from '@/layouts/AppLayout.vue'
import PatientAssumptions from '@/pages/patients/PatientAssumptions/PatientAssumptions.vue'
import PatientCalculations from '@/pages/patients/PatientCalculations/PatientCalculations.vue'
import PatientDetails from '@/pages/patients/PatientDetails/PatientDetails.vue'
import PatientDiets from '@/pages/patients/PatientDiets/PatientDiets.vue'
import PatientQuestionnaire from '@/pages/patients/PatientQuestionnaire/PatientQuestionnaire.vue'
import PatientRoot from '@/pages/patients/PatientRoot/PatientRoot.vue'
import PatientsList from '@/pages/patients/PatientsList/PatientsList.vue'

export const patientsRouter: RouteRecordSingleViewWithChildren = {
  path: '/patients',
  component: AppLayoutVue,
  meta: {
    auth: true
  },
  children: [
    {
      path: 'list/',
      name: 'PatientsList',
      component: PatientsList,
      props: true,
      meta: {
        title: 'Lista pacjentów'
      }
    },
    {
      path: ':patientId',
      redirect: {
        name: 'PatientDetails'
      },
      name: 'Patient',
      component: PatientRoot,
      props: true,
      children: [
        {
          path: 'details',
          name: 'PatientDetails',
          component: PatientDetails,
          props: true,
          meta: {
            title: 'Podsumowanie'
          }
        },
        {
          path: 'calculations',
          name: 'PatientCalculations',
          component: PatientCalculations,
          props: true,
          meta: {
            title: 'Wyliczenia'
          }
        },
        {
          path: 'assumptions',
          name: 'PatientAssumptions',
          component: PatientAssumptions,
          props: true,
          meta: {
            title: 'Założenia'
          }
        },
        {
          path: 'questionnaire',
          name: 'PatientQuestionnaire',
          component: PatientQuestionnaire,
          props: true,
          meta: {
            title: 'Wyliczenia'
          }
        },
        {
          path: 'diets',
          name: 'PatientDiets',
          component: PatientDiets,
          props: true,
          meta: {
            title: 'Wyliczenia'
          }
        }
      ]
    }
  ]
}
