import type { VariantPropsOf } from '@/utils/variants'
import type { PropType, SetupContext } from 'vue'

import { G } from '@mobily/ts-belt'
import { computed, defineComponent } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { variants } from '@/utils/variants'

import s from './style.module.scss'

const checkboxStyles = variants({
  base: s.input,
  variants: {
    size: {
      small: s['input-size--small'],
      default: s['input-size--default'],
      large: s['input-size--large']
    },
    disabled: {
      true: s.disabled
    },
    checked: {
      true: s.checked
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const labelStyles = variants({
  base: s.label,
  variants: {
    size: {
      small: s['label-size--small'],
      default: s['label-size--default'],
      large: s['label-size--large']
    },
    disabled: {
      true: s['label--disabled']
    },
    checked: {
      true: s['label--checked']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const captionStyles = variants({
  base: s.caption,
  variants: {
    size: {
      small: s['caption--small'],
      default: s['caption--default'],
      large: s['caption--large']
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

type Props = VariantPropsOf<typeof checkboxStyles> & {
  disabled?: boolean
  indeterminate?: boolean
  label: string
  modelValue: boolean
  name: string
}
interface Events {
  'update:modelValue': (value: boolean) => void
}

const IconSize: Record<NonNullable<VariantPropsOf<typeof checkboxStyles>['size']>, IconSize> = {
  small: '16',
  default: '20',
  large: '24'
}

const Component = (props: Props, ctx: SetupContext<Events>) => {
  const iconSize = IconSize[props.size ?? 'default']
  const checkboxClasses = computed(() => {
    return checkboxStyles({
      disabled: props.disabled,
      checked: props.modelValue || props.indeterminate,
      size: props.size
    })
  })

  const labelClasses = computed(() => {
    return labelStyles({
      disabled: props.disabled,
      checked: !!props.modelValue || !!props.indeterminate,
      size: props.size
    })
  })

  const captionClasses = computed(() => {
    return captionStyles({ size: props.size })
  })

  const clickHandler = () => {
    if (!props.disabled) {
      ctx.emit('update:modelValue', !props.modelValue)
    }
  }

  return () => (
    <label class={labelClasses.value} for={props.name}>
      <input
        type="checkbox"
        name={props.name}
        id={props.name}
        class={s.input}
        checked={props.modelValue}
        onChange={clickHandler}
      />
      <div class={checkboxClasses.value}>
        {props.modelValue ? (
          <BaseIcon name="check" size={iconSize} />
        ) : props.indeterminate ? (
          <BaseIcon name="remove" size={iconSize} />
        ) : null}
      </div>
      {G.isNotNullable(props.label) && props.label}
      {ctx.slots.caption && <span class={captionClasses.value}>{ctx.slots.caption()}</span>}
    </label>
  )
}

export const KcalmarCheckbox = defineComponent(Component, {
  props: {
    size: String as PropType<Props['size']>,
    disabled: Boolean,
    indeterminate: Boolean,
    label: String,
    modelValue: Boolean,
    name: String
  },
  emits: ['update:modelValue']
})
