import type { RouteRecordSingleViewWithChildren } from 'vue-router'

import AppLayout from '@/layouts/AppLayout.vue'
import IngredientsList from '@/pages/ingredients/IngredientsList.vue'
import IngredientsListEdit from '@/pages/ingredients/IngredientsListEdit.vue'

export const ingredientsRouter: RouteRecordSingleViewWithChildren = {
  path: '/ingredients',
  name: 'ingredients',
  component: AppLayout,
  props: true,
  meta: {
    auth: true
  },
  children: [
    {
      path: 'list/',
      name: 'IngredientsList',
      component: IngredientsList,
      props: true,
      meta: {
        title: 'Produkty'
      }
    },
    {
      path: 'edit/:ingredientId(\\d+)',
      name: 'IngredientsListEdit',
      component: IngredientsListEdit,
      meta: {
        title: 'Edycja produktu'
      }
    },
    {
      path: 'add/',
      name: 'IngredientsListAdd',
      component: IngredientsListEdit,
      meta: {
        title: 'Dodawanie produktu'
      }
    }
  ]
}
