<template>
  <button :class="styles">
    <slot />
  </button>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { computed, toRefs, useCssModule } from 'vue'

import { variants } from '@/utils/variants'

const $style = useCssModule()
const props = defineProps({
  size: {
    type: String as PropType<'default' | 'large'>,
    default: 'default'
  },
  active: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
const { size, active, disabled } = toRefs(props)

const makeStyles = variants({
  base: $style.root,
  variants: {
    size: {
      default: $style.default ?? '',
      large: $style.large ?? ''
    },
    active: {
      true: $style.active ?? ''
    },
    disabled: {
      true: $style.disabled ?? ''
    }
  },
  defaultVariants: {
    size: 'default'
  }
})

const styles = computed(() =>
  makeStyles({
    size: size.value,
    active: active.value,
    disabled: disabled.value
  })
)
</script>

<style lang="scss" scoped module>
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: $radius-radi-4;
  color: $text-tertiary;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: $fill-200;
    color: $text-tertiary;
  }
}

.default {
  width: 28px;
  height: 28px;

  @include font-style('sm', 'medium');
}

.large {
  width: 36px;
  height: 36px;

  @include font-style('base', 'medium');
}

.active {
  cursor: text;
  color: $text-primary;
  background-color: #fff;
  border: 1px solid $state-base-border;
}

.disabled {
  cursor: text;
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
}
</style>
