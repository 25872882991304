<template>
  <div class="background">
    <div class="container">
      <span class="title">Wybierz tagi</span>
      <span class="label">Diety specjalne:</span>
      <div class="row-f">
        <div
          v-for="specialDiet in specialDietsChoices"
          :key="specialDiet.value"
          class="tag-box"
          :class="{ active: specialDiet.active, disabled: specialDiet.disabled }"
          @click="setDishType(specialDiet.value)"
        >
          <BaseIcon
            v-if="specialDiet.disabled"
            name="lock"
            size="16"
          />
          {{ specialDiet.text }}
        </div>
      </div>
      <span class="label">Kuchnie świata:</span>
      <div class="row-f">
        <div
          v-for="region in cuisineRegionChoices"
          :key="region.value"
          class="tag-box"
          :class="{ active: region.active }"
          @click="setDishType(region.value)"
        >
          {{ region.text }}
        </div>
      </div>
      <span class="label">Sprzęt kuchenny:</span>
      <div class="row-f">
        <div
          v-for="kitchenware in kitchenwareChoices"
          :key="kitchenware.value"
          class="tag-box"
          :class="{ active: kitchenware.active }"
          @click="setDishType(kitchenware.value)"
        >
          {{ kitchenware.text }}
        </div>
      </div>
      <span class="label">Rodzaj potrawy:</span>
      <div class="row-f">
        <div
          v-for="dishType in dishTypeChoices"
          :key="dishType.value"
          class="tag-box"
          :class="{ active: dishType.active }"
          @click="setDishType(dishType.value)"
        >
          {{ dishType.text }}
        </div>
      </div>
      <span class="label">Inne:</span>
      <div class="row-f">
        <div
          v-for="choice in otherChoices"
          :key="choice.value"
          class="tag-box"
          :class="{ active: choice.active }"
          @click="setDishType(choice.value)"
        >
          {{ choice.text }}
        </div>
      </div>
      <span class="label">Specjalne okazje:</span>
      <div class="row-f">
        <div
          v-for="specialOccasion in specialOccasionChoices"
          :key="specialOccasion.value"
          class="tag-box"
          :class="{ active: specialOccasion.active }"
          @click="setDishType(specialOccasion.value)"
        >
          {{ specialOccasion.text }}
        </div>
      </div>
      <div
        v-if="mode === 'search'"
        class="checkbox-row"
      >
        <BaseCheckbox
          class="search-all-tags"
          :model-value="searchAllTags"
          @update:model-value="toggleSearchAllTags()"
        />
        <div>Wyszukaj potrawy, które zawierają <span>wszystkie</span> wybrane tagi</div>
      </div>
      <div class="button-row">
        <StyledButton
          variant="secondary"
          @click.once="clearDishTypes"
        >
          Wyczyść tagi
        </StyledButton>

        <StyledButton
          variant="secondary"
          class="cancel-button"
          @click="hideTagList"
        >
          Anuluj
        </StyledButton>
        <StyledButton @click="saveDishTypes">
          Zapisz
        </StyledButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DishTypes } from '@/types/Diet'

import { computed, onMounted, ref } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import { dishTypes } from '@/utils/common'

import { StyledButton } from '../StyledButton'

const props = defineProps<{
  autoTags?: DishTypes
  chosenTags: DishTypes
  mode: 'add-dish' | 'search'
}>()

interface Choice {
  active: boolean
  disabled: boolean
  subgroup: 'type' | 'region' | 'kitchenware' | 'specialDiets' | 'other' | 'specialOccasion'
  text: string
  value: DishTypes[number]
}

const emit = defineEmits(['hideTagList', 'searchAllTags', 'setDishType'])

const dishTypeValues = ref<number[]>([])
const dishTypeValuesSaved = ref<number[]>([])
const searchAllTags = ref(true)

const choices = computed<Choice[]>(() => {
  const disabledChoices = [51, 52, 53, 54]
  return dishTypes.map((choice) => ({
    ...choice,
    active: dishTypeValues.value.includes(choice.value),
    disabled: disabledChoices.includes(choice.value) && props.mode === 'add-dish'
  }))
})

const dishTypeChoices = computed<Choice[]>(() => {
  return choices.value.filter((type) => type.subgroup === 'type')
})

const cuisineRegionChoices = computed(() => {
  return choices.value.filter((type) => type.subgroup === 'region')
})
const kitchenwareChoices = computed(() => {
  return choices.value.filter((type) => type.subgroup === 'kitchenware')
})
const specialDietsChoices = computed(() => {
  const filteredChoices = choices.value.filter((type) => type.subgroup === 'specialDiets')

  return filteredChoices.map((choice) => {
    choice.active =
      dishTypeValues.value.includes(choice.value) || Boolean(props.autoTags?.includes(choice.value))
    return choice
  })
})
const otherChoices = computed(() => {
  return choices.value.filter((type) => type.subgroup === 'other')
})
const specialOccasionChoices = computed(() => {
  return choices.value.filter((type) => type.subgroup === 'specialOccasion')
})

const hideTagList = () => {
  if (dishTypeValuesSaved.value) {
    dishTypeValues.value = dishTypeValuesSaved.value
  }
  emit('hideTagList')
}
const toggleSearchAllTags = () => {
  searchAllTags.value = !searchAllTags.value
}
const setDishType = (value: number) => {
  addOrRemoveFromList(dishTypeValues.value, value)
  dishTypeValuesSaved.value = dishTypeValues.value
}
const clearDishTypes = () => {
  dishTypeValuesSaved.value = dishTypeValues.value
  dishTypeValues.value = []
}
const saveDishTypes = () => {
  emit('setDishType', dishTypeValues.value)
  sessionStorage.setItem('searchAllTags', searchAllTags.value.toString())
  emit('searchAllTags', searchAllTags.value)
}
const addOrRemoveFromList = (valuesList: number[], value: number) => {
  const index = valuesList.indexOf(value)
  if (index === -1) {
    valuesList.push(value)
  } else {
    valuesList.splice(index, 1)
  }
}

onMounted(() => {
  if (sessionStorage.getItem('searchAllTags') === 'false') {
    searchAllTags.value = false
  } else {
    searchAllTags.value = true
  }
  dishTypes.forEach((dishType) => {
    if (props.chosenTags.includes(dishType.value)) {
      setDishType(dishType.value)
    }
  })
})
</script>

<style scoped>
.background {
  margin: auto;
  inset: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgb(0 0 0 / 60%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  padding: 24px 32px 32px;
  width: 1014px;
  background: #fff;
  z-index: 999;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  box-shadow: 0 1.75px 4px -1px rgb(0 0 0 / 14%);
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
}

.container .title {
  font-size: 24px;
  font-family: Montserrat-Bold;
  color: #2b2b2b;
  margin-bottom: 24px;
  height: 36px;
  line-height: 36px;
}

.container .row-f {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.tag-box {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  font-size: 14px;
  font-family: Montserrat-Medium;
  font-weight: 500;
  color: #2b2b2b;
  cursor: pointer;
}

.tag-box.disabled {
  pointer-events: none;
  border-color: #f0f0f0;
  color: rgb(43 43 43 / 20%);
}

.tag-box.active,
.tag-box.disabled.active {
  border-color: #009b9d;
  color: #009b9d;
}

.tag-box i,
.tag-box.disabled.active i {
  margin-right: 4px;
  color: #009b9d;
}

/* stylelint-disable-next-line no-descending-specificity */
.tag-box.disabled i {
  color: rgb(43 43 43 / 20%);
}

.container .label {
  font-size: 14px;
  font-family: Montserrat-Medium;
  font-weight: 500;
  color: #2b2b2b;
  height: 22px;
  line-height: 22px;
}

.search-all-tags {
  margin-right: 8px;
}

.button-row {
  display: flex;
  column-gap: 8px;
}

.button-row .cancel-button {
  margin-left: auto;
}

.checkbox-row {
  margin: 8px 0 24px;
  height: 22px;
  line-height: 22px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.checkbox-row span {
  font-family: Montserrat-Bold;
}
</style>
