<template>
  <Teleport to="#base-modal-portal">
    <div
      class="base-modal-overlay"
      @click="emit('close', $event)"
      @keydown.esc="emit('close', $event)"
    >
      <div
        :id="modalId"
        class="base-modal"
        :class="[{ 'base-modal__form': type === 'form' }, modalClass]"
        @click.stop="emit('click', $event)"
      >
        <Space :space="12">
          <Space
            direction="columns"
            align-x="between"
          >
            <div class="base-modal-title">
              <slot name="title" />
            </div>
            <IconButton
              class="base-modal-close"
              name="close"
              type="info"
              @click="emit('close')"
            />
          </Space>
          <div class="base-modal-content">
            <slot />
          </div>
          <div
            v-if="$slots.actions"
            class="base-modal-actions"
          >
            <slot name="actions" />
          </div>
        </Space>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts" setup>
import { useEventListener } from '@vueuse/core'

import { Teleport } from 'vue'

import { IconButton } from '@/components/IconButton'
import { Space } from '@/components/Space'

interface Props {
  modalClass?: string
  modalId?: string
  type?: 'default' | 'form'
}

defineProps<Props>()
const emit = defineEmits(['confirm', 'close', 'click'])

useEventListener(document, 'keydown', (event) => {
  if (event.key === 'Enter') {
    emit('confirm', event)
  }
  if (event.key === 'Escape') {
    emit('close', event)
  }
})
</script>

<style>
.base-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 30%);
  z-index: 99993;
}

.base-modal__form {
  overflow: unset !important;
  width: 100% !important;
  max-width: 578px;
  box-sizing: border-box;
}

.base-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 480px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 2px 2px 8px rgb(0 0 0 / 30%);
  max-height: 80%;
  padding: 25px;
  z-index: 9001;
  overflow: auto;
}

.base-modal-title {
  font-family: Montserrat-Medium;
  font-size: 20px;
  color: var(--color-primary-text);
  padding-right: 28px;
  line-height: 28px;
  letter-spacing: -1.7%;
}

.base-modal-content {
  font-size: 12px;
  color: var(--color-primary-text);
  line-height: 20px;
}

.base-modal-actions {
  display: flex;
  direction: row;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}
</style>
