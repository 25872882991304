import type { AppMessage } from './store/types'

import mitt from 'mitt'

type Types = {
  deactivateSelects: unknown
  hideAutocompleteOptions: unknown
  ingredientDrop: {
    groupId: number
    parent: number
    type: 'group' | 'subgroup'
  }
  resizeBaseTextArea: unknown
  showError: Partial<Omit<AppMessage, 'id' | 'type'>> & { error?: GlobalError }
  undoOrRedo: unknown
}

const emitter = mitt<Types>()

export default emitter
