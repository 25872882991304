<template>
  <div
    v-if="!loading"
    class="main-content"
  >
    <div class="list-edit-container">
      <div
        v-if="groupsExtended"
        class="overlay"
        @click="toggleGroupsExtended()"
      />
      <div class="list-edit">
        <IngredientBasicInformations
          v-if="ingredientLoaded && ingredientOptionsLoaded"
          :ingredient="ingredient"
          :ingredient-categories="getCategories"
          :ingredient-measurements="getMeasurements"
          @handle-update-ingredient-name="handleUpdateIngredientName($event)"
          @handle-update-ingredient-category="handleUpdateIngredientCategory($event)"
          @handle-update-ingredient-grams="handleUpdateIngredientGrams($event)"
          @handle-update-ingredient-measurement="handleUpdateIngredientMeasurement($event)"
          @handle-update-ingredient-tags="handleUpdateIngredientTags($event)"
        />
        <div class="basic-and-groups">
          <div
            class="basic-nutrients"
            :class="{ extended: groupsExtended }"
          >
            <span class="title">Podstawowe wartości odżywcze dla 100 gramów produktu</span>
            <div
              v-for="nutrient in basicIngredientNutrients"
              :key="nutrient"
            >
              <div class="nutrient-row">
                <div class="basic-nutrient-name">
                  <span>*</span>
                  {{ getNutrientName(nutrient) }}
                </div>
                <BaseInputNumber
                  v-if="ingredientLoaded"
                  :disabled="isNutrientDisabled(nutrient)"
                  :type="'Number'"
                  :precision="1"
                  :min="0"
                  :max="9999"
                  :model-value="getNutrientValue(nutrient)"
                  @update:model-value="handleUpdateIngredientNutrient($event, nutrient)"
                />
                <div class="basic-nutrient-measurement">
                  <template v-if="nutrientsMap[nutrient].measurement">
                    ({{ nutrientsMap[nutrient].measurement }})
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div
            class="ingredient-groups"
            :class="{ extended: groupsExtended }"
          >
            <div class="ingredient-groups-inner">
              <BaseIcon
                class="extend-icon"
                variant="outline"
                name="open_in_full"
                @click="toggleGroupsExtended()"
              />

              <span class="title">Grupy i podgrupy produktów</span>
              <div class="description">
                <div class="group-dot" />
                grupa
                <div class="subgroup-dot" />
                podgrupa
              </div>
              <div class="draggable-container">
                <IngredientGroupsTree
                  v-if="ingredientLoaded"
                  :ingredient-groups-list-prop="ingredientGroupsList"
                  :ingredient-active-groups-prop="ingredient.group"
                  @select-group="handleSelectGroup($event)"
                  @drag-start="setDragging(true)"
                  @drag-end="setDragging(false)"
                />
                <BaseIcon
                  v-if="groupsExtended"
                  class="swap-horiz"
                  name="swap_horiz"
                />
                <div
                  class="dragged-container"
                  :class="{
                    'dragging-hover': dropzoneActive && dragging,
                    dragging: dragging,
                    empty: getGroupsWithNames.length === 0 && !dropzoneActive && !dragging
                  }"
                >
                  <span class="choosen-title">* Wybrane grupy i podgrupy</span>
                  <div
                    class="dragged-list"
                    @drop.prevent="handleIngredientDrop($event)"
                    @dragenter="handleDropzoneActive(true)"
                    @dragleave="handleDropzoneActive(false)"
                    @dragover.prevent
                  >
                    <div
                      v-if="dragging && dropzoneActive"
                      class="dropzone-add-icon"
                    >
                      +
                    </div>
                    <div
                      v-else-if="dragging && !dropzoneActive"
                      class="dragging"
                    >
                      Upuść tutaj, aby dodać grupę
                    </div>
                    <div
                      v-else-if="getGroupsWithNames.length > 0"
                      class="inner"
                    >
                      <div
                        v-for="group in getGroupsWithNames"
                        :key="group.name"
                        class="group"
                        :class="{ subgroup: group.subGroup }"
                      >
                        <span class="name">{{ group.name }}</span>
                        <BaseIcon
                          class="choosen-ingredient__icon-check"
                          size="16"
                          :type="group.subGroup ? 'info' : 'success'"
                          name="check_circle"
                        />

                        <BaseIcon
                          class="choosen-ingredient__icon-cancel"
                          type="warning"
                          size="16"
                          name="cancel"
                          @click="removeGroup(group.id)"
                        />
                      </div>
                    </div>
                    <div
                      v-else
                      class="empty-dragged-list"
                    >
                      Przeciągnij element z listy
                      <span v-if="!groupsExtended">powyżej</span>
                      <span v-else>po lewej</span>, bądź zaznacz go bezpośrednio na liście.
                    </div>
                  </div>
                </div>
              </div>
              <div class="button-container">
                <StyledButton
                  v-if="groupsExtended"
                  @click.once="toggleGroupsExtended()"
                >
                  Zminimalizuj
                </StyledButton>
              </div>
            </div>
          </div>
        </div>
        <div class="specific-nutrients-container">
          <span class="title">Szczegółowe wartości odżywcze dla 100 gramów produktu <span>(opcjonalne)</span></span>
          <div class="specific-nutrients">
            <div
              v-for="nutrient in specificIngredientNutrients"
              :key="nutrient"
              class="specific-nutrient"
              :class="{ 'active-box': isNutrientActive(nutrient) }"
            >
              <div
                class="specific-nutrient-name"
                :title="getNutrientName(nutrient)"
              >
                {{ getNutrientName(nutrient) }}
              </div>
              <div class="value-box">
                <div class="specific-nutrient-measurement">
                  <template v-if="nutrientsMap[nutrient].measurement">
                    ({{ nutrientsMap[nutrient].measurement }})
                  </template>
                </div>
                <BaseInputNumberNullAllowed
                  v-if="ingredientLoaded"
                  :placeholder="getNumberInputPlaceholder(nutrient)"
                  :type="'Number'"
                  :precision="getDecimalValue(nutrient)"
                  :min="0"
                  :max="getMaxValue(nutrient)"
                  :model-value="getNutrientValue(nutrient)"
                  @update:model-value="handleUpdateIngredientNutrient($event, nutrient)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="actions-row">
        <div class="required-info">
          <span>*</span> Pola wymagane
        </div>
        <StyledButton
          variant="outline"
          @click.once="goToIngredientsList()"
        >
          Anuluj
        </StyledButton>
        <StyledButton
          :disabled="saveButtonDisabled"
          @click.once="saveIngredientHandler()"
        >
          Zapisz
        </StyledButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  Category,
  Measurement
} from '@/components/ingredients/IngredientBasicInformations.vue'
import type { Group, ListIngredient } from '@/services/ingredientsService'
import type { Nutrients } from '@/utils/nutrients'
import type { AxiosError } from 'axios'

import { F, G } from '@mobily/ts-belt'
import { useEventListener } from '@vueuse/core'
import { klona } from 'klona'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseInputNumberNullAllowed from '@/components/BaseInputNumberNullAllowed.vue'
import IngredientBasicInformations from '@/components/ingredients/IngredientBasicInformations.vue'
import IngredientGroupsTree from '@/components/ingredients/IngredientGroupsTree.vue'
import { StyledButton } from '@/components/StyledButton'
import eventBus from '@/eventBus'
import { useHasAddIngredientPermission } from '@/hooks/useHasAddIngredientPermission'
import { ingredientsService } from '@/services/ingredientsService'
import { useGlobalStore } from '@/store/globalStore'
import { type IngredientCategory, type Measurements } from '@/types/Diet'
import {
  basicIngredientNutrients,
  ingredientDefault,
  specificIngredientNutrients
} from '@/utils/ingredients'
import { nutrientsMap } from '@/utils/nutrients'
import { reportError } from '@/utils/reportError'

interface GroupList {
  id: number
  name: string
  subGroup: boolean
}

const globalStore = useGlobalStore()
const { createMessage, changeIngredientCounter } = globalStore
const { user } = storeToRefs(globalStore)
const router = useRouter()
const route = useRoute()

const ingredientLoaded = ref(false)
const ingredientOptionsLoaded = ref(false)
const ingredientsGroupsLoaded = ref(false)
const ingredient = ref({} as ListIngredient)
const ingredientId = ref<number>()
const ingredientCategories = ref<IngredientCategory[]>([])
const ingredientMeasurements = ref<Measurements[]>([])
const ingredientGroupsList = ref<Group[]>([])
const groupsExtended = ref(false)
const dragging = ref(false)
const dropzoneActive = ref(false)
const redirect = ref(false)
const hasAddIngredientPermission = useHasAddIngredientPermission()

const saveButtonDisabled = computed(() => {
  return !ingredient.value.name || ingredient.value.group.length === 0
})

const loading = computed(() => {
  if (ingredientLoaded.value && ingredientOptionsLoaded && ingredientsGroupsLoaded) return false
  return true
})
const getCategories = computed<Category[]>(() => {
  return Array.from(ingredientCategories.value, (category) => {
    return { value: category.id, text: category.name }
  })
})
const getMeasurements = computed<Measurement[]>(() => {
  return Array.from(ingredientMeasurements.value, (measurement) => {
    return { value: measurement.id, text: measurement.name }
  })
})
const getGroupsWithNames = computed(() => {
  const groupList: GroupList[] = []

  ingredientGroupsList.value.forEach((group) => {
    if (ingredient.value.group.includes(group.id)) {
      const groupDict = {
        id: group.id,
        name: group.name,
        subGroup: false
      }
      groupList.push(groupDict)
    } else {
      group.children.forEach((subGroup) => {
        if (ingredient.value.group.includes(subGroup.id)) {
          const groupDict = {
            id: subGroup.id,
            name: subGroup.name,
            subGroup: true
          }

          groupList.push(groupDict)
        }
      })
    }
  })

  return groupList
})

const confirmLeave = () => {
  return window.confirm('Masz niezapisane zmiany. Czy na pewno chcesz wyjść?')
}
const handleDropzoneActive = (value: boolean) => {
  dropzoneActive.value = value
}
const setDragging = (value: boolean) => {
  dragging.value = value
}
const handleIngredientDrop = (event: DragEvent) => {
  if (event.dataTransfer) {
    const eventData = event.dataTransfer.getData('groupDict')
    if (eventData) {
      try {
        const groupDict = JSON.parse(eventData)
        eventBus.emit('ingredientDrop', groupDict)
      } catch (e) {
        reportError(e, 'Error while diet drop')
      }
    }
    handleDropzoneActive(false)
  }
}
const removeGroup = (groupId: number) => {
  ingredient.value.group = ingredient.value.group.filter((group) => group !== groupId)
}
const handleSelectGroup = (groups: number[]) => {
  ingredient.value.group = groups
}
const toggleGroupsExtended = () => {
  const body = document.querySelector('body')
  if (body) {
    groupsExtended.value = !groupsExtended.value
    if (groupsExtended.value) {
      body.classList.add('blocked')
    } else {
      body.classList.remove('blocked')
    }
  }
}
const isNutrientActive = (nutrient: Nutrients) => {
  const value = getNutrientValue(nutrient)
  return typeof value === 'number'
}
const isNutrientDisabled = (nutrient: Nutrients) => {
  return nutrient === 'carbohydrate_exchanger'
}
const getDecimalValue = (nutrient: Nutrients) => {
  const decimalList = [
    'glycemic_index',
    'fiber_energy',
    'carbohydrates_energy',
    'fat_energy',
    'protein_energy',
    'protein_according_regulation',
    'energy_value',
    'energy_value_kj',
    'energy_value_according_regulation_kj',
    'energy_value_according_regulation_kcal'
  ]
  if (decimalList.includes(nutrient)) {
    return 1
  } else if (nutrient === 'waste') {
    return 0
  }
  return 3
}
const getMaxValue = (nutrient: Nutrients) => {
  if (nutrient === 'iodine') return 20000
  return 9999
}
const getNumberInputPlaceholder = (nutrient: Nutrients) => {
  const value = getDecimalValue(nutrient)
  if (value === 3) return '0,000'
  else if (value === 1) return '0,0'
  return '0'
}
const goToIngredientsList = async (withMessage = '') => {
  redirect.value = true
  await router.push({ name: 'IngredientsList' })
  if (withMessage) {
    createMessage({ title: withMessage })
  }
}
const handleUpdateIngredientNutrient = <T extends Nutrients>(value: number | '', nutrient: T) => {
  // @ts-expect-error
  if (specificIngredientNutrients.includes(nutrient) && value === '') {
    // @ts-expect-error
    ingredient.value[nutrient] = undefined
  } else {
    // @ts-expect-error
    ingredient.value[nutrient] = value as number
    if (nutrient === 'carbohydrates') {
      ingredient.value.carbohydrate_exchanger = getNutrientValue('carbohydrate_exchanger')
    }
  }
}
const getNutrientName = (nutrient: Nutrients) => {
  return nutrientsMap[nutrient].name
}
const getNutrientValue = <T extends Nutrients>(nutrient: T) => {
  if (nutrient === 'carbohydrate_exchanger') {
    return round(ingredient.value.carbohydrates / 10, 1)
  }
  return ingredient.value[nutrient]
}
const saveIngredientHandler = async () => {
  ingredient.value.carbohydrate_exchanger = getNutrientValue('carbohydrate_exchanger')
  let response
  if (ingredientId.value) {
    response = await ingredientsService.updateIngredient(ingredientId.value, ingredient.value)
  } else {
    if (user?.value) {
      ingredient.value.user = user.value.id
    }
    response = await ingredientsService.createIngredient(ingredient.value)
  }
  if (response.status === 200) {
    await goToIngredientsList('Zapisano produkt')
  } else if (response.status === 201) {
    await goToIngredientsList('Dodano produkt')
    changeIngredientCounter({
      value: 1
    })
  }
}
const fetchIngredientOptions = async () => {
  try {
    const response = await ingredientsService.fetchIngredientOptions()
    ingredientCategories.value = response.data.ingredient_categories
    ingredientMeasurements.value = response.data.measurement_names
    ingredientOptionsLoaded.value = true
  } catch (err) {
    reportError(err, 'Error while fetching ingredient options')
  }
}
const fetchIngredientGroups = async () => {
  try {
    const response = await ingredientsService.fetchIngredientGroups()
    ingredientGroupsList.value = response.data
    ingredientsGroupsLoaded.value = true
  } catch (err) {
    reportError(err, 'Error while fetching ingredient groups')
  }
}
const fetchData = async () => {
  if (G.isNotNullable(ingredientId.value)) {
    try {
      const response = await ingredientsService.fetchIngredient(ingredientId.value)

      if (response.status === 200) {
        ingredient.value = response.data
        ingredientLoaded.value = true
      }
    } catch (error) {
      reportError(error, 'Error while fetching ingredient', { ingredientId: ingredientId.value })
      if ((error as AxiosError).response?.status === 404) {
        void router.push({ name: 'PageNotFound' })
      }
    }
  }
}
const handleUpdateIngredientName = (value: string) => {
  ingredient.value.name = value
}
const handleUpdateIngredientCategory = (value: ListIngredient['category']) => {
  ingredient.value.category = value
}
const handleUpdateIngredientGrams = (value: ListIngredient['grams']) => {
  ingredient.value.grams = value
}
const handleUpdateIngredientMeasurement = (value: ListIngredient['measurement']) => {
  ingredient.value.measurement = value
}
const handleUpdateIngredientTags = <T extends keyof ListIngredient>(data: {
  tagName: T
  value: ListIngredient[T]
}) => {
  if (data.tagName in ingredient.value) {
    ingredient.value[data.tagName] = data.value
  }
}
const confirmStayInEditPage = () => {
  return !redirect.value && !confirmLeave()
}
const beforeWindowUnload = (e: BeforeUnloadEvent) => {
  if (confirmStayInEditPage()) {
    e.preventDefault()
    e.returnValue = ''
  }
}

useEventListener(window, 'beforeunload', beforeWindowUnload)

onMounted(async () => {
  try {
    await Promise.all([fetchIngredientGroups(), fetchIngredientOptions()])
    if (route.params.ingredientId) {
      const id = parseInt(route.params.ingredientId as string)
      if (isNaN(id)) {
        throw new Error('Invalid ingredient id')
      }
      ingredientId.value = id
      await fetchData()
    } else {
      if (hasAddIngredientPermission.value) {
        ingredient.value = klona(F.coerce<ListIngredient>(ingredientDefault))
        ingredientLoaded.value = true
      } else {
        redirect.value = true
        void router.push({ name: 'PageNotFound' })
      }
    }
  } catch (err) {
    reportError(err, 'Error while fetching mounting IngredientsListEdit ')
  }
})
onBeforeRouteLeave((___, __, next) => {
  if (confirmStayInEditPage()) {
    next(false)
  } else {
    next()
  }
})
</script>
<style>
body.blocked {
  overflow: hidden;
}
</style>
<style scoped>
.main-content {
  position: relative;
}

.actions-row .required-info span {
  color: var(--color-destructive-100);
}

.overlay {
  background-color: rgb(0 0 0 / 50%);
  position: fixed;
  inset: 0;
  z-index: 8;
}

.list-edit {
  margin-bottom: 12px;
}

.basic-and-groups {
  display: flex;
  margin-top: 24px;
  column-gap: 24px;
}

.basic-nutrients {
  display: flex;
  flex-direction: column;
  background: #fff;
  height: 464px;
  width: 50%;
  padding: 20px 0 0 16px;
  color: var(--color-primary-text);
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  min-width: 520px;
}

.title {
  line-height: 1.21;
  font-family: Montserrat-Medium;
  font-size: 24px;
  margin-bottom: 25px;
}

.basic-nutrients .title {
  max-width: 400px;
}

.specific-nutrients-container .title {
  padding-left: 10px;
}

.specific-nutrients-container .title span {
  color: var(--color-tertiary-text);
}

.basic-nutrients .nutrient-row {
  margin-left: 10px;
  margin-bottom: 16px;
  display: flex;
  font-size: 12px;
  align-items: center;
  font-family: Montserrat-Medium;
}

.basic-nutrients .nutrient-row .basic-nutrient-name {
  min-width: 240px;
  margin-right: 24px;
}

.basic-nutrients .nutrient-row .basic-nutrient-name span {
  color: var(--color-destructive-100);
}

.basic-nutrients .nutrient-row .basic-nutrient-measurement {
  margin-left: 24px;
}

.basic-nutrients .number-input {
  border: 1px solid var(--color-tertiary-text);
  border-radius: 5px;
  max-width: 152px;
}

.basic-nutrients .number-input:focus {
  border-color: var(--color-main-100);
}

.actions-row {
  display: flex;
  width: 100%;
  background: #fff;
  position: sticky;
  bottom: 0;
  padding-right: 47px;
  height: 54px;
  align-items: center;
  column-gap: 10px;
}

.actions-row .required-info {
  margin-right: auto;
  font-size: 12px;
  font-family: Montserrat-Medium;
  margin-left: 50px;
}

.specific-nutrients {
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  column-gap: 6px;
}

.specific-nutrients-container {
  padding: 20px 6px 8px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  background: #fff;
  color: var(--color-primary-text);
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
}

.specific-nutrients .specific-nutrient .specific-nutrient-name {
  min-height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 12px;
  background: #f5f5f5;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 16px;
  margin-bottom: 4px;
}

.specific-nutrients .specific-nutrient .value-box {
  max-width: 50px;
  margin: 0 auto;
  text-align: center;
}

.specific-nutrients .specific-nutrient.active-box .specific-nutrient-name {
  font-family: Montserrat-Medium;
}

.specific-nutrients .specific-nutrient.active-box {
  background: var(--color-main-10);
}

.specific-nutrients .specific-nutrient .value-box .base-input {
  max-width: 100%;
  padding-left: 0;
  padding-top: 1px;
  text-align: center;
}

.specific-nutrients .specific-nutrient .specific-nutrient-measurement {
  font-family: Montserrat-Medium;
  font-size: 9px;
  color: var(--color-tertiary-text);
  text-align: left;
  height: 11px;
}

.specific-nutrient {
  height: 77px;
}

.ingredient-groups .container {
  max-height: 198px;
  padding: 6px 0 16px 10px;
  margin-top: 16px;
  margin-left: 11px;
  width: auto;
}

.ingredient-groups .extend-icon {
  position: absolute;
  right: 18px;
  top: 18px;
  color: var(--color-primary-text);
  cursor: pointer;
}

.draggable-container .dragged-container {
  margin-top: 24px;
}

.draggable-container .dragged-container .choosen-title {
  font-size: 12px;
}

.draggable-container .dragged-container .dragged-list {
  height: 66px;
  overflow-y: auto;
  scrollbar-width: thin;
  border: 1px dashed var(--color-tertiary-text);
  color: var(--color-destructive-100);
  margin-top: 14px;
  padding: 8px 9px 6px;
  border-radius: 4px;
}

.ingredient-groups.extended .draggable-container .dragged-list {
  height: 100%;
  margin-top: 0;
  border: none;
}

.draggable-container .dragged-container.empty .dragged-list {
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-family: Montserrat-Bold;
  border: 2px dashed var(--color-destructive-100);
}

.draggable-container .dragged-container.dragging .dragged-list {
  color: var(--color-main-100);
  border: 2px dashed var(--color-main-100);
  font-size: 14px;
  background: #f8fcfc;
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: Montserrat-Bold;
  opacity: 0.5;
}

.draggable-container .dragged-container.dragging-hover .dragged-list {
  border: 2px solid var(--color-main-100);
  color: var(--color-main-40);
  font-size: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-family: Montserrat-Regular;
}

.ingredient-groups.extended .draggable-container .dragged-container .dragged-list {
  height: calc(100% - 19px);
  border: none;
}

.draggable-container .dragged-container.empty .dragged-list div {
  max-width: 295px;
  text-align: center;
}

.ingredient-groups {
  padding: 20px 16px;
  background: #fff;
  width: 50%;
  position: relative;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  min-width: 500px;
}

.basic-nutrients.extended {
  width: 50%;
}

.basic-nutrients.extended,
.ingredient-groups.extended {
  width: 100%;
}

.ingredient-groups.extended .button-container {
  width: 100%;
  margin: 0 132px;
}

.ingredient-groups.extended .button-container button {
  position: absolute;
  right: 0;
  margin-top: 16px;
}

.ingredient-groups.extended {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 787px;
  overflow-y: auto;
  z-index: 9;
}

.ingredient-groups.extended .container {
  max-height: 613px;
  width: 50%;
  border: solid 1px rgb(166 166 166 / 50%);
  margin-left: 0;
  box-shadow: none;
}

.ingredient-groups.extended .extend-icon {
  top: 25px;
  right: -60px;
}

.ingredient-groups.extended .draggable-container {
  display: flex;
  justify-content: space-between;
  column-gap: 31px;
  max-height: 612px;
}

.ingredient-groups.extended .ingredient-groups-inner {
  margin: 0 132px;
  margin-bottom: 16px;
  min-width: 1000px;
  position: relative;
}

.ingredient-groups.extended .draggable-container .dragged-container {
  width: 50%;
  max-height: 635px;
  border-radius: 2px;
  border: dashed 1px var(--color-tertiary-text);
  margin-top: 16px;
}

.ingredient-groups.extended .dragged-container .choosen-title {
  display: none;
}

.ingredient-groups.extended .draggable-container .dragged-container .dragged-list.in-dragging {
  border: 1px solid var(--color-main-100);
}

.ingredient-groups.extended .draggable-container .dragged-list .dragging {
  color: var(--color-main-100);
  font-size: 16px;
}

.ingredient-groups.extended .draggable-container .dragged-container.empty .dragged-list div {
  max-width: 335px;
}

.ingredient-groups.extended .draggable-container .dragged-container.empty .dragged-list {
  color: var(--color-tertiary-text);
  font-size: 16px;
  line-height: 1.3;
}

.ingredient-groups .description {
  display: flex;
  font-size: 10px;
  align-items: center;
  margin-top: 28px;
}

.ingredient-groups.extended .description {
  font-size: 12px;
}

.group-dot {
  height: 8px;
  width: 8px;
  background-color: var(--color-main-100);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.subgroup-dot {
  height: 8px;
  width: 8px;
  background-color: var(--color-warning-100);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-left: 20px;
}

.draggable-container .dragged-container.empty .choosen-title {
  color: var(--color-destructive-100);
}

.draggable-container .dragged-container .dragged-list::-webkit-scrollbar {
  width: 5px;
}

.draggable-container .dragged-container .dragged-list .inner {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
}

.draggable-container .dragged-container .dragged-list .group {
  display: flex;
  align-items: center;
  padding: 7px 8px 7px 9px;
  border-radius: 16px;
  width: max-content;
  color: var(--color-primary-text);
  background: var(--color-main-10);
  font-size: 10px;
}

.draggable-container .dragged-container .dragged-list .group.subgroup {
  background: var(--color-warning-10);
}

.draggable-container
  .dragged-container
  .dragged-list
  .group.subgroup
  .choosen-ingredient__icon-check {
  color: var(--color-warning-100);
}

.draggable-container .dragged-container .dragged-list .group span {
  display: inline-block;
  margin-right: 6px;
}

.draggable-container .dragged-container .dragged-list .group:not(.subgroup) span::first-letter {
  text-transform: uppercase;
}

.draggable-container .dragged-container .dragged-list .group:hover .choosen-ingredient__icon-check,
.draggable-container .dragged-container .dragged-list .group .choosen-ingredient__icon-cancel {
  display: none;
}

.draggable-container
  .dragged-container
  .dragged-list
  .group:hover
  .choosen-ingredient__icon-cancel {
  display: inline-block;
  cursor: pointer;
}

.draggable-container .dragged-container .dragged-list .group:hover {
  background: var(--color-red-10);
}

.ingredient-groups.extended .draggable-container .dragged-container.dragging {
  border: 2px dashed var(--color-main-100);
}

.swap-horiz {
  color: #000;
  display: flex;
  align-self: center;
}

.list-loader {
  background: rgb(255 255 255 / 40%);
  display: flex;
  position: absolute;
  inset: 0;
  align-items: top;
  justify-content: center;
  z-index: 1000;
  cursor: wait;
}

.list-loader-bar {
  background-color: transparent;
  border-radius: 9999px;
  width: calc(100% - 24px);
  margin: 52px 12px;
  height: 2px;
  position: relative;
  overflow: hidden;
}

.list-loader-bar__progress {
  background-color: var(--color-main-100);
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: progress-bar;
}

.dropzone-add-icon {
  pointer-events: none;
}

@media screen and (width <= 1280px) {
  .ingredient-groups.extended {
    display: flex;
  }
}

@media screen and (height <= 780px) {
  .ingredient-groups.extended {
    top: 0;
    min-height: auto;
  }

  .ingredient-groups.extended .button-container {
    height: 50px;
  }
}

@keyframes progress-bar {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}
</style>
