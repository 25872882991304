<template>
  <div
    :class="$style.container"
    :data-testId="`${props.testId}_Allergens`"
  >
    <div
      v-if="allergens.notLike"
      :class="[$style.allergen, $style.notLike]"
      :data-testId="`${props.testId}_Allergens_NotLike`"
    />
    <div
      v-if="allergens.like"
      :class="[$style.allergen, $style.like]"
      :data-testId="`${props.testId}_Allergens_Like`"
    />
    <div
      v-if="allergens.allergies"
      :class="[$style.allergen, $style.allergies]"
      :data-testId="`${props.testId}_Allergens_Allergies`"
    />
    <div
      v-if="allergens.intolerances"
      :class="[$style.allergen, $style.intolerances]"
      :data-testId="`${props.testId}_Allergens_Intolerances`"
    />
  </div>
</template>
<script setup lang="ts">
import type {
  AllergensInfo,
  DietSearchDishIngredient,
  DietSearchDishOrIngredient,
  ScheduleDishOrIngredient
} from '@/types/Diet'

import { computed, useCssModule } from 'vue'

import { useDietsStore } from '@/store/dietsStore'

const { getDishOrIngredientAllergens } = useDietsStore()

interface Props {
  dishOrIngredient: ScheduleDishOrIngredient | DietSearchDishOrIngredient | DietSearchDishIngredient
  testId: string
}

const $style = useCssModule()

const props = defineProps<Props>()
const allergens = computed<AllergensInfo>(() =>
  getDishOrIngredientAllergens(props.dishOrIngredient)
)
</script>

<style lang="scss" module scoped>
.container {
  display: flex;
  align-items: center;
  gap: 4px;
}

.allergen {
  width: 8px;
  min-width: 8px;
  height: 8px;
}

.allergies {
  background: #faa0a8;
}

.intolerances {
  background: #a0a8fa;
}

.like {
  background: var(--color-success-60);
}

.notLike {
  background: var(--color-warning-60);
}
</style>
