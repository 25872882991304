<template>
  <div>
    <table :class="props.tableClass">
      <colgroup>
        <col
          v-for="header in table.getHeaderGroups()[0]?.headers ?? []"
          :key="header.id"
          :style="{
            width: header.column.columnDef.size + 'px',
            minWidth: header.column.columnDef.minSize + 'px',
            maxWidth: header.column.columnDef.maxSize + 'px'
          }"
        >
      </colgroup>
      <thead>
        <tr
          v-for="headerGroup in table.getHeaderGroups()"
          :key="headerGroup.id"
        >
          <th
            v-for="header in headerGroup.headers"
            :key="header.id"
            :style="{
              top: stickyHeight ? stickyHeight - 1 + 'px' : 'auto',
              position: stickyHeight ? 'sticky' : 'relative'
            }"
            @click="header.column.getToggleSortingHandler()?.($event)"
          >
            <Space
              direction="columns"
              :space="1"
              :class="$style.headContent"
              :padding-x="Number($style.space7) / 2"
              :padding-y="Number($style.space7) / 2"
              :align-x="mapAlign[header.column.columnDef.meta?.align ?? 'left']"
              :style="{
                paddingRight: (header.column.getCanSort() ? $style.space4 : $style.space7) + 'px',
                cursor: header.column.getCanSort() ? 'pointer' : 'default'
              }"
            >
              <FlexRender
                :render="header.column.columnDef.header"
                :props="header.getContext()"
              />
              <BaseIcon
                v-if="header.column.getCanSort()"
                size="24"
                :name="getSortingIcon(header.column.getIsSorted())"
              />
            </Space>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="isLoading">
          <td :colspan="table.getAllColumns().length">
            <Space
              direction="columns"
              :align-x="mapAlign.center"
              :padding-y="Number($style.space7)"
            >
              <span>Ładowanie...</span>
            </Space>
          </td>
        </tr>
        <tr
          v-for="row in table.getRowModel().rows"
          v-else
          :key="row.id"
          @click="handleRowClick(row)"
        >
          <td
            v-for="cell in row.getVisibleCells()"
            :key="cell.id"
          >
            <div
              :class="{
                [$style.cell]: true,
                [$style.cellCollapsed]: cell.column.columnDef.meta?.collapsed
              }"
              :style="{ textAlign: cell.column.columnDef.meta?.align }"
            >
              <FlexRender
                :render="cell.column.columnDef.cell"
                :props="cell.getContext()"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <Pagination
      :can-next-page="table.getCanNextPage()"
      :can-previous-page="table.getCanPreviousPage()"
      :next-page="table.nextPage"
      :previous-page="table.previousPage"
      :go-to-page="handlePageChange"
      :current-page="table.getState().pagination.pageIndex"
      :pages="table.getPageCount() - 1"
    />
  </div>
</template>
<script lang="ts" setup generic="T">
import type { SpaceAlign } from '@/components/Space'
import type {
  ColumnDef,
  Row,
  RowSelectionState,
  SortDirection,
  VisibilityState
} from '@tanstack/vue-table'

import { G } from '@mobily/ts-belt'
import {
  FlexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useVueTable
} from '@tanstack/vue-table'
import { ref, watch } from 'vue'

import { Space } from '@ui/Space'

import BaseIcon from '@/components/BaseIcon.vue'

import { Pagination } from './components/Pagination'
import { valueUpdater } from './helpers/valueUpdater'

const mapAlign: Record<string, SpaceAlign> = {
  left: 'start',
  center: 'center',
  right: 'end'
}

const props = defineProps<{
  columns: ColumnDef<T>[]
  data: T[]
  defaultSortBy?: ColumnDef<T>['id']
  enableRowSelection?: boolean
  isLoading?: boolean
  onRowClick?: (data: T) => void
  stickyHeight?: number
  tableClass?: string
}>()
const columnVisibility = ref<VisibilityState>({})
const rowSelection = ref<RowSelectionState>({})

const table = useVueTable({
  get data() {
    return props.data
  },
  get columns() {
    return props.columns
  },
  state: {
    get columnVisibility() {
      return columnVisibility.value
    },
    get rowSelection() {
      return rowSelection.value
    }
  },
  enableMultiRowSelection: false,
  enableSortingRemoval: false,
  enableHiding: true,
  enableRowSelection: props.enableRowSelection,
  onRowSelectionChange: (updateOrValue) => {
    valueUpdater(updateOrValue, rowSelection)
  },
  getCoreRowModel: getCoreRowModel(),
  getPaginationRowModel: getPaginationRowModel(),
  getSortedRowModel: getSortedRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  onColumnVisibilityChange: (updateOrValue) => {
    valueUpdater(updateOrValue, columnVisibility)
  },
  defaultColumn: {
    size: undefined,
    meta: {
      collapsed: true,
      align: 'left'
    }
  },
  initialState: {
    sorting: [
      {
        desc: false,
        id: props.defaultSortBy || props.columns[0]?.id || 'name'
      }
    ],
    pagination: {
      pageSize: 20
    }
  }
})

const getSortingIcon = (isSorted: false | SortDirection): IconName => {
  if (isSorted) {
    return isSorted === 'asc' ? 'expand_less' : 'expand_more'
  }
  return 'unfold_more'
}

const handlePageChange = (page: number) => {
  table.setPageIndex(page)
}
const showAllColumns = (visible: boolean) => {
  table.getAllColumns().forEach((column) => {
    column.toggleVisibility(visible)
  })
}
const toggleAllColumns = (visible?: boolean) => {
  if (G.isNotNullable(visible)) {
    showAllColumns(visible)
  }
  const columnsVisible = Object.keys(columnVisibility.value).length
  if (columnsVisible > 0 && columnsVisible !== props.columns.length) {
    showAllColumns(true)
  } else {
    showAllColumns(false)
  }
}

const handleRowClick = (row: Row<T>) => {
  if (row.getCanSelect()) {
    row.getToggleSelectedHandler()(row.getIsSelected())
  }

  props.onRowClick?.(row.original)
}

defineExpose({
  toggleAllColumns,
  rowSelection,
  table
})

watch(
  () => table.getState().pagination.pageIndex,
  () => {
    window.scrollTo(0, 0)
  }
)
</script>

<style lang="scss" module scoped>
:export {
  space7: strip-unit($space-7);
  space6: strip-unit($space-6);
  space4: strip-unit($space-4);
}

table,
th,
td {
  border: 1px solid $state-base-border;
}

table {
  border-collapse: collapse;
  white-space: nowrap;
  table-layout: fixed;
  max-width: 100%;
  width: 100%;
}

th {
  background-color: #fff;
  z-index: 2;

  i {
    color: $fill-600;
  }
}

td {
  @include font-style('sm', 'medium');
}

tr {
  border-bottom: 1px solid $state-base-border;
}

thead > tr {
  background-color: $fill-50 !important;
}

tr:nth-child(even) {
  background-color: $fill-50;
}

tr:nth-child(odd) {
  background-color: #fff;
}

.headContent {
  padding: $space-7;
  display: flex;
  align-items: center;
  gap: 0;
  text-align: left;
}

.cell {
  padding: $space-4 $space-7;
  gap: $space-6;
}

.cellCollapsed {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
