import type { DietSearchDish, DietSearchIngredient } from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'
import type { QueryObserverResult } from '@tanstack/vue-query'
import type { ComputedRef, InjectionKey, Ref } from 'vue'

import { inject } from 'vue'

interface Data {
  dishes: DietSearchDish[]
  ingredients: DietSearchIngredient[]
}

export interface DietDetailProvider {
  nutrientValue: Ref<'choosen' | Nutrients>
  nutrients: ComputedRef<Nutrients[]>
  refetchSearchDishesAndIngredients: () => Promise<QueryObserverResult<Data>>
  searchDishesAndIngredients: Ref<Data | undefined>
  searchDishesAndIngredientsLoading: Ref<boolean>
  searchDishesAndIngredientsStatus: Ref<'error' | 'success' | 'pending'>
}

export const DietDetailInject = Symbol() as InjectionKey<DietDetailProvider>

export const useDietDetailProvider = () => inject(DietDetailInject) as DietDetailProvider
