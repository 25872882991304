import { G } from '@mobily/ts-belt'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

import { api } from '@/services/apiService'
import { reportError } from '@/utils/reportError'

export const authService = {
  updateTokens(accessToken: string, refreshToken: string) {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    sessionStorage.setItem('accessToken', accessToken)
    sessionStorage.setItem('refreshToken', refreshToken)
  },
  deleteTokens() {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    sessionStorage.removeItem('accessToken')
    sessionStorage.removeItem('refreshToken')
  },
  getAccessToken() {
    let accessToken = localStorage.getItem('accessToken')
    if (G.isNullable(accessToken)) {
      accessToken = sessionStorage.getItem('accessToken')
    }
    return accessToken
  },
  getRefreshToken() {
    let refreshToken = localStorage.getItem('refreshToken')
    if (G.isNullable(refreshToken)) {
      refreshToken = sessionStorage.getItem('refreshToken')
    }
    return refreshToken
  },
  userIdFormAccessToken() {
    const accessToken = this.getAccessToken()
    if (G.isNotNullable(accessToken)) {
      // @ts-ignore
      return jwtDecode(accessToken).user_id
    }
    return null
  },
  redirectToLoginPage(path = '') {
    window.location.replace(import.meta.env.VUE_APP_AUTH_LOGIN_URL + path)
  },
  redirectToLogoutPage() {
    window.location.href = import.meta.env.VUE_APP_AUTH_LOGOUT_URL
  },
  async verifyAccessToken() {
    const accessToken = this.getAccessToken()
    if (G.isNullable(accessToken)) {
      throw new Error('AccessToken does not exist')
    }
    return await axios.post(import.meta.env.VUE_APP_AUTH_VERIFY_TOKEN_URL, { token: accessToken })
  },
  async refreshAccessToken() {
    const refreshToken = this.getRefreshToken()
    if (G.isNullable(refreshToken)) {
      throw new Error('RefreshToken does not exist')
    }
    try {
      const response = await axios.post<{ access: string; refresh: string }>(
        import.meta.env.VUE_APP_AUTH_REFRESH_TOKEN_URL,
        {
          refresh: refreshToken
        }
      )
      this.updateTokens(response.data.access, response.data.refresh)
      return response
    } catch (error) {
      reportError(error, 'Error refreshing access token', {
        module: 'auth'
      })
      throw error
    }
  },
  async fetchUser(userId: string) {
    const url = `/users/${userId}/`
    return api.get(url)
  }
}
