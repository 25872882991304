<template>
  <Space
    :space="12"
    :padding-x="16"
    :padding-y="6"
  >
    <Space direction="columns">
      <Space
        direction="columns"
        align-y="center"
      >
        <T as="span">
          Kcal od (min)
        </T>
        <KcalmarInput
          :model-value="kcalMinValue"
          :precision="1"
          :step="0.1"
          :min="kcalMin"
          :max="kcalMax"
          variant="underline"
          size="small"
          class="w-80!"
          @update:model-value="setKcalMin"
        />
      </Space>
      <Space
        direction="columns"
        align-y="center"
      >
        <T as="span">
          Kcal do (max)
        </T>
        <KcalmarInput
          :min="kcalMin"
          :max="kcalMax"
          :precision="1"
          :step="0.1"
          variant="underline"
          size="small"
          class="w-80!"
          :model-value="kcalMaxValue"
          @update:model-value="setKcalMax"
        />
      </Space>
    </Space>
    <Space
      :fluid="true"
      direction="columns"
    >
      <Space
        :fill="true"
        :wrap="true"
      >
        <T as="span">
          Rodzaj produktu
        </T>
        <KcalmarCheckbox
          v-for="category in categoryChoices"
          :key="category.value"
          :label="category.text"
          :name="category.text"
          :disabled="category.disabled"
          :model-value="category.active"
          size="small"
          @update:model-value="setIngredientType(category.value)"
        />
      </Space>
      <Space
        :fill="true"
        :wrap="true"
      >
        <span>Baza produktów</span>
        <Space
          v-for="ingredientBase in ingredientBasesChoices"
          :key="ingredientBase.value"
        >
          <KcalmarCheckbox
            :label="ingredientBase.text"
            :name="ingredientBase.text"
            :disabled="ingredientBase.disabled"
            :model-value="ingredientBase.active"
            size="small"
            @update:model-value="setIngredientBase(ingredientBase.value)"
          />
          <div
            v-if="ingredientBase.disabled"
            class="unlock"
            @click="showIngredientsIzzModal()"
          >
            Odblokuj
          </div>
        </Space>
      </Space>
      <Space
        :fill="true"
        :wrap="true"
      >
        <span>Kategoria</span>
        <KcalmarCheckbox
          v-for="ingredientCategory in ingredientCategoriesChoices"
          :key="ingredientCategory.value"
          :label="ingredientCategory.text"
          :name="ingredientCategory.text"
          :model-value="ingredientCategory.active"
          size="small"
          @update:model-value="setIngredientCategory(ingredientCategory.value)"
        />
      </Space>
      <Space
        :fill="true"
        :wrap="true"
      >
        <span>Tagi systemowe</span>
        <KcalmarCheckbox
          v-for="ingredientTag in ingredientTagsChoices"
          :key="ingredientTag.value"
          :label="ingredientTag.text"
          :name="ingredientTag.text"
          :model-value="ingredientTag.active"
          size="small"
          @update:model-value="setIngredientTag(ingredientTag.value)"
        />
      </Space>
    </Space>
  </Space>
</template>

<script lang="ts" setup>
import type { IngredientType } from './provider'

import { A, D } from '@mobily/ts-belt'
import { computed, onBeforeMount, ref, watch } from 'vue'

import { Space } from '@/components/Space'
import { KcalmarCheckbox } from '@/components/ui/KcalmarCheckbox'
import { KcalmarInput } from '@/components/ui/KcalmarInput'
import { T } from '@/components/ui/T'
import { ingredientBases, ingredientCategories, ingredientTags } from '@/utils/common'

import { useIngredientsProvider } from './provider'

interface List {
  text: string
  value: number
}
export interface CategoryChoice {
  active: boolean
  disabled: boolean
  text: string
  value: IngredientType
}
export interface Choice {
  active: boolean
  text: string
  value: string
}

export interface IngredientBaseChoice {
  active: boolean
  disabled: boolean
  text: string
  value: number
}

const {
  kcalMax,
  kcalMin,
  ingredientsType,
  izzConditionsConfirmed,
  showIngredientsIzzModal,
  resetFiltersValue,
  setIngredientCategoryForFilters: _setIngredientCategoryForFilters,
  setIngredientBaseForFilters: _setIngredientBaseForFilters,
  setIngredientTagForFilters: _setIngredientTagForFilters,
  setIngredientTypeForFilters: _setIngredientTypeForFilters,
  setKcalMin: _setKcalMin,
  setKcalMax: _setKcalMax,
  resetKcalMinMax
} = useIngredientsProvider()

const categoryValues = ref<IngredientType[]>([])
const ingredientTagsValues = ref<string[]>([])
const ingredientBasesValues = ref<number[]>([])
const ingredientCategoriesValues = ref<number[]>([])
const ingredientBasesList = ref<List[]>([])
const kcalMinValue = ref(0)
const kcalMaxValue = ref(9999)

const categoryChoices = computed<CategoryChoice[]>(() => {
  const choices: CategoryChoice[] = [
    {
      value: 'is_public',
      text: 'Publiczne',
      active: false,
      disabled: ingredientsType.value === 'is_private' || ingredientsType.value === 'is_public'
    },
    {
      value: 'is_private',
      text: 'Prywatne',
      active: false,
      disabled: ingredientsType.value === 'is_public' || ingredientsType.value === 'is_private'
    },
    {
      value: 'is_favorite',
      text: 'Ulubione',
      active: false,
      disabled: ingredientsType.value === 'is_favorite'
    }
  ]
  return choices.map((choice) => {
    choice.active =
      categoryValues.value.includes(choice.value) || choice.value === ingredientsType.value
    return choice
  })
})

const ingredientTagsChoices = computed(() => {
  const choices: Choice[] = []
  A.forEach(D.keys(ingredientTags), (tag) => {
    choices.push({
      value: tag,
      text: ingredientTags[tag],
      active: ingredientTagsValues.value.includes(tag)
    })
  })

  return choices
})

const ingredientCategoriesChoices = computed(() => {
  return ingredientCategories.map((choice) => {
    const active = ingredientCategoriesValues.value.includes(choice.value)
    return {
      value: choice.value,
      text: choice.text,
      active
    }
  })
})

const ingredientBasesChoices = computed<IngredientBaseChoice[]>(() => {
  return ingredientBases.map((choice) => {
    const active = ingredientBasesValues.value.includes(choice.value)
    const disabled = !izzConditionsConfirmed.value && choice.text === 'IŻŻ'
    return {
      value: choice.value,
      text: choice.text,
      active,
      disabled
    }
  })
})

const setKcalMin = (value: number) => {
  kcalMinValue.value = value
  _setKcalMin(value)
}

const setKcalMax = (value: number) => {
  kcalMaxValue.value = value
  _setKcalMax(value)
}

const addOrRemoveFromList = <T>(valuesList: T[], value: T) => {
  const index = valuesList.indexOf(value)
  if (index === -1) {
    valuesList.push(value)
  } else {
    valuesList.splice(index, 1)
  }
}
const setIngredientTag = (value: string) => {
  addOrRemoveFromList(ingredientTagsValues.value, value)
  _setIngredientTagForFilters(ingredientTagsValues.value)
}
const setIngredientType = (value: string) => {
  addOrRemoveFromList(categoryValues.value, value)
  _setIngredientTypeForFilters(categoryValues.value)
}
const setIngredientCategory = (value: number) => {
  addOrRemoveFromList(ingredientCategoriesValues.value, value)
  _setIngredientCategoryForFilters(ingredientCategoriesValues.value)
}
const setIngredientBase = (value: number) => {
  addOrRemoveFromList(ingredientBasesValues.value, value)
  _setIngredientBaseForFilters(ingredientBasesValues.value)
}
const resetExtendedFilters = () => {
  categoryValues.value = []
  ingredientTagsValues.value = []
  ingredientCategoriesValues.value = []
  ingredientBasesValues.value = []
  kcalMinValue.value = kcalMin.value
  kcalMaxValue.value = kcalMax.value
  resetKcalMinMax()
}

onBeforeMount(() => {
  kcalMaxValue.value = kcalMax.value
  kcalMinValue.value = kcalMin.value
  ingredientBasesList.value = ingredientBases
  ingredientBasesList.value.push({
    value: 7,
    text: 'Kcalmar.pro'
  })
})
watch(ingredientsType, () => {
  resetExtendedFilters()
})
watch(resetFiltersValue, () => {
  resetExtendedFilters()
})
watch(kcalMin, (newValue) => {
  kcalMinValue.value = newValue
})
watch(kcalMax, (newValue) => {
  kcalMaxValue.value = newValue
})
</script>
