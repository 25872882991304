<template>
  <div
    class="move-copy"
    @mouseleave="close"
  >
    <div class="title">
      {{ title }}
    </div>
    <div class="search">
      <BaseInputSearch
        v-model="search"
        :debounce-after="100"
        :force-focus="true"
      />
    </div>
    <div class="groups">
      <div
        v-for="group in filteredGroups"
        :key="`move-copy-${group.id}`"
        class="group"
        @click="selectGroup(group)"
      >
        {{ group.name }}
      </div>
    </div>
    <div
      v-if="!hideAddGroup"
      class="group-add"
    >
      <StyledButton
        variant="secondary"
        left-icon="add"
        @click="addGroupClick"
      >
        Dodaj grupę
      </StyledButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { GroupOption } from '@/hooks/useDietList'
import type { PropType } from 'vue'

import { computed, ref } from 'vue'

import BaseInputSearch from '@/components/BaseInputSearch.vue'
import { StyledButton } from '@/components/StyledButton'

const emit = defineEmits(['close', 'selectGroup', 'addGroupClick'])
const props = defineProps({
  title: {
    required: true,
    type: String
  },
  groups: {
    required: true,
    type: Array as PropType<GroupOption[]>
  },
  hideAddGroup: {
    default: false,
    type: Boolean
  }
})
const search = ref('')
const filteredGroups = computed(() =>
  props.groups.filter((group) => group.name.toLowerCase().includes(search.value.toLowerCase()))
)

const close = () => {
  emit('close')
}
const selectGroup = (group: GroupOption) => {
  emit('selectGroup', group.id)
}
const addGroupClick = () => {
  emit('addGroupClick')
}
</script>

<style scoped>
.move-copy {
  position: absolute;
  max-height: 338px;
  box-shadow: 0 1px 6px 0 hsl(0deg 0% 61% / 50%);
  background: #fff;
  z-index: 90;
  font-size: 12px;
  color: var(--color-primary-text);
  width: 250px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 12px;
}

.title {
  font-family: Montserrat-SemiBold;
  line-height: 15px;
}

.search {
  height: 30px;
}

.groups {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 1px;
  scrollbar-width: thin;
  flex-grow: 1;
  min-height: 100px;
  max-height: 250px;
}

.groups::-webkit-scrollbar {
  width: 6px;
}

.group {
  cursor: pointer;
  padding: 5px;
  word-break: break-word;
}

.group:hover {
  background: var(--color-main-10);
  font-family: Montserrat-Medium;
}

.group-add {
  border-top: 1px solid #f5f5f5;
  height: 42px;
  min-height: 42px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}
</style>
