<template>
  <div class="diet-list">
    <div class="search">
      <BaseInputSearch
        :placeholder="'Wyszukaj jadłospis po nazwie lub opisie...'"
        :debounce-after="750"
        :model-value="searchPhrase"
        @update:model-value="filterDiets($event)"
      />
    </div>
    <div class="nav-actions">
      <DietListNav
        :diet-type="dietType"
        :diets-count="dietsCount"
      />
      <div class="actions">
        <Space
          direction="columns"
          :space="6"
        >
          <Space
            direction="columns"
            :space="0"
          >
            <div class="move-diet-wrapper">
              <BaseTooltip class="base-tooltip--top base-tooltip--center">
                <template #tooltip>
                  Przenieś zaznaczone jadłospisy do wybranej grupy<br>
                  <template v-if="moveDisabled">
                    <br><span class="tooltip-bold">Maksymalna dozwolona liczba zaznaczonych jednocześnie jadłospisów to
                      {{ dietsMoveLimit }}.</span>
                    <br><span class="tooltip-bold">Zaznaczono {{ selectedDietsCount }}. Zmniejsz liczbę zaznaczonych
                      jadłospisów.</span>
                  </template>
                </template>
                <IconButton
                  name="folder"
                  :disabled="moveDisabled || selectedDietsEmpty || loading"
                  @click.stop="showMoveDietMenu"
                />
              </BaseTooltip>
              <DietListCopyMoveMenu
                v-if="moveDietMenu.visible"
                class="move-diet-menu"
                :title="'Przenieś do:'"
                :groups="groupsOptions"
                @select-group="moveDiet"
                @add-group-click="hideMoveDietMenu(), showAddGroupModal()"
                @close="hideMoveDietMenu()"
              />
            </div>
            <BaseTooltip
              v-if="canAssignToLeader"
              class="base-tooltip--top base-tooltip--center"
            >
              <template #tooltip>
                Przypisz zaznaczone jadłospisy do prowadzącego
                <template v-if="shareDisabled">
                  <br>
                  <br><span class="tooltip-bold">Maksymalna dozwolona liczba zaznaczonych jednocześnie jadłospisów to
                    {{ dietsAssignToLeaderLimit }}.</span>
                  <br><span class="tooltip-bold">Zaznaczono {{ selectedDietsCount }}. Zmniejsz liczbę zaznaczonych
                    jadłospisów.</span>
                </template>
              </template>
              <IconButton
                name="school"
                :disabled="assignToLeaderDisabled || selectedDietsEmpty || loading"
                @click="showAssignToLeaderModal()"
              />
            </BaseTooltip>
            <BaseTooltip class="base-tooltip--top base-tooltip--center">
              <template #tooltip>
                Udostępnij zaznaczone jadłospisy innemu użytkownikowi
                <template v-if="shareDisabled">
                  <br>
                  <br><span class="tooltip-bold">Maksymalna dozwolona liczba zaznaczonych jednocześnie jadłospisów to
                    {{ dietsShareLimit }}.</span>
                  <br><span class="tooltip-bold">Zaznaczono {{ selectedDietsCount }}. Zmniejsz liczbę zaznaczonych
                    jadłospisów.</span>
                </template>
              </template>

              <IconButton
                name="share"
                :disabled="shareDisabled || selectedDietsEmpty || loading"
                @click="showShareDietModal()"
              />
            </BaseTooltip>
            <BaseTooltip class="base-tooltip--top base-tooltip--center">
              <template #tooltip>
                Usuń zaznaczone jadłospisy
              </template>
              <IconButton
                name="delete"
                type="destructive"
                variant="outline"
                :disabled="selectedDietsEmpty || loading"
                @click="showDeleteDietModal()"
              />
            </BaseTooltip>
          </Space>
          <StyledButton
            left-icon="add"
            variant="secondary"
            :disabled="loading"
            @click="showAddGroupModal"
          >
            Dodaj grupę
          </StyledButton>
          <StyledButton
            v-if="!isUniversityDiet"
            left-icon="add"
            :disabled="loading"
            @click="showAddDietModal"
          >
            Dodaj jadłospis
          </StyledButton>
        </Space>
      </div>
    </div>
    <div class="list">
      <div class="header row">
        <div class="column" />
        <div class="column">
          <BaseCheckbox
            :model-value="selected.all"
            @update:model-value="handleAllCheckbox()"
          />
        </div>
        <div
          class="header__group column"
          @click="setSortByGroups()"
        >
          <div class="column__sortable">
            Nazwa grupy
            <BaseIcon :name="sortByGroups === 'name' ? 'expand_less' : 'expand_more'" />
          </div>
        </div>
        <div class="header__name column">
          <div
            class="column__sortable"
            @click="setSortByDiets('name')"
          >
            Nazwa jadłospisu
            <BaseIcon :name="getIconBySort('name')" />
          </div>
        </div>
        <div class="header__description column">
          <div
            class="column__sortable"
            @click="setSortByDiets('description')"
          >
            Opis
            <BaseIcon :name="getIconBySort('description')" />
          </div>
        </div>
        <div class="column">
          <div
            class="column__sortable"
            @click="setSortByDiets('created')"
          >
            Data utworzenia
            <BaseIcon :name="getIconBySort('created')" />
          </div>
        </div>
        <div class="column">
          <div
            class="column__sortable"
            @click="setSortByDiets('calories')"
          >
            Kaloryczność (kcal/dzień)
            <BaseIcon :name="getIconBySort('calories')" />
          </div>
        </div>
        <div
          v-if="loading"
          class="list-loader-bar"
        >
          <div class="list-loader-bar__progress" />
        </div>
      </div>
      <div
        v-if="groups.length > 0 || searchPhrase === ''"
        class="groups"
      >
        <div
          v-for="group in groups"
          :key="`group-${group.id}`"
          class="group-wrapper"
          @drop="handleDietDrop(group, $event)"
          @dragover.prevent
          @dragenter.prevent="setHoverGroupOrDiet(`group-${group.id}`)"
        >
          <div
            v-if="group.id === 0"
            class="group-zero-info"
          >
            <BaseIcon
              type="info"
              name="info"
            />
            Poniższe jadłospisy nie znajdują się w żadnej ze stworzonych przez Ciebie grup. Dodaj je
            do grup istniejących bądź utwórz nową, aby ułatwić sobie pracę.
          </div>
          <div
            class="group row"
            :class="{
              'group--selected': group.selected && groupIsSelectable(group),
              'group--hovered': isHoveredGroupOrDiet(`group-${group.id}`)
            }"
            @mouseenter="setHoverGroupOrDiet(`group-${group.id}`)"
            @mouseleave="setHoverGroupOrDiet('')"
          >
            <div class="column">
              <IconButton
                class="group__expand"
                :name="group.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'"
                @click="expandGroup(group)"
              />
            </div>
            <div class="column">
              <BaseCheckbox
                :partial="groupPartiallySelected(group)"
                :disabled="!groupIsSelectable(group)"
                :model-value="group.selected && groupIsSelectable(group)"
                @update:model-value="handleGroupCheckbox(group)"
              />
            </div>
            <div class="column group__name">
              <span>{{ group.name }} <span class="group__count">({{ group.dietsCount }})</span></span>
            </div>
            <div class="column" />
            <div class="column" />
            <div class="column" />
            <div class="column" />
            <div
              v-if="isHoveredGroupOrDiet(`group-${group.id}`) && group.id !== 0"
              class="row__actions"
            >
              <div class="group__actions">
                <BaseTooltip class="base-tooltip--top base-tooltip--right">
                  <template #tooltip>
                    Edytuj nazwę
                  </template>

                  <IconButton
                    name="edit"
                    @click.stop="showGroupNameEdit(group)"
                  />
                </BaseTooltip>
                <BaseTooltip class="base-tooltip--top base-tooltip--right">
                  <template #tooltip>
                    Usuń grupę i przenieś<br>jadłospisy do katalogu głównego
                  </template>
                  <IconButton
                    type="destructive"
                    name="delete"
                    variant="outline"
                    @click="showDeleteGroupModal(group)"
                  />
                </BaseTooltip>
              </div>
            </div>
            <div
              v-if="group.id === groupNameEdit.id"
              class="group__name-edit"
              @click.stop
            >
              <input
                ref="groupNameInput"
                v-model="groupNameEdit.name"
                class="group__name-edit__input"
                maxlength="50"
                @keyup.enter="saveGroupName"
                @keyup.esc="hideGroupNameEdit"
              >
              <IconButton
                name="save"
                :disabled="groupNameEdit.name.length === 0"
                @click="saveGroupName"
              />
            </div>
          </div>
          <div
            v-if="group.expanded"
            class="copy-diet-wrapper"
          >
            <div
              class="diets-wrapper"
              :class="{
                'diets-wrapper--scroll': group.diets.length > 10,
                'diets--copy-active': copyDietMenu.visible
              }"
              @scroll="throttledDietScroll(group, $event)"
            >
              <div
                v-if="group.diets.length > 0"
                class="diets"
              >
                <div
                  v-if="loadingDiets"
                  class="diets-loader"
                />
                <div
                  v-for="(diet, dietIndex) in group.diets"
                  :key="`group-${group.id}-diet-${diet.id}`"
                  class="diet row"
                  :class="{
                    'diet--selected': diet.selected || isHighlightedDiet(`diet-${diet.id}`)
                  }"
                  @mouseenter="setHoverGroupOrDiet(`diet-${diet.id}`)"
                  @mouseleave="setHoverGroupOrDiet('')"
                >
                  <div class="column" />
                  <div class="column">
                    <BaseCheckbox
                      :model-value="diet.selected"
                      @update:model-value="handleDietCheckbox(diet, group)"
                    />
                  </div>
                  <div class="column" />
                  <div class="column diet__name">
                    <div
                      v-if="diet.id === dietNameEdit.id"
                      class="diet__name-edit"
                      @click.stop
                    >
                      <input
                        ref="dietNameInput"
                        v-model="dietNameEdit.name"
                        class="diet__name-edit__input"
                        maxlength="85"
                        @keyup.enter="saveDietName"
                        @keyup.esc="hideDietNameEdit"
                      >
                      <IconButton
                        name="save"
                        size="small"
                        icon-size="16"
                        :disabled="dietNameEdit.name.length === 0"
                        @click="saveDietName"
                      />
                    </div>
                    <router-link
                      v-else
                      class="diet__name-link"
                      :to="{ name: 'DietDetail', params: { dietType, dietId: diet.id } }"
                      :draggable="'true'"
                      @dragstart="handleDietDragStart(diet, group, $event)"
                      @dragend="handleDragEnd"
                    >
                      {{ diet.name }}
                    </router-link>
                  </div>
                  <div class="column diet__description">
                    <span :title="diet.description">{{ diet.description }}</span>
                  </div>
                  <div class="column diet__created">
                    {{ diet.created }}
                  </div>
                  <div class="column diet__calories">
                    {{ diet.calories }}
                  </div>
                  <div
                    v-if="isHoveredGroupOrDiet(`diet-${diet.id}`)"
                    class="row__actions"
                  >
                    <div class="diet__actions">
                      <BaseTooltip
                        class="base-tooltip--right"
                        :class="{
                          'base-tooltip--top': dietIndex !== 0 || group.diets.length <= 10,
                          'base-tooltip--bottom': dietIndex === 0 && group.diets.length > 10
                        }"
                      >
                        <template #tooltip>
                          Edytuj nazwę
                        </template>
                        <IconButton
                          name="edit"
                          @click.stop="showDietNameEdit(diet)"
                        />
                      </BaseTooltip>
                      <BaseTooltip
                        class="base-tooltip--right"
                        :class="{
                          'base-tooltip--top': dietIndex !== 0 || group.diets.length <= 10,
                          'base-tooltip--bottom': dietIndex === 0 && group.diets.length > 10
                        }"
                      >
                        <template #tooltip>
                          Skopiuj jadłospis
                        </template>
                        <IconButton
                          name="filter_none"
                          icon-size="18"
                          @click.stop="showCopyDietMenu(diet.id)"
                        />
                      </BaseTooltip>
                      <BaseTooltip
                        v-if="dietType !== 'university'"
                        class="base-tooltip--right"
                        :class="{
                          'base-tooltip--top': dietIndex !== 0 || group.diets.length <= 10,
                          'base-tooltip--bottom': dietIndex === 0 && group.diets.length > 10
                        }"
                      >
                        <template #tooltip>
                          Przypisz jadłospis do pacjenta
                        </template>
                        <IconButton
                          name="person_add"
                          @click.stop="showAssignToPatientModal(diet.id)"
                        />
                      </BaseTooltip>
                      <BaseTooltip
                        v-if="canAssignToLeader"
                        class="base-tooltip--right"
                        :class="{
                          'base-tooltip--top': dietIndex !== 0 || group.diets.length <= 10,
                          'base-tooltip--bottom': dietIndex === 0 && group.diets.length > 10
                        }"
                      >
                        <template #tooltip>
                          Przypisz jadłospis do prowadzącego
                        </template>
                        <IconButton
                          name="school"
                          @click.stop="showAssignToLeaderModal(diet.id)"
                        />
                      </BaseTooltip>
                      <BaseTooltip
                        class="base-tooltip--right"
                        :class="{
                          'base-tooltip--top': dietIndex !== 0 || group.diets.length <= 10,
                          'base-tooltip--bottom': dietIndex === 0 && group.diets.length > 10
                        }"
                      >
                        <template #tooltip>
                          Udostępnij jadłospis innemu użytkownikowi
                        </template>
                        <IconButton
                          name="share"
                          @click="showShareDietModal(diet.id)"
                        />
                      </BaseTooltip>
                      <BaseTooltip
                        class="base-tooltip--right"
                        :class="{
                          'base-tooltip--top': dietIndex !== 0 || group.diets.length <= 10,
                          'base-tooltip--bottom': dietIndex === 0 && group.diets.length > 10
                        }"
                      >
                        <template #tooltip>
                          Usuń jadłospis
                        </template>
                        <IconButton
                          variant="outline"
                          type="destructive"
                          name="delete"
                          @click="showDeleteDietModal(diet)"
                        />
                      </BaseTooltip>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="diets-empty"
              >
                <div class="diets-empty__info">
                  Twoja grupa <span class="diets-empty__info-name">{{ group.name }}</span> jest
                  pusta.<br>
                  <template v-if="isUniversityDiet">
                    Dodaj do niej jadłospisy przeciągając istniejące już jadłospisy z innych grup.
                  </template>
                  <template v-else-if="group.name === 'Udostępnione'">
                    W tej grupie będą znajdowały się jadłospisy udostępnione Ci przez innych
                    dietetyków.
                  </template>
                  <template v-else>
                    Dodaj do niej jadłospisy klikając w przycisk powyżej
                    <span class="diets-empty__info-button">+ Dodaj jadłospis</span> lub przeciągnij
                    istniejące już jadłospisy z innych grup.
                  </template>
                </div>
              </div>
            </div>
            <DietListCopyMoveMenu
              v-if="copyDietMenu.visible"
              class="copy-diet-menu"
              :title="'Skopiuj do:'"
              :groups="groupsOptions"
              @select-group="copyDiet"
              @add-group-click="hideCopyDietMenu(), showAddGroupModal()"
              @close="hideCopyDietMenu()"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="groups-empty"
      >
        <BaseIcon
          name="info"
          type="info"
        />
        <span>Nie znaleziono żadnych jadłospisów.</span>
      </div>
      <div
        v-if="loading"
        class="list-loader"
      />
    </div>
    <BaseModal
      v-if="addDietModal.visible"
      :modal-class="'add-diet-modal'"
      @close="hideAddDietModal"
      @confirm="addDiet"
      @click="deactivateSelects"
    >
      <template #title>
        Dodawanie jadłospisu
      </template>
      <div class="modal-label">
        Wprowadź nazwę jadłospisu
      </div>
      <BaseInput
        v-model="addDietModal.name"
        class="add-diet-modal__input"
        :maxlength="85"
        :placeholder="'Twoja nazwa jadłospisu'"
        :force-focus="true"
      />
      <div class="modal-label">
        Wybierz grupę dla jadłospisu
      </div>
      <BaseSelect
        v-model="addDietModal.groupId"
        class="add-diet-modal__select"
        :options="addDietModalGroups"
      />
      <template #actions>
        <StyledButton
          variant="outline"
          @click.once="hideAddDietModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton
          :disabled="addDietButtonDisabled"
          @click.once="addDiet"
        >
          Dodaj jadłospis
        </StyledButton>
      </template>
    </BaseModal>

    <BaseModal
      v-if="deleteDietModal.visible"
      @close="hideDeleteDietModal"
      @confirm="deleteDiet"
    >
      <template
        v-if="deleteDietModal.diet"
        #title
      >
        Usuwanie jadłospisu
      </template>
      <template
        v-else
        #title
      >
        Usuwanie jadłospisów
      </template>
      <div
        v-if="deleteDietModal.diet"
        class="diet-delete-modal-info"
      >
        Czy chcesz usunąć jadłospis o nazwie
        <span class="modal-bold">{{ deleteDietModal.diet.name }}</span>?
      </div>
      <div v-else>
        <template v-if="selected.diets.size === 1">
          Zaznaczono <span class="modal-bold">1 jadłospis</span>. Czy na pewno chcesz go usunąć?
        </template>
        <template v-else>
          Zaznaczono <span class="modal-bold">{{ selected.diets.size }} jadłospisy</span>. Czy na
          pewno chcesz je usunąć?
        </template>
      </div>
      <template #actions>
        <StyledButton
          variant="outline"
          @click.once="hideDeleteDietModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton @click.once="deleteDiet">
          Usuń
        </StyledButton>
      </template>
    </BaseModal>

    <BaseModal
      v-if="addGroupModal.visible"
      :modal-class="'add-group-modal'"
      @close="hideAddGroupModal"
      @confirm="addGroup"
    >
      <template #title>
        Dodawanie grupy
      </template>
      <div class="modal-label">
        Wprowadź nazwę grupy
      </div>
      <BaseInput
        v-model="addGroupModal.name"
        class="add-group-modal__input"
        :maxlength="50"
        :placeholder="'Twoja nazwa grupy'"
        :force-focus="true"
      />
      <template #actions>
        <StyledButton
          variant="outline"
          @click.once="hideAddGroupModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton
          :disabled="addGroupButtonDisabled"
          @click.once="addGroup"
        >
          Dodaj grupę
        </StyledButton>
      </template>
    </BaseModal>

    <BaseModal
      v-if="deleteGroupModal.visible"
      @close="hideDeleteGroupModal"
      @confirm="deleteGroup"
    >
      <template #title>
        Usuwanie grupy
      </template>
      <div class="group-delete-modal-info">
        Czy na pewno chcesz usunąć grupę
        <span class="modal-bold">{{ deleteGroupModal?.group?.name ?? '' }}</span>?<br>
        Jej zawartość zostanie przeniesiona do katalogu głównego.
      </div>
      <template #actions>
        <StyledButton
          variant="outline"
          @click.once="hideDeleteGroupModal"
        >
          Anuluj
        </StyledButton>
        <StyledButton @click.once="deleteGroup">
          Usuń
        </StyledButton>
      </template>
    </BaseModal>

    <DietAssignToPatientModal
      v-if="assignToPatientModal.visible"
      :diet-type="dietType"
      :diet-id="assignToPatientModal.dietId"
      @close="hideAssignToPatientModal"
    />

    <DietAssignToLeaderModal
      v-if="assignToLeaderModal.visible"
      :diet-type="dietType"
      :diets-ids="assignToLeaderModal.dietsIds"
      @close="hideAssignToLeaderModal"
    />

    <DietShareModal
      v-if="shareDietModal.visible"
      :diet-type="dietType"
      :diets-ids="shareDietModal.dietsIds"
      @close="hideShareDietModal"
    />

    <div
      id="ghost-image"
      class="ghost-image"
    >
      <BaseIcon
        class="ghost-image__icon"
        name="assignment"
      /> {{ ghostText() }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DietType } from '@/store/types'
import type { PropType } from 'vue'

import { computed } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInput from '@/components/BaseInput.vue'
import BaseInputSearch from '@/components/BaseInputSearch.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import DietAssignToLeaderModal from '@/components/diets/DietAssignToLeaderModal.vue'
import DietAssignToPatientModal from '@/components/diets/DietAssignToPatientModal.vue'
import DietListCopyMoveMenu from '@/components/diets/DietListCopyMoveMenu.vue'
import DietListNav from '@/components/diets/DietListNav.vue'
import DietShareModal from '@/components/diets/DietShareModal.vue'
import { IconButton } from '@/components/IconButton'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { useDietList } from '@/hooks/useDietList'

const props = defineProps({
  dietType: {
    required: true,
    type: String as PropType<DietType>
  }
})

const dietType = computed(() => props.dietType)

const {
  groupNameInput,
  dietNameInput,
  addDiet,
  addDietButtonDisabled,
  addDietModalGroups,
  addGroup,
  addGroupButtonDisabled,
  addGroupModal,
  addDietModal,
  assignToLeaderDisabled,
  assignToLeaderModal,
  assignToPatientModal,
  canAssignToLeader,
  copyDiet,
  copyDietMenu,
  deactivateSelects,
  deleteDiet,
  deleteDietModal,
  deleteGroup,
  deleteGroupModal,
  dietNameEdit,
  dietsAssignToLeaderLimit,
  dietsCount,
  dietsMoveLimit,
  dietsShareLimit,
  moveDiet,
  moveDietMenu,
  filterDiets,
  hideDietNameEdit,
  ghostText,
  groupIsSelectable,
  groupNameEdit,
  groups,
  groupsOptions,
  handleAllCheckbox,
  handleDietCheckbox,
  handleDietDragStart,
  handleDietDrop,
  showAddDietModal,
  hideAddDietModal,
  showDeleteDietModal,
  hideDeleteDietModal,
  showAddGroupModal,
  hideAddGroupModal,
  showDeleteGroupModal,
  hideDeleteGroupModal,
  showDietNameEdit,
  saveDietName,
  showGroupNameEdit,
  saveGroupName,
  handleDragEnd,
  isUniversityDiet,
  selectedDietsEmpty,
  moveDisabled,
  shareDisabled,
  groupPartiallySelected,
  setSortByDiets,
  setSortByGroups,
  expandGroup,
  setHoverGroupOrDiet,
  isHoveredGroupOrDiet,
  isHighlightedDiet,
  handleGroupCheckbox,
  showAssignToLeaderModal,
  hideAssignToLeaderModal,
  showAssignToPatientModal,
  hideAssignToPatientModal,
  showShareDietModal,
  hideShareDietModal,
  showCopyDietMenu,
  hideCopyDietMenu,
  showMoveDietMenu,
  hideMoveDietMenu,
  selected,
  shareDietModal,
  searchPhrase,
  loading,
  getIconBySort,
  sortByGroups,
  loadingDiets,
  selectedDietsCount,
  hideGroupNameEdit,
  throttledDietScroll
} = useDietList({ dietType: dietType.value })
</script>

<style scoped>
.diet-list {
  margin-bottom: 24px;
  min-width: 1050px;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 18px;
  margin-bottom: 24px;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  position: sticky;
  top: 0;
  z-index: 150;
}

.nav-actions {
  display: flex;
  position: sticky;
  top: 68px;
  z-index: 150;
  background: #f8f8f8;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-group,
.add-diet {
  margin-left: 16px;
}

.list {
  background: #fff;
  padding: 0 12px 12px;
  font-size: 12px;
  position: relative;
}

.list-loader {
  background: rgb(255 255 255 / 40%);
  display: flex;
  position: absolute;
  inset: 0;
  align-items: top;
  justify-content: center;
  z-index: 100;
  cursor: wait;
}

.list-loader-bar {
  background-color: transparent;
  border-radius: 9999px;
  width: 100%;
  height: 2px;
  position: absolute;
  inset: 52px 0 0;
  overflow: hidden;
  z-index: 200;
}

.list-loader-bar__progress {
  background-color: var(--color-main-100);
  border-radius: 9999px;
  position: absolute;
  bottom: 0;
  top: 0;
  width: 50%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: progress-bar;
}

@keyframes progress-bar {
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
}

.row {
  display: grid;
  grid-template-columns: 28px 12px 150px minmax(165px, auto) 180px 150px 180px;
  grid-column-gap: 10px;
}

.row__actions {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
}

.column__sortable {
  height: 52px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.column__sortable .base-icon {
  cursor: pointer;
}

.header {
  height: 52px;
  border-bottom: 2px solid #eaeaea;
  margin-bottom: 12px;
  font-family: Montserrat-Medium;
  color: var(--color-main-100);
  position: sticky;
  background: #fff;
  top: 112px;
  z-index: 100;
}

.header__group,
.header__name,
.header__description {
  justify-content: left;
}

.groups-empty {
  display: flex;
  gap: 10px;
  padding: 20px 0;
  align-items: center;
}

.group-zero-info {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
}

.group {
  height: 32px;
  background: #f5f5f5;
  margin-bottom: 6px;
  position: relative;
}

.group--selected {
  background: #eaeaea;
}

.group:hover {
  background: var(--color-main-10);
}

.group--hovered {
  background: var(--color-main-10);
}

.group__expand {
  position: relative;
  left: 5px;
}

.group__name {
  font-family: Montserrat-Medium;
  justify-content: left;
  width: 600px;
}

.group__count {
  color: var(--color-secondary-text);
}

.group__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 50px;
  background: linear-gradient(to right, transparent, var(--color-main-10) 50px);
  height: 32px;
}

.group__name-edit {
  position: absolute;
  z-index: 50;
  inset: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  border-bottom: 2px solid var(--color-main-100);
}

.group__name-edit__input {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  font-family: Montserrat-Medium;
  margin-left: 60px;
  width: 600px;
}

.group__name-edit__button {
  margin-right: 20px;
}

.group:hover .row__actions {
  display: block;
}

.diets-wrapper {
  max-height: 374px;
  margin-bottom: 6px;
  position: relative;
}

.diets-wrapper--scroll {
  overflow: hidden scroll;
}

.diets--copy-active {
  /* overflow-y: visible;
    overflow-x: visible; */
}

.diets-empty__info {
  font-family: Montserrat-Medium;
  border: 2px dashed var(--color-main-100);
  margin: 10px 0;
  padding: 19px 0;
  text-align: center;
  line-height: 1.4;
}

.diets-empty__info-name {
  font-family: Montserrat-Bold;
}

.diets-empty__info-button {
  font-family: Montserrat-Bold;
  color: var(--color-main-100);
}

.diets {
  position: relative;
}

.diets-loader {
  position: absolute;
  inset: 0;
  z-index: 10000;
  background: rgb(255 255 255 / 60%);
  cursor: wait;
}

.diet {
  height: 32px;
  margin-bottom: 6px;
  position: relative;
}

.diet:hover,
.diet--selected {
  background: var(--color-main-10);
}

.diet__name {
  justify-content: left;
  position: relative;
}

.diet__name-link {
  display: block;
  width: 100%;
  height: 32px;
  line-height: 32px;
  color: var(--color-primary-text);
  text-decoration: none;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.diet__name-edit {
  position: absolute;
  z-index: 50;
  inset: 1px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: transparent;
  border-bottom: 2px solid var(--color-main-100);
}

.diet__name-edit__input {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  margin-left: 8px;
  width: calc(100% - 30px);
}

.diet__name-edit__button {
  margin-right: 8px;
}

.diet__description {
  justify-content: flex-start;
}

.diets-wrapper--scroll .diet__description {
  margin-left: 10px;
}

.diet__description span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: flex-start;
  max-width: 100%;
  line-height: 16px;
}

.diet__created {
  justify-content: flex-start;
  margin-left: 45px;
}

.diet__calories {
  justify-content: flex-end;
  margin-right: 75px;
}

.diet__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 0 50px;
  background: linear-gradient(to right, transparent, var(--color-main-10) 50px);
  height: 32px;
}

.diet:hover .row__actions {
  display: block;
}

.move-diet-wrapper {
  position: relative;
}

.move-diet-menu {
  top: 12px;
  right: 12px;
}

.copy-diet-wrapper {
  position: relative;
}

.copy-diet-menu {
  top: 6px;
  right: 22px;
}

.modal-bold {
  font-family: Montserrat-SemiBold;
}

.modal-label {
  font-size: 10px;
  color: var(--color-tertiary-text);
  margin-left: 8px;
}

.add-group-modal__input {
  width: 100%;
}

.add-diet-modal__input {
  width: 100%;
  margin-bottom: 20px;
}

.diet-delete-modal-info {
  word-break: break-word;
}

.group-delete-modal-info {
  word-break: break-word;
}

.ghost-image {
  position: absolute;
  background-color: #5dbab3;
  border-radius: 5px;
  color: #fff;
  font-family: Montserrat-SemiBold;
  font-size: 12px;
  padding: 6px;
  display: flex;
  align-items: center;
  top: -500px;
}

.ghost-image__icon {
  padding-right: 6px;
  color: #fff;
}

.tooltip-bold {
  font-weight: bold;
}
</style>

<style>
.add-diet-modal {
  overflow: visible !important;
}
</style>
