import type { User } from './types'

import { defineStore } from 'pinia'

import { authService } from '@/services/authService'

import { useGlobalStore } from './globalStore'

export interface State {
  user?: User
}

export const useAuthStore = defineStore('authStore', {
  state: (): State => ({
    user: undefined // TODO: Move user data here
  }),
  actions: {
    async verifyAuth() {
      try {
        await authService.verifyAccessToken()
      } catch {
        await authService.refreshAccessToken()
      }
      const globalStore = useGlobalStore()
      const userIdFromState = globalStore.user?.id
      const userIdFormAccessToken = authService.userIdFormAccessToken()

      if (userIdFromState !== userIdFormAccessToken) {
        const response = await authService.fetchUser(userIdFormAccessToken)
        this.user = response.data
        globalStore.setUser({ user: response.data })
      }
    }
  }
})
