import type { Nutrients } from '@/utils/nutrients'
import type { Ref } from 'vue'

import { useQuery } from '@tanstack/vue-query'
import { storeToRefs } from 'pinia'

import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'

export const useSearchDishesAndIngredients = (nutrients: Ref<Nutrients[]>) => {
  const { hasDetails } = storeToRefs(useDietsStore())

  return useQuery({
    queryKey: ['searchDishesAndIngredients', nutrients],
    queryFn: () => dietsService.fetchSearchDishesAndIngredients(nutrients.value),
    staleTime: 36000,
    enabled: hasDetails,
    retry: 3
  })
}
