<template>
  <BaseModal
    type="form"
    @close="modalClose"
  >
    <template #title>
      Dodawanie pacjenta
    </template>
    <Form
      v-slot="{ meta }"
      :validation-schema="validationSchema"
      :initial-values="initialValues"
      :on-submit="submit"
    >
      <Space :space="8">
        <Space
          direction="columns"
          :space="16"
        >
          <FormInput
            type="text"
            name="patientName"
            label="Nazwa pacjenta"
            placeholder="Wprowadź nazwę pacjenta"
          />
          <FormInput
            type="text"
            name="patientEmail"
            label="Adres email"
            placeholder="Wprowadź email"
          />
        </Space>
        <Space
          direction="columns"
          :space="16"
        >
          <FormSelect
            label="Płeć:"
            :options="genderfilterOptions"
            name="gender"
            placeholder="Wybierz płeć"
          />
          <FormInputDate
            name="patientBirthday"
            label="Data urodzenia:"
            :max-date="getBirthdayMaxDate()"
            :min-date="getBirthdayMinDate()"
          />
        </Space>
        <FormSelect
          :disabled="!hasAccess"
          :options="filterPatternOptions"
          :on-disabled-press="showAppUpgradePlanModal"
          name="pattern"
          label="Szablon ankiety:"
          placeholder="Wybierz szablon ankiety"
        />
        <Space
          align-x="end"
          align-y="center"
          direction="columns"
        >
          <StyledButton
            variant="outline"
            @click="modalClose"
          >
            Anuluj
          </StyledButton>
          <StyledButton :disabled="!meta.valid || !submitBttActive">
            Dodaj pacjenta
          </StyledButton>
        </Space>
      </Space>
    </Form>
  </BaseModal>
</template>

<script lang="ts" setup>
import type { PartialDeep } from 'type-fest'
import type { SubmissionHandler } from 'vee-validate'

import { F } from '@mobily/ts-belt'
import { toTypedSchema } from '@vee-validate/zod'
import { format, startOfYear, subYears } from 'date-fns'
import { Form } from 'vee-validate'
import { computed, ref } from 'vue'
import * as zod from 'zod'
import { isAxiosError } from 'axios'
import BaseModal from '@/components/BaseModal.vue'
import FormInput from '@/components/FormInput.vue'
import FormInputDate from '@/components/FormInputDate.vue'
import FormSelect from '@/components/FormSelect.vue'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import { dashboardService, type AddPatient } from '@/services/dashboardService'
import { useDashboardStore } from '@/store/dashboardStore'
import { useGlobalStore } from '@/store/globalStore'

const emit = defineEmits(['close'])
const { hasPerm, showAppUpgradePlanModal, createMessage } = useGlobalStore()
const dashboardState = useDashboardStore()
const submitBttActive = ref(true)
const formSubmited = ref(false)
const genderfilterOptions = computed(() =>
  dashboardState.patientsOptions.gender.map((item) => ({
    label: item.display_name,
    value: item.value
  }))
)

const schema = zod.object({
  patientName: zod
    .string()
    .nonempty('To pole nie może być puste.')
    .max(30, 'To pole może mieć maksymalnie 30 znaków.'),
  patientEmail: zod
    .string()
    .email({ message: 'Nieprawidłowy format adresu email.' })
    .optional()
    .or(zod.literal('')),
  patientBirthday: zod.date(),
  gender: zod.number({
    required_error: 'Wybierz opcję',
    invalid_type_error: 'Wybierz opcję'
  }),
  pattern: zod.string({
    required_error: 'Wybierz opcję',
    invalid_type_error: 'Wybierz opcję'
  })
})
const validationSchema = toTypedSchema(schema)

const initialValues = {
  gender: 2,
  pattern: 'Podstawowy',
  patientBirthday: startOfYear(subYears(new Date(), 30))
}

const modalClose = () => {
  emit('close')
}

type Schema = zod.input<typeof schema>

const submit: SubmissionHandler<PartialDeep<Schema>> = async (values, { setFieldError }) => {
  submitBttActive.value = false
  const data = F.coerce<Schema>(values)
  const patient: AddPatient = {
    birth_date: format(new Date(data.patientBirthday), 'dd.MM.yyyy'),
    gender: data.gender,
    name: data.patientName,
    pattern_name: data.pattern,
    email: data.patientEmail
  }

  await dashboardService
    .addPatient(patient, { handledError: true })
    .then((response) => {
      if (response.data.patient_tab_available) {
        window.location.href = response.data.url
      }
      formSubmited.value = false
      createMessage({
        title: 'Pacjent musi zaakceptować zaproszenie, żebyś mógł wejść w jego kartotekę.'
      })
      close()
    })
    .catch((error: unknown) => {
      if (isAxiosError(error) && error.response) {
        const keys = Object.keys(error.response.data) as (keyof Schema)[]
        if (keys.length && keys[0]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const err = error.response.data[keys[0]]
          if (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            setFieldError('patientEmail', error.response.data[keys[0]][0])
          }

          formSubmited.value = false
          submitBttActive.value = true
        }
      }
    })
}

const hasAccess = computed(() => hasPerm('accounts.survey_template'))

const filterPatternOptions = computed(() =>
  dashboardState.patientsOptions.pollPattern.map((item) => ({
    label: item.display_name,
    value: item.display_name
  }))
)

const getBirthdayMaxDate = () => {
  const today = new Date()
  today.setDate(today.getDate() - 1)
  return today
}
const getBirthdayMinDate = () => {
  const today = new Date()
  today.setMonth(0)
  today.setDate(1)
  today.setFullYear(today.getFullYear() - 115) // minDate is -115 years from now (starts 1st jan)
  return today
}
</script>
