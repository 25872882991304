<template>
  <div class="settings">
    <div class="settings-box name-description-filters">
      <div class="title">
        Nazwa i opis jadłospisu
      </div>
      <BaseTooltip class="base-tooltip--top base-tooltip--left">
        <div class="label">
          Nazwa jadłospisu
        </div>
        <template
          v-if="dietType === 'patient'"
          #tooltip
        >
          Widoczna w panelu pacjenta
        </template>
        <template
          v-else
          #tooltip
        >
          Widoczna na wydruku diety
        </template>
      </BaseTooltip>
      <BaseInput
        v-model="dietName"
        class="name"
        :maxlength="85"
        :placeholder="'Kliknij, aby wprowadzić nazwę jadłospisu'"
      />
      <BaseTooltip class="base-tooltip--top base-tooltip--left">
        <div class="label">
          Opis jadłospisu
        </div>
        <template #tooltip>
          Tutaj wpisz wszystkie ważne informacje dotyczące diety.<br>
          Opis jadłospisu jest widoczny tylko dla Ciebie.
        </template>
      </BaseTooltip>
      <BaseTextarea
        v-model="dietDescription"
        class="description"
        :maxlength="5000"
        :autosize="true"
        :placeholder="'Kliknij, aby wprowadzić opis jadłospisu'"
      />
      <div class="label">
        Wyszukaj tylko w potrawach i produktach
      </div>
      <div class="filters">
        <BaseCheckbox
          v-if="hasPerm('accounts.diet_settings_search_in')"
          v-model="filterVegan"
          class="filter"
          :label="'Wegańskie'"
        />
        <BaseCheckbox
          v-else
          class="filter"
          :label="'Wegańskie'"
          :disabled="true"
          :model-value="false"
          @update:model-value="showAppUpgradePlanModal"
        />
        <BaseCheckbox
          v-if="hasPerm('accounts.diet_settings_search_in')"
          v-model="filterVegetarian"
          class="filter"
          :label="'Wegetariańskie'"
        />
        <BaseCheckbox
          v-else
          class="filter"
          :label="'Wegetariańskie'"
          :disabled="true"
          :model-value="false"
          @update:model-value="showAppUpgradePlanModal"
        />
        <BaseCheckbox
          v-if="hasPerm('accounts.diet_settings_search_in')"
          v-model="filterGlutenFree"
          class="filter"
          :label="'Bezglutenowe'"
        />
        <BaseCheckbox
          v-else
          class="filter"
          :label="'Bezglutenowe'"
          :model-value="false"
          :disabled="true"
          @update:model-value="showAppUpgradePlanModal"
        />
        <BaseCheckbox
          v-if="hasPerm('accounts.diet_settings_search_in')"
          v-model="filterDiaryFree"
          class="filter"
          :label="'Bezmleczne'"
        />
        <BaseCheckbox
          v-else
          class="filter"
          :label="'Bezmleczne'"
          :model-value="false"
          :disabled="true"
          @update:model-value="showAppUpgradePlanModal"
        />
      </div>
      <div class="info filters-info">
        <BaseIcon
          name="info"
          type="info"
        /> Zaznaczenie tej opcji spowoduje zmianę wyników
        wyszukiwania potraw oraz produktów dla tego jadłospisu.
      </div>
    </div>
    <div class="settings-box meals">
      <div class="title">
        Kolejność, czas i założona kaloryczność posiłków
      </div>
      <div
        class="energy-distribution-switch"
        @click="handleUpdateEnergyDistribution"
      >
        <label class="energy-distribution-switch__label">Rozkład energii</label>
        <BaseSwitch :value="energyDistribution" />
      </div>
      <div
        class="meal-header"
        :class="{ 'energy-distribution': energyDistribution }"
      >
        <div class="meal-header__hour">
          Godzina
        </div>
        <div>Nazwa</div>
        <div>Typ posiłku</div>
        <div v-if="energyDistribution">
          Rozkład energii
        </div>
      </div>
      <div
        v-for="(meal, mealIndex) in meals"
        :key="mealIndex"
        class="meal"
        :class="{ 'energy-distribution': energyDistribution }"
      >
        <div class="meal-hour">
          <BaseSelect
            class="meal-hour__select"
            :options="hourOptions"
            :model-value="meal.hour"
            @update:model-value="dietsStore.updateMealHour(mealIndex, $event)"
          />
        </div>
        <div class="meal-name">
          <BaseInput
            class="meal-name__input"
            :model-value="meal.name"
            maxlength="50"
            @update:model-value="updateMealName(mealIndex, $event)"
          />
        </div>
        <div class="meal-type">
          <BaseSelect
            class="meal-type__select"
            :options="mealTypes"
            :model-value="meal.type"
            @update:model-value="dietsStore.updateMealType(mealIndex, $event)"
          />
        </div>
        <div
          v-if="energyDistribution"
          class="meal-energy-distrubution"
        >
          <BaseInputNumber
            class="meal-energy-distrubution__input"
            :min="0"
            :max="100"
            :model-value="meal.energy_distribution"
            @update:model-value="dietsStore.updateMealEnergyDistribution(mealIndex, $event)"
          />%
          <span class="meal-energy-distrubution__calories">{{ energyDistributionCalories(meal.energy_distribution) }} kcal</span>
        </div>
        <div class="meal-action">
          <IconButton
            v-if="meals.length > 1"
            name="delete"
            type="destructive"
            @click="showDeleteMealModal(mealIndex, meal.name)"
          />
        </div>
      </div>
      <div
        v-if="meals.length < 12"
        class="create-meal"
      >
        <StyledButton
          v-if="handleCreateMeal"
          left-icon="add"
          class="base-button--secondary base-button--outline"
          @click="handleCreateMeal"
        >
          Dodaj pozycję
        </StyledButton>
      </div>
    </div>
    <div class="settings-box choosen-nutrients">
      <div class="title">
        Najważniejsze parametry posiłku
      </div>
      <div class="info">
        <BaseIcon
          type="info"
          name="info"
        /> Kliknij lub przeciągnij z listy po prawej 5 elementów,
        które mają się wyświetlać przy posiłkach oraz w podsumowaniu tygodniowym.
      </div>
      <div class="choosen-nutrients__info">
        Wartości wybranych składników odżywczych w widoku dziennym i tygodniowym
      </div>
      <div class="choosen-nutrients__info">
        <strong>{{ chosenNutrients.length }}/5</strong> wybranych składników
      </div>
      <div
        class="choosen-nutrients__dropzone"
        @drop.prevent="dropNutrient"
        @dragover.prevent
      >
        <div
          v-for="nutrient in chosenNutrients"
          :key="`choosen-nutrient-${nutrient}`"
          class="choosen-nutrient"
        >
          {{ nutrientsMap[nutrient].name }}
          <BaseIcon
            class="choosen-nutrient__icon-check"
            size="16"
            name="check_circle"
          />
          <BaseIcon
            class="choosen-nutrient__icon-cancel"
            type="warning"
            size="16"
            name="cancel"
            @click="deleteNutrient(nutrient)"
          />
        </div>
      </div>
    </div>
    <div class="settings-box nutrients">
      <BaseSelect
        class="nutrients__category"
        :options="nutrientsOptions"
        :model-value="nutrientCategory"
        @update:model-value="updateNutrientCategory"
      />
      <div class="nutrients__list">
        <template v-for="nutrient in nutrientsInCategory">
          <div
            v-if="nutrient"
            :key="nutrient.key"
            class="nutrient"
            :class="nutrientClass(nutrient.key)"
            :draggable="canDragNutrient(nutrient.key)"
            @dragstart="dragStartNutrient($event, nutrient.key)"
            @click="updateNutrient(nutrient.key)"
          >
            {{ nutrient.name }}
            <BaseIcon
              v-if="canDeleteNutrient(nutrient.key)"
              class="nutrient__icon-check"
              size="16"
              name="check_circle"
            />
            <BaseIcon
              v-if="canDeleteNutrient(nutrient.key)"
              class="nutrient__icon-cancel"
              size="16"
              type="warning"
              name="cancel"
              @click.stop="deleteNutrient(nutrient.key)"
            />
          </div>
        </template>
      </div>
      <BaseModal
        v-if="deleteMealModal"
        @close="hideDeleteMealModal"
      >
        <template #title>
          Usuwanie posiłku
        </template>
        <div
          v-if="choosenMealName"
          class="delete-confirmation-text"
        >
          Czy chcesz usunąć posiłek o nazwie <span>{{ choosenMealName }}</span>?
        </div>
        <div v-else>
          Czy chcesz usunąć wybrany posiłek?
        </div>
        <template #actions>
          <StyledButton
            variant="outline"
            @click.once="hideDeleteMealModal"
          >
            Anuluj
          </StyledButton>
          <StyledButton @click.once="handleDeleteMeal">
            Usuń
          </StyledButton>
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Nutrients } from '@/utils/nutrients'

import { D, G, pipe, S } from '@mobily/ts-belt'
import { klona } from 'klona'
import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseInput from '@/components/BaseInput.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseModal from '@/components/BaseModal.vue'
import BaseSelect from '@/components/BaseSelect.vue'
import BaseSwitch from '@/components/BaseSwitch.vue'
import BaseTextarea from '@/components/BaseTextarea.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import { IconButton } from '@/components/IconButton'
import { StyledButton } from '@/components/StyledButton'
import { hours, mealTypes } from '@/const'
import { useDietsStore } from '@/store/dietsStore'
import { useGlobalStore } from '@/store/globalStore'
import { nutrientsCategories, nutrientsMap } from '@/utils/nutrients'

const route = useRoute()
const globalStore = useGlobalStore()
const dietsStore = useDietsStore()
const { hasPerm, showAppUpgradePlanModal } = globalStore
const {
  updateDietName,
  updateDietDescription,
  updateDishSearchFilters,
  updatechosenNutrients,
  updateEnergyDistribution,
  createMeal,
  updateMealName,
  deleteMeal
} = dietsStore

const { diet } = storeToRefs(dietsStore)

const nutrientCategory = ref('Składniki odżywcze')
const deleteMealModal = ref(false)
const choosenMealIndex = ref<number>()
const choosenMealName = ref('')

const dietName = computed({
  get() {
    return diet.value?.data.name ?? ''
  },
  set(name: string) {
    if (name.length > 0) {
      updateDietName(S.trim(name))
    } else {
      updateDietName(dietName.value[0] ?? '')
    }
  }
})

const dietDescription = computed({
  get() {
    return diet.value?.data.description ?? ''
  },
  set(description) {
    updateDietDescription(description)
  }
})
const filterDiaryFree = computed({
  get() {
    return diet.value?.data.dish_search_filters.diary_free ?? false
  },
  set(value) {
    updateDishSearchFilters({
      key: 'diary_free',
      value
    })
  }
})
const filterGlutenFree = computed({
  get() {
    return diet.value?.data.dish_search_filters.gluten_free ?? false
  },
  set(value) {
    updateDishSearchFilters({
      key: 'gluten_free',
      value
    })
  }
})
const filterVegan = computed({
  get() {
    return diet.value?.data.dish_search_filters.vegan ?? false
  },
  set(value) {
    updateDishSearchFilters({
      key: 'vegan',
      value
    })
  }
})
const filterVegetarian = computed({
  get() {
    return diet.value?.data.dish_search_filters.vegetarian ?? false
  },
  set(value) {
    updateDishSearchFilters({
      key: 'vegetarian',
      value
    })
  }
})
const meals = computed(() => {
  return diet.value?.data.meals ?? []
})
const energyDistribution = computed(() => {
  return diet.value?.data.energy_distribution
})
const chosenNutrients = computed<Nutrients[]>(() => {
  return (
    diet.value?.data.choosen_nutrients ?? [
      'protein',
      'fat',
      'carbohydrates',
      'fiber',
      'carbohydrate_exchanger'
    ]
  )
})
const hourOptions = computed(() => {
  return Array.from(hours, (hour) => {
    return { value: hour, text: hour }
  })
})
const nutrientsOptions = computed(() => {
  return Array.from(nutrientsCategories, (category) => {
    return { value: category, text: category }
  })
})
const nutrientsInCategory = computed(() => {
  return pipe(
    D.mapWithKey(nutrientsMap, (key, obj) => {
      return {
        key,
        name: obj.name,
        category: obj.category,
        disabled: chosenNutrients.value.includes(key)
      }
    }),
    D.filter((nutrient) => {
      return nutrient.category === nutrientCategory.value
    })
  )
})
const dietType = computed(() => {
  return route.params.dietType
})

const showDeleteMealModal = (mealIndex: number, mealName: string) => {
  deleteMealModal.value = true
  choosenMealIndex.value = mealIndex
  choosenMealName.value = mealName
}
const hideDeleteMealModal = () => {
  deleteMealModal.value = false
  choosenMealIndex.value = undefined
  choosenMealName.value = ''
}
const updateNutrientCategory = (category: string) => {
  nutrientCategory.value = category
}
const dragStartNutrient = (event: DragEvent, nutrient: Nutrients) => {
  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'copy'
    event.dataTransfer.setData('text/plain', nutrient)
  }
}
const dropNutrient = (event: DragEvent) => {
  if (event.dataTransfer) {
    const nutrient = event.dataTransfer.getData('text/plain') as Nutrients
    if (nutrient in nutrientsMap) {
      updateNutrient(nutrient)
    }
  }
}
const updateNutrient = (nutrient: Nutrients) => {
  if (hasPerm('accounts.diet_microelements')) {
    const clonedNutrients = klona(chosenNutrients.value)
    if (!clonedNutrients.includes(nutrient)) {
      clonedNutrients.push(nutrient)
    }
    updatechosenNutrients(clonedNutrients)
  } else {
    showAppUpgradePlanModal()
  }
}
const deleteNutrient = (nutrient: Nutrients) => {
  if (hasPerm('accounts.diet_microelements')) {
    updatechosenNutrients(chosenNutrients.value.filter((elem) => nutrient !== elem))
  } else {
    showAppUpgradePlanModal()
  }
}
const canDragNutrient = (nutrient: Nutrients) => {
  return chosenNutrients.value.length <= 5 && !chosenNutrients.value.includes(nutrient)
}
const nutrientClass = (nutrient: Nutrients) => {
  if (chosenNutrients.value.includes(nutrient)) {
    return 'selected'
  } else if (chosenNutrients.value.length >= 5) {
    return 'disabled'
  }
  return ''
}
const canDeleteNutrient = (nutrient: Nutrients) => {
  return chosenNutrients.value.includes(nutrient)
}
const energyDistributionCalories = (value: number) => {
  const calories = diet.value?.data.norms.calories ?? 0
  return round((value * calories) / 100)
}
const handleUpdateEnergyDistribution = () => {
  updateEnergyDistribution(!energyDistribution.value)
}
const handleCreateMeal = () => {
  createMeal()
}

const handleDeleteMeal = () => {
  const mealIndex = choosenMealIndex.value
  if (G.isNumber(mealIndex)) {
    deleteMeal(mealIndex)
    hideDeleteMealModal()
  }
}
</script>

<style scoped>
.settings {
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 12px;
  grid-template-areas:
    'name-description-filters name-description-filters'
    'meals meals'
    'choosen-nutrients nutrients';
  margin-bottom: 12px;
}

.name-description-filters {
  grid-area: name-description-filters;
}

.meals {
  grid-area: meals;
  position: relative;
}

.choosen-nutrients {
  grid-area: choosen-nutrients;
}

.nutrients {
  grid-area: nutrients;
}

.settings-box {
  background: #fff;
  padding: 16px 18px;
}

.title {
  font-family: Montserrat-Medium;
  font-size: 24px;
}

.label {
  font-family: Montserrat-Medium;
  font-size: 12px;
  margin-top: 22px;
  margin-bottom: 8px;
}

.info {
  font-size: 12px;
  line-height: 20px;
}

.info :deep(.base-icon) {
  margin-right: 6px;
}

.name {
  font-size: 16px;
  line-height: 19px;
  width: 100%;
  max-width: 800px;
}

.delete-confirmation-text span {
  font-family: Montserrat-SemiBold;
}

.description {
  max-height: 200px;
  max-width: 800px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.description::-webkit-scrollbar {
  width: 0;
}

.filters {
  margin: 8px;
}

.filter {
  margin-right: 40px;
}

.filter.disabled {
  pointer-events: auto;
}

.filters-info {
  margin-top: 16px;
  margin-bottom: 4px;
}

.energy-distribution-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-family: Montserrat-Medium;
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;
}

.energy-distribution-switch__label {
  margin-right: 12px;
  cursor: pointer;
}

.meal-header {
  display: grid;
  grid-template-columns: 80px auto 140px 30px;
  column-gap: 30px;
  font-size: 12px;
  font-family: Montserrat-Medium;
  border-bottom: 1px solid #eaeaea;
  margin-top: 4px;
  padding: 12px 0;
}

.meal-header.energy-distribution {
  grid-template-columns: 80px auto 140px 120px 30px;
}

.meal-header div {
  padding-left: 6px;
}

div.meal-header__hour {
  padding-left: 12px;
}

.meal {
  display: grid;
  grid-template-columns: 80px auto 140px 30px;
  column-gap: 30px;
  height: 40px;
  align-items: center;
}

.meal.energy-distribution {
  grid-template-columns: 80px auto 140px 120px 30px;
}

.meal-hour {
  width: 80px;
}

.meal-hour__select {
  width: 68px;
  margin: auto;
}

.meal-hour__select :deep(.base-select__selected) {
  padding: 5px 21px 5px 8px;
  text-align: center;
}

.meal-name__input {
  width: 100%;
}

.meal-type {
  width: 140px;
}

.meal-type__select :deep(.base-select__selected) {
  padding: 5px 21px 5px 8px;
}

.meal-energy-distrubution {
  width: 120px;
  font-size: 12px;
}

.meal-energy-distrubution__input {
  width: 36px;
  text-align: center;
}

.meal-energy-distrubution__calories {
  margin-left: 6px;
}

.meal-action {
  width: 30px;
}

.meal-action__delete {
  margin: 0 auto;
}

.meal-action__delete :deep(.base-icon) {
  color: var(--color-tertiary-text) !important;
}

.meal-action__delete:hover :deep(.base-icon) {
  color: #f55f6d !important;
}

.create-meal {
  margin-top: 12px;
}

.choosen-nutrients__dropzone {
  border-radius: 4px;
  border: 1px solid #eaeaea;
  min-height: 64px;
  padding: 8px;
  display: flex;
  flex-flow: row wrap;
  margin-top: 16px;
}

.choosen-nutrient {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #eef8f7;
  margin: 6px;
  height: 26px;
  padding: 0 8px;
  line-height: 26px;
  border-radius: 13px;
  font-size: 10px;
  cursor: default;
  white-space: nowrap;
}

.choosen-nutrient :deep(.base-icon) {
  margin-left: 8px;
}

.choosen-nutrient:hover {
  background: var(--color-red-10);
}

.choosen-nutrient:hover .choosen-nutrient__icon-check {
  display: none;
}

.choosen-nutrient__icon-cancel {
  display: none;
  cursor: pointer;
}

.choosen-nutrient:hover .choosen-nutrient__icon-cancel {
  display: block;
}

.choosen-nutrients .info {
  margin: 16px 0;
}

.choosen-nutrients__info {
  font-size: 12px;
  line-height: 20px;
}

.choosen-nutrient__info strong {
  font-family: Montserrat-Medium;
}

.nutrients__category {
  margin-bottom: 16px;
}

.nutrients__list {
  height: 176px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  align-items: flex-start;
}

.nutrients__list::-webkit-scrollbar {
  width: 6px;
}

.nutrient {
  background: #f2f2f2;
  font-size: 10px;
  border-radius: 13px;
  line-height: 26px;
  padding: 0 8px;
  height: 26px;
  margin: 3px 0;
  display: flex;
  align-items: center;
}

.nutrient:hover {
  background: var(--color-main-10);
  cursor: pointer;
}

.nutrient.disabled {
  pointer-events: none;
  cursor: default;
  background: #f2f2f2;
  color: var(--color-tertiary-text);
}

.nutrient.selected :deep(.base-icon) {
  margin-left: 8px;
}

.nutrient.selected {
  background: var(--color-main-10);
  cursor: default;
}

.nutrient.selected:hover {
  background: var(--color-red-10);
}

.nutrient:hover .nutrient__icon-check {
  display: none;
}

.nutrient.selected .nutrient__icon-cancel {
  display: none;
  cursor: pointer;
}

.nutrient:hover .nutrient__icon-cancel {
  display: block;
}
</style>
