<template>
  <div class="root">
    <Listbox
      :model-value="props.modelValue"
      @update:model-value="selectOption"
    >
      <ListboxButton
        v-slot="{ open, disabled }"
        as="template"
      >
        <KcalmarSelectButton
          :disabled="disabled"
          :expanded="open"
        >
          {{ label ?? props.placeholder }}
        </KcalmarSelectButton>
      </ListboxButton>
      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="list">
          <ListboxOption
            v-for="option in options"
            :key="option.text"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
            :disabled="option.disabled"
          >
            <KcalmarSelectItem
              :enabled="!option.disabled"
              :active="active"
              :selected="selected"
            >
              {{ option.text }}
            </KcalmarSelectItem>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </Listbox>
  </div>
</template>

<script lang="ts" setup generic="T">
import type { PropType } from 'vue'

import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { computed } from 'vue'

import { KcalmarSelectButton } from '@ui/KcalmarSelectButton'
import { KcalmarSelectItem } from '@ui/KcalmarSelectItem'

interface Option<T> {
  disabled?: boolean
  text: string
  value: T
}

const props = defineProps({
  modelValue: {
    required: true,
    type: Object as PropType<Option<T> | undefined>
  },
  options: {
    type: Array as PropType<Option<T>[] | readonly Option<T>[]>,
    required: true
  },
  placeholder: {
    type: String,
    required: false
  }
})
const emit = defineEmits(['update:modelValue'])

const label = computed(() => {
  return props.options.find((option) => option.value === props.modelValue?.value)?.text
})

const selectOption = (option: Option<T>) => {
  emit('update:modelValue', option)
}
</script>

<style lang="scss" scoped>
.root {
  position: relative;
  display: inline-flex;
}

.list {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  gap: $space-2;
  padding: $space-4;
  max-height: 240px;
  overflow-y: auto;
  top: calc(100% + 8px);
  margin: 0;
  border-radius: $radius-radi-6;
  background: $fill-50;
  z-index: 9999;
  list-style-type: none;
  box-shadow:
    0 2px 4px -1px rgb(26 26 26 / 8%),
    0 1px 0 0 $colors-alpha-50;
}
</style>
