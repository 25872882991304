<template>
  <div class="root">
    <Combobox
      :model-value="props.modelValue"
      @update:model-value="selectOption"
    >
      <ComboboxInput
        as="template"
        :display-value="(option) => (option as Option<T>).text"
        right-icon="expand_more"
        @change="query = $event.target.value"
      >
        <KcalmarInput>
          <template #rightContent>
            <ComboboxButton
              v-slot="{ open }"
              as="template"
            >
              <KcalmarIconButton
                variant="transparent"
                :name="!open ? 'arrow-down-s-line' : 'arrow-up-s-line'"
              />
            </ComboboxButton>
          </template>
        </KcalmarInput>
      </ComboboxInput>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions class="list">
          <li v-if="filteredOptions.length === 0 && query !== ''">
            Brak wyników
          </li>
          <ComboboxOption
            v-for="option in filteredOptions"
            :key="option.value"
            v-slot="{ active, selected }"
            as="template"
            :value="option"
            :disabled="option.disabled"
          >
            <KcalmarSelectItem
              :enabled="!option.disabled"
              :active="active"
              :selected="selected"
            >
              {{ option.text }}
            </KcalmarSelectItem>
          </ComboboxOption>
        </ComboboxOptions>
      </transition>
    </Combobox>
  </div>
</template>

<script lang="ts" setup generic="T extends string | number">
import type { PropType } from 'vue'

import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions
} from '@headlessui/vue'
import { computed, ref } from 'vue'

import { KcalmarIconButton } from '@ui/KcalmarIconButton'
import { KcalmarInput } from '@ui/KcalmarInput'
import { KcalmarSelectItem } from '@ui/KcalmarSelectItem'

interface Option<T extends string | number> {
  disabled?: boolean
  text: string
  value: T
}

const props = defineProps({
  modelValue: {
    required: true,
    type: Object as PropType<Option<T> | undefined>
  },
  options: {
    type: Array as PropType<Option<T>[] | readonly Option<T>[]>,
    required: true
  },
  placeholder: {
    type: String,
    required: false
  }
})
const emit = defineEmits(['update:modelValue'])

const query = ref('')
const filteredOptions = computed(() =>
  query.value === ''
    ? props.options
    : props.options.filter((v) =>
        v.text
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)
const selectOption = (option: Option<T>) => {
  emit('update:modelValue', option)
}
</script>

<style lang="scss" scoped>
.root {
  position: relative;
  display: inline-flex;
}

.list {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  max-height: 240px;
  overflow: hidden;
  width: 100%;
  gap: $space-2;
  padding: $space-4;
  top: calc(100% + 8px);
  margin: 0;
  border-radius: $radius-radi-6;
  background: $fill-50;
  z-index: 9999;
  list-style-type: none;
  box-shadow:
    0 2px 4px -1px rgb(26 26 26 / 8%),
    0 1px 0 0 $colors-alpha-50;
}
</style>
