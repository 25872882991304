import type { RouteRecordSingleViewWithChildren } from 'vue-router'

import { G } from '@mobily/ts-belt'

import { Empty } from '@/pages/Empty'
import { authService } from '@/services/authService'

export const authRouter: RouteRecordSingleViewWithChildren = {
  path: '/session',
  redirect: { name: 'auth' },
  children: [
    {
      path: 'logout', // This path is used to logout frontend from backend
      name: 'LogoutBase',
      component: Empty,
      beforeEnter: () => {
        authService.deleteTokens()
        authService.redirectToLogoutPage()
      }
    },
    {
      path: 'hijack/login', // This path is used to hijack functionality
      name: 'HijackLogin',
      redirect: () => {
        authService.deleteTokens()
        return { path: '/dashboard' }
      }
    },
    {
      path: 'hijack/logout', // This path is used to hijack functionality
      name: 'HijackLogout',
      component: Empty,
      beforeEnter: () => {
        authService.deleteTokens()
        window.location.replace(`${import.meta.env.VUE_APP_HOST}/admin`)
      }
    },
    {
      path: ':accessToken/:refreshToken',
      name: 'AuthBase',
      redirect: (to) => {
        if (G.isString(to.params.accessToken) && G.isString(to.params.refreshToken)) {
          authService.updateTokens(to.params.accessToken, to.params.refreshToken)
        } else {
          authService.deleteTokens()
        }
        const path = to.query.path ?? ''
        return { path: path.toString(), query: {} }
      }
    }
  ]
}
