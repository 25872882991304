<template>
  <div class="container">
    <div
      v-for="group in ingredientGroupsListProp"
      :key="group.id"
    >
      <div
        class="group-or-subgroup-row"
        :class="{ extended: extendedGroupId === group.id }"
      >
        <BaseIcon
          class="arrow"
          size="16"
          name="arrow_right"
          variant="outline"
          @click="toggleExtendedGroup(group.id)"
        />

        <div class="group-checkbox-column">
          <BaseCheckbox
            :model-value="isGroupActive(group.id)"
            :partial="isGroupPartialActive(group.id)"
            @update:model-value="selectedGroupHandler(group.id)"
          />
        </div>
        <div
          class="draggable-box"
          :class="{ dragging: draggedId === group.id }"
          :draggable="true"
          @dragstart="handleDragStart($event, group.id)"
          @dragend="handleDragEnd()"
        >
          <div class="group-dot" />
          <span class="name">{{ group.name }}</span>
        </div>
      </div>
      <div
        v-if="extendedGroupId === group.id"
        class="subgroups"
      >
        <div>
          <div class="border" />
          <div>
            <div
              v-for="subgroup in group.children"
              :key="subgroup.name"
            >
              <div class="group-or-subgroup-row">
                <div class="horizontal-border" />
                <BaseCheckbox
                  :model-value="isGroupActive(subgroup.id)"
                  @update:model-value="
                    selectedGroupHandler(subgroup.id, 'subgroup', subgroup.parent)
                  "
                />
                <div
                  class="draggable-box"
                  :class="{ subgroup: group.parent !== undefined }"
                  :draggable="true"
                  @dragstart="handleDragStart($event, subgroup.id, 'subgroup', subgroup.parent)"
                  @dragend="handleDragEnd()"
                >
                  <div class="subgroup-dot" />
                  <span class="name">{{ subgroup.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'

import { A } from '@mobily/ts-belt'
import { computed, onBeforeMount, onMounted, ref, watch } from 'vue'

import BaseCheckbox from '@/components/BaseCheckbox.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import eventBus from '@/eventBus'
import { type Group } from '@/services/ingredientsService'
import { stringify } from '@/utils/stringify'

const emit = defineEmits(['dragStart', 'dragEnd', 'selectGroup'])
const props = defineProps({
  ingredientGroupsListProp: {
    type: Array as PropType<Group[]>,
    required: true
  },
  ingredientActiveGroupsProp: {
    type: Array,
    required: true
  }
})
const extendedGroupId = ref<number>()
const ingredientActiveGroups = ref<number[]>([])
const draggedId = ref<number>()

const getIngredientGroupsForDataBase = computed(() => {
  const groupsForDataBase: number[] = []
  A.forEach(props.ingredientGroupsListProp, (group) => {
    if (ingredientActiveGroups.value.includes(group.id)) {
      groupsForDataBase.push(group.id)
    } else {
      A.forEach(group.children, (subGroup) => {
        if (ingredientActiveGroups.value.includes(subGroup.id)) {
          groupsForDataBase.push(subGroup.id)
        }
      })
    }
  })

  return groupsForDataBase
})
const initGroupsData = computed(() => {
  const groupsWithSubGroups: number[] = []
  A.forEach(props.ingredientGroupsListProp, (group) => {
    if (props.ingredientActiveGroupsProp.includes(group.id)) {
      groupsWithSubGroups.push(group.id)
      A.forEach(group.children, (subGroup) => {
        groupsWithSubGroups.push(subGroup.id)
      })
    } else {
      A.forEach(group.children, (subGroup) => {
        if (props.ingredientActiveGroupsProp.includes(subGroup.id)) {
          groupsWithSubGroups.push(subGroup.id)
        }
      })
    }
  })
  return groupsWithSubGroups
})

const isGroupActive = (groupId: number) => {
  return ingredientActiveGroups.value.includes(groupId)
}
const isGroupPartialActive = (groupId: number) => {
  let partialActive = false
  if (!ingredientActiveGroups.value.includes(groupId)) {
    const group = props.ingredientGroupsListProp.find((g) => g.id === groupId)
    if (group) {
      group.children.forEach((subGroup) => {
        if (ingredientActiveGroups.value.includes(subGroup.id)) {
          partialActive = true
        }
      })
    }
  }
  return partialActive
}
const handleDragStart = (event: DragEvent, groupId: number, type = 'group', parent?: number) => {
  emit('dragStart')
  draggedId.value = groupId
  const groupDict = {
    groupId,
    type,
    parent
  }
  if (event.dataTransfer) {
    event.dataTransfer.dropEffect = 'move'
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('groupDict', stringify(groupDict))
  }
}
const handleDragEnd = () => {
  draggedId.value = undefined
  emit('dragEnd')
}
const selectGroup = (groupId: number) => {
  const group = props.ingredientGroupsListProp.find((g) => g.id === groupId)
  if (ingredientActiveGroups.value.includes(groupId)) {
    const groupIndex = ingredientActiveGroups.value.indexOf(groupId)
    ingredientActiveGroups.value.splice(groupIndex, 1)
    if (group) {
      group.children.forEach((subGroup) => {
        const subGroupIndex = ingredientActiveGroups.value.indexOf(subGroup.id)
        if (subGroupIndex !== -1) {
          ingredientActiveGroups.value.splice(subGroupIndex, 1)
        }
      })
    }
  }
}
const unselectGroup = (groupId: number) => {
  const group = props.ingredientGroupsListProp.find((g) => g.id === groupId)
  if (group) {
    ingredientActiveGroups.value.push(group.id)
    group.children.forEach((subGroup) => {
      if (!ingredientActiveGroups.value.includes(subGroup.id)) {
        ingredientActiveGroups.value.push(subGroup.id)
      }
    })
  }
}
const selectSubGroup = (subGroupId: number, parent: number) => {
  const subGroupIndex = ingredientActiveGroups.value.indexOf(subGroupId)
  ingredientActiveGroups.value.splice(subGroupIndex, 1)
  if (ingredientActiveGroups.value.includes(parent)) {
    const groupIndex = ingredientActiveGroups.value.indexOf(parent)
    ingredientActiveGroups.value.splice(groupIndex, 1)
  }
}
const unselectSubGroup = (subGroupId: number, parent: number) => {
  const group = props.ingredientGroupsListProp.find((g) => g.id === parent)
  ingredientActiveGroups.value.push(subGroupId)
  let containsAllSubGroups = true
  if (group) {
    group.children.forEach((subGroup) => {
      if (!ingredientActiveGroups.value.includes(subGroup.id)) {
        containsAllSubGroups = false
      }
    })
  }
  if (containsAllSubGroups) {
    if (!ingredientActiveGroups.value.includes(parent)) {
      ingredientActiveGroups.value.push(parent)
    }
  }
}
const selectedGroupHandler = (
  groupOrSubGroupId: number,
  type: 'group' | 'subgroup' = 'group',
  parent?: number
) => {
  if (type === 'subgroup' && parent) {
    if (ingredientActiveGroups.value.includes(groupOrSubGroupId)) {
      selectSubGroup(groupOrSubGroupId, parent)
    } else {
      unselectSubGroup(groupOrSubGroupId, parent)
    }
  } else {
    if (ingredientActiveGroups.value.includes(groupOrSubGroupId)) {
      selectGroup(groupOrSubGroupId)
    } else {
      unselectGroup(groupOrSubGroupId)
    }
  }
  emit('selectGroup', getIngredientGroupsForDataBase.value)
}
const toggleExtendedGroup = (id: number) => {
  if (extendedGroupId.value === id) {
    extendedGroupId.value = undefined
  } else {
    extendedGroupId.value = id
  }
}

onMounted(() => {
  ingredientActiveGroups.value = initGroupsData.value
})

onBeforeMount(() => {
  eventBus.on('ingredientDrop', ({ groupId, type, parent }) => {
    if (
      !ingredientActiveGroups.value.includes(groupId) &&
      !ingredientActiveGroups.value.includes(parent)
    ) {
      selectedGroupHandler(groupId, type, parent)
    }
  })

  watch(initGroupsData, (newValue) => {
    ingredientActiveGroups.value = newValue
  })
})
</script>

<style scoped>
:deep(.base-icon) {
  color: var(--color-primary-text);
}

.container::-webkit-scrollbar {
  width: 6px;
}

.container {
  font-family: Montserrat-Regular;
  background: #fff;
  box-shadow: 2px 4px 4px 0 rgb(232 232 232 / 50%);
  overflow-y: auto;
  scrollbar-width: thin;
  width: 100%;
}

.subgroups {
  position: relative;
  margin-left: 20px;
}

.draggable-box {
  display: flex;
  align-items: center;
  padding: 0 12px 0 10px;
  border-radius: 13px;
  user-select: none;
  cursor: pointer;
}

.draggable-box:hover {
  background-color: var(--color-main-10);
}

.draggable-box.dragging {
  position: relative;
  transform: translate(0, 0);
  opacity: 0.999;
}

.draggable-box.subgroup:hover {
  background-color: var(--color-warning-10);
}

.group-or-subgroup-row {
  display: flex;
  height: 26px;
  line-height: 26px;
  align-items: center;
}

.group-or-subgroup-row .horizontal-border {
  width: 10px;
  height: 1px;
  border-top: 2px dotted var(--color-tertiary-text);
  margin-right: 2px;
  margin-left: 4px;
}

.group-or-subgroup-row .arrow {
  cursor: pointer;
}

.group-or-subgroup-row.extended .arrow {
  transform: rotate(90deg);
}

.group-or-subgroup-row .base-checkbox {
  margin-right: 7px;
}

.name {
  font-size: 10px;
  color: var(--color-primary-text);
  text-transform: lowercase;
  display: inline-block;
}

.name:hover {
  cursor: pointer;
}

.name::first-letter {
  text-transform: uppercase;
}

.subgroups .name::first-letter {
  text-transform: lowercase;
}

.group-dot {
  height: 8px;
  width: 8px;
  background-color: var(--color-main-100);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.subgroup-dot {
  height: 8px;
  width: 8px;
  background-color: var(--color-warning-100);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
}

.border {
  position: absolute;
  width: 1px;
  border-left: 2px dotted var(--color-tertiary-text);
  bottom: 13px;
  top: 0;
}
</style>
