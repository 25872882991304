<template>
  <Teleport
    v-if="isMounted"
    to="#app-content-header-portal"
  >
    <SavingStatus
      :status="dishSaveStatus"
      type="dish"
    />
  </Teleport>
  <template v-if="!isLoading && currentDish">
    <div class="dish-or-ingredient">
      <div class="menu">
        <BaseTooltip
          v-if="isExtended"
          class="menu-item base-tooltip--bottom base-tooltip--right"
        >
          <IconButton
            name="zoom_in_map"
            icon-size="22"
            @click="closeExtended"
          />
          <template #tooltip>
            Zamknij widok rozszerzony
          </template>
        </BaseTooltip>
        <BaseTooltip
          v-else-if="!isExtended"
          class="menu-item base-tooltip--bottom base-tooltip--right"
        >
          <IconButton
            name="zoom_out_map"
            @click="showExtened"
          />
          <template #tooltip>
            Edycja zaawansowana i<br>
            wartości odżywcze
          </template>
        </BaseTooltip>
        <BaseTooltip class="menu-item base-tooltip--bottom base-tooltip--right">
          <IconButton
            name="filter_none"
            icon-size="18"
            @click="copyDishHandler"
          />
          <template #tooltip>
            Skopiuj potrawę
          </template>
        </BaseTooltip>
        <BaseTooltip class="menu-item base-tooltip--bottom base-tooltip--right">
          <IconButton
            name="favorite"
            :icon-variant="!isFavorite ? 'outline' : 'fill'"
            @click="toggleFavorite(currentDish.id)"
          />
          <template #tooltip>
            {{ !isFavorite ? 'Dodaj do ulubionych' : 'Usuń z ulubionych' }}
          </template>
        </BaseTooltip>

        <BaseTooltip
          v-if="currentDish && !currentDish.is_public"
          class="menu-item base-tooltip--bottom base-tooltip--right"
        >
          <IconButton
            name="delete"
            icon-size="22"
            variant="outline"
            type="destructive"
            @click="deleteDishOrIngredientHandler"
          />
          <template #tooltip>
            Usuń potrawę
          </template>
        </BaseTooltip>
        <BaseTooltip class="menu-item base-tooltip--bottom base-tooltip--right">
          <IconButton
            name="close"
            icon-size="22"
            @click="closeDishOrIngredientHandler"
          />
          <template #tooltip>
            Zamknij widok potrawy
          </template>
        </BaseTooltip>
      </div>
      <DietDishForm
        :mode="'add-extended'"
        :extended="isExtended"
        :search-ingredients="searchIngredients as DietSearchIngredient[]"
        :dish-index="dishIndex.toString()"
        :chosen-nutrients="chosenNutrients"
        :dish="currentDish"
        @update-calculations="updateCalculations"
        @add-ingredient="addIngredient"
        @delete-ingredient="deleteIngredient"
        @replace-ingredient="replaceIngredient"
        @update-ingredient-in-dish-quantity="updateIngredientDishQuantity"
        @update-name="updateName"
        @update-size="updateSize"
        @update-max-portions="updateMaxPortions"
        @update-preparation-time="updatePreparationTime"
        @update-preparation-steps="updatePreparationSteps"
        @update-meal-types="updateMealTypes"
        @update-dish-types="updateDishTypes"
      />
    </div>
  </template>
  <template v-else>
    <div class="loader">
      <BaseLoader class="base-loader--size-48" />
    </div>
  </template>
</template>

<script lang="ts" setup>
import type { DishSearchByIngredientResponse } from '@/services/dishService'
import type {
  DietIngredient,
  DietSearchIngredient,
  DishInFormType,
  DishTypes,
  MealTypes,
  StoreDietDish
} from '@/types/Diet'
import type { Nutrients } from '@/utils/nutrients'

import { A, D } from '@mobily/ts-belt'
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import { useMounted } from '@vueuse/core'
import { match } from 'ts-pattern'

import { computed, ref, Teleport, watch } from 'vue'

import BaseLoader from '@/components/BaseLoader.vue'
import BaseTooltip from '@/components/BaseTooltip.vue'
import DietDishForm from '@/components/diets/DietDishForm.vue'
import { IconButton } from '@/components/IconButton'
import SavingStatus from '@/components/SavingStatus.vue'
import { dishService } from '@/services/dishService'
import { mapToSearchIngredient } from '@/utils/ingredients/mapToSearchIngredient'
import { reportError } from '@/utils/reportError'

import { useDishListProvider } from './provider'

interface Props {
  dishIndex: number
  extendedForm: boolean
}

const queryClient = useQueryClient()
const props = defineProps<Props>()
const emit = defineEmits(['closeForm', 'toggleExtended', 'copyDish', 'deleteDish'])
const { searchIngredients, chosenNutrients, isDishFavorite, toggleFavorite } = useDishListProvider()
const dishSaveStatus = ref<'idle' | 'error' | 'pending' | 'success'>('idle')
const isMounted = useMounted()

const dishId = computed(() => props.dishIndex)
const isExtended = computed(() => props.extendedForm)
const isFavorite = computed(() => isDishFavorite(dishId.value))

const { data: currentDish, isLoading } = useQuery({
  queryKey: ['dish', dishId],
  queryFn: () => dishService.fetchDish(dishId.value)
})

const { mutateAsync: updateDishAsync, status: updateDishStatus } = useMutation({
  mutationFn: (params: { data: StoreDietDish | DishInFormType; id: number }) =>
    dishService.updateDish(params.id, params.data),
  onSuccess: (__, params) => {
    queryClient.setQueryData(['dish', params.id], (oldData: DishSearchByIngredientResponse) => {
      return { ...oldData, ...params.data }
    })
    queryClient.setQueryData(['dishes', chosenNutrients], (oldData: DishInFormType[]) => {
      return oldData.map((dish) => {
        if (dish.id === params.id) {
          return {
            ...dish,
            name: params.data.name,
            size: params.data.size,
            preparation_time: params.data.preparation_time,
            portions: params.data.max_portions
          }
        }
        return dish
      })
    })
  }
})

const updateDishSearchByIngredientCache = (oldData: DishSearchByIngredientResponse) => {
  return {
    ...D.mapWithKey(oldData, (id, oldDish) => {
      if (id.toString() === dishId.value.toString() && currentDish.value) {
        return {
          dish_types: oldDish.dish_types,
          meal_types: oldDish.meal_types,
          ingredients: [...currentDish.value.ingredients].map(mapToSearchIngredient)
        }
      }
      return oldDish
    })
  }
}

const { mutateAsync: addIngredientAsync, status: addIngredientStatus } = useMutation({
  mutationFn: (data: { dishId: number; ingredient: DietIngredient }) => {
    return dishService.addIngredient(data.dishId, data.ingredient)
  },
  onSuccess: ({ dish_ingredient_id }, { ingredient }) => {
    queryClient.setQueryData(['dish', dishId], (oldData: StoreDietDish) => {
      return {
        ...oldData,
        ingredients: [...oldData.ingredients, { ...ingredient, dish_ingredient_id }]
      }
    })
    queryClient.setQueryData(['dishSearchByIngredient'], updateDishSearchByIngredientCache)
  }
})
const { mutateAsync: deleteIngredient, status: deleteIngredientStatus } = useMutation({
  mutationFn: ({ dishIngredientId }: { dishIngredientId: number; ingredientId: number }) => {
    return dishService.deleteIngredient(dishId.value, dishIngredientId)
  },
  onSuccess: (_, { ingredientId }) => {
    queryClient.setQueryData(['dish', dishId], (oldData: StoreDietDish) => {
      return {
        ...oldData,
        ingredients: oldData.ingredients.filter((i) => i.ingredient_id !== ingredientId)
      }
    })
    queryClient.setQueryData(['dishSearchByIngredient'], updateDishSearchByIngredientCache)
  }
})
const { mutateAsync: replaceIngredient, status: replaceIngredientStatus } = useMutation({
  mutationFn: (params: {
    dishIngredientId: number
    ingredient: DietIngredient
    ingredientIndex: number
  }) => {
    return dishService.replaceIngredient(dishId.value, params.ingredient, params.dishIngredientId)
  },
  onSuccess: (_, params) => {
    queryClient.setQueryData(['dish', dishId], (oldData: StoreDietDish) => {
      const ingredients = [...oldData.ingredients]
      ingredients[params.ingredientIndex] = {
        ...params.ingredient,
        dish_ingredient_id: params.dishIngredientId
      }
      return {
        ...oldData,
        ingredients
      }
    })

    queryClient.setQueryData(['dishSearchByIngredient'], updateDishSearchByIngredientCache)
  }
})

const {
  mutateAsync: updateIngredientDishQuantityAsync,
  status: updateIngredientInDishQuantityStatus
} = useMutation({
  mutationFn: (data: { dishIngredientId: number; quantity: number }) => {
    return dishService.updateIngredientInDishQuantity(
      dishId.value,
      data.dishIngredientId,
      data.quantity
    )
  },
  onSuccess: (_, params) => {
    queryClient.setQueryData(['dish', dishId], (oldData: StoreDietDish) => {
      const ingredients = [...oldData.ingredients].map((v) => {
        if (v.dish_ingredient_id === params.dishIngredientId) {
          return { ...v, quantity: params.quantity }
        }
        return v
      })

      return {
        ...oldData,
        ingredients
      }
    })
  }
})

const updateName = async (name: string) => {
  if (currentDish.value) {
    await updateDishAsync({
      id: dishId.value,
      data: { ...currentDish.value, name }
    })
  }
}
const updateSize = async (size: number) => {
  if (currentDish.value) {
    await updateDishAsync({
      id: dishId.value,
      data: { ...currentDish.value, size }
    })
  }
}
const updatePreparationSteps = async (preparation_steps: string) => {
  if (currentDish.value) {
    await updateDishAsync({
      id: dishId.value,
      data: { ...currentDish.value, preparation_steps }
    })
  }
}
const addIngredient = async (ingredient: DietIngredient) => {
  try {
    if (currentDish.value) {
      await addIngredientAsync({
        dishId: dishId.value,
        ingredient
      })
    }
  } catch (err) {
    reportError(err, 'Error during addIngredient', { dishId: dishId.value, ingredient })
  }
}

const updateMealTypes = async (mealTypes: MealTypes) => {
  if (currentDish.value) {
    const meal_types = Object.values(mealTypes)

    await updateDishAsync({
      id: dishId.value,
      data: { ...currentDish.value, meal_types }
    })

    queryClient.setQueryData(
      ['dishSearchByIngredient'],
      (oldData: DishSearchByIngredientResponse) => {
        return {
          ...D.mapWithKey(oldData, (id, oldDish) => {
            if (id.toString() === dishId.value.toString()) {
              return {
                meal_types,
                dish_types: oldDish.dish_types,
                ingredients: oldDish.ingredients
              }
            }
            return oldDish
          })
        }
      }
    )
  }
}

const updateDishTypes = async (dishTypes: DishTypes) => {
  if (currentDish.value) {
    const dish_types = Object.values(dishTypes)

    await updateDishAsync({
      id: dishId.value,
      data: { ...currentDish.value, dish_types }
    })

    queryClient.setQueryData(
      ['dishSearchByIngredient'],
      (oldData: DishSearchByIngredientResponse) => {
        return {
          ...D.mapWithKey(oldData, (id, oldDish) => {
            if (id.toString() === dishId.value.toString()) {
              return {
                dish_types,
                meal_types: oldDish.meal_types,
                ingredients: oldDish.ingredients
              }
            }
            return oldDish
          })
        }
      }
    )
  }
}

const updatePreparationTime = async (preparation_time: number) => {
  if (currentDish.value) {
    await updateDishAsync({
      id: dishId.value,
      data: { ...currentDish.value, preparation_time: preparation_time }
    })

    queryClient.setQueryData(['dish', dishId], (oldData: StoreDietDish) => ({
      ...oldData,
      preparation_time
    }))
  }
}
const updateMaxPortions = async (maxPortions: number) => {
  if (currentDish.value) {
    await updateDishAsync({
      id: dishId.value,
      data: { ...currentDish.value, max_portions: maxPortions }
    })
  }
}

const updateCalculations = (values: Record<Nutrients, number>) => {
  queryClient.setQueryData(['dishes', chosenNutrients], (oldData: DishInFormType[]) => {
    return oldData.map((dish) => {
      if (dish.id === dishId.value) {
        return {
          ...dish,
          ...values
        }
      }
      return dish
    })
  })
}

const updateIngredientDishQuantity = async (values: {
  dishIngredientId: number
  quantity: number
}) => {
  try {
    if (values.dishIngredientId) {
      await updateIngredientDishQuantityAsync(values)
    }
  } catch (err) {
    reportError(err, 'Error during updateIngredientDishQuantity', { values })
  }
}

const deleteDishOrIngredientHandler = () => {
  emit('deleteDish', dishId.value)
}
const closeDishOrIngredientHandler = () => {
  emit('closeForm')
}
const copyDishHandler = () => {
  emit('copyDish', dishId.value)
}
const showExtened = () => {
  emit('toggleExtended')
}
const closeExtended = () => {
  emit('toggleExtended')
}

watch(
  [
    updateDishStatus,
    deleteIngredientStatus,
    addIngredientStatus,
    replaceIngredientStatus,
    updateIngredientInDishQuantityStatus
  ],
  (next) => {
    match(next)
      .when(
        (v) => A.includes(v, 'pending'),
        () => {
          dishSaveStatus.value = 'pending'
        }
      )
      .when(
        (v) => A.includes(v, 'error'),
        () => {
          dishSaveStatus.value = 'error'
        }
      )
      .when(
        (v) => A.includes(v, 'success'),
        () => {
          dishSaveStatus.value = 'success'
        }
      )
      .otherwise(() => {
        dishSaveStatus.value = 'idle'
      })
  }
)
watch(dishId, () => {
  dishSaveStatus.value = 'idle'
})
</script>

<style scoped>
.dish-or-ingredient {
  background: #fff;
  padding: 16px;
  box-sizing: border-box;
  scrollbar-width: thin;
  overflow-y: scroll;
  position: relative;
}

.menu {
  position: absolute;
  right: 16px;
  top: 21px;
  display: flex;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
