import type { PropType } from 'vue'
import type { IconName } from './types'

import { defineComponent } from 'vue'

import { colors } from '@/assets/scss/variables'

import { glyphs } from './glyphs'

export type Colors = keyof typeof colors

export interface Props {
  readonly color?: Colors
  readonly name: IconName
  readonly size?: IconSize | number
  readonly style?: HTMLSpanElement['style']
}

const emptyObject = {}

export const Icon = defineComponent(
  (props: Props) => {
    const { name, size = 24, color: _color, style = emptyObject } = props

    const fontSize = `${size}px`
    const color = _color ? colors[_color] : undefined

    return () => (
      <span
        style={{
          fontFamily: 'Icon',
          fontSize,
          color,
          ...style,
          width: fontSize,
          height: fontSize,
          lineHeight: fontSize
        }}
      >
        {glyphs[name]}
      </span>
    )
  },
  {
    props: {
      name: String as PropType<IconName>,
      size: [String, Number] as PropType<IconSize>,
      color: String as PropType<Colors>,
      style: Object as PropType<HTMLSpanElement['style']>
    }
  }
)
