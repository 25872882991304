<template>
  <div class="activities">
    <h2>Ostatnia aktywność</h2>
    <div class="activity">
      <div>
        <div class="activity__content">
          <div class="activity__label">
            <h3>Jadłospisy</h3>
            <IconButton
              name="add"
              type="info"
              @click="handleAddDietButton()"
            />
          </div>
          <div
            v-if="!getDashboardLastActivity('diets')?.length"
            class="activity__empty"
          >
            <Space
              align-y="center"
              :space="8"
              :padding-x="5"
              :padding-y="12"
            >
              <p>Dodaj swój pierwszy jadłospis</p>
              <StyledButton
                variant="light"
                size="big"
                @click="openAddDietModal"
              >
                Utwórz jadłospis
              </StyledButton>
            </Space>
          </div>
          <div
            v-if="getDashboardLastActivity('diets')?.length"
            class="activity__list"
          >
            <router-link
              v-for="(item, index) in getDashboardLastActivity('diets')"
              :key="index"
              class="activity__list__item"
              :to="{
                name: 'DietDetailWeek',
                params: { dietType: item.type, dietId: item.id }
              }"
            >
              <Space
                direction="columns"
                align-y="center"
                :space="8"
              >
                <BaseIcon
                  size="20"
                  name="assignment"
                  type="secondary"
                />
                {{ item.name }}
              </Space>
            </router-link>
          </div>
        </div>
      </div>
      <div>
        <div class="activity__content">
          <div class="activity__label">
            <h3>Pacjenci</h3>
            <IconButton
              v-if="user?.ppdo_permissions"
              name="add"
              type="info"
              @click="openAddPatientModal"
            />
          </div>
          <div
            v-if="!getDashboardLastActivity('patients')?.length && user?.ppdo_permissions"
            class="activity__empty"
          >
            <Space
              align-y="center"
              :space="8"
              :padding-x="5"
              :padding-y="12"
            >
              <p>Dodaj swojego pierwszego pacjenta</p>
              <StyledButton
                variant="light"
                size="big"
                @click="openAddPatientModal"
              >
                Dodaj pacjenta
              </StyledButton>
            </Space>
          </div>
          <div
            v-if="getDashboardLastActivity('patients')?.length && user?.ppdo_permissions"
            class="activity__list"
          >
            <a
              v-for="(item, index) in getDashboardLastActivity('patients')"
              :key="index"
              class="activity__list__item"
              :href="item.url"
            >
              <Space
                direction="columns"
                align-y="center"
                :space="8"
              >
                <BaseIcon
                  size="20"
                  name="people_alt"
                  type="secondary"
                />
                {{ item.patient }}
              </Space>
            </a>
          </div>

          <div
            v-if="!user?.ppdo_permissions"
            class="activity__empty"
          >
            <Space
              align-y="center"
              :space="8"
              :padding-x="5"
              :padding-y="12"
            >
              <p v-if="user?.is_student && !globalStore.hasPerm('accounts.kcalmar_paid_plan')">
                Przed dodaniem swojego pierwszego pacjenta musisz zaakceptować regulamin oraz
                oświadczenie o przetwarzaniu danych osobowych
              </p>
              <p v-else>
                Przed dodaniem swojego pierwszego pacjenta musisz zaakceptować regulamin oraz Umowę
                Powierzenia Przetwarzania Danych Osobowych
              </p>
              <a :href="agreementLink"><StyledButton
                size="big"
                variant="light"
              >Przejdź do umowy</StyledButton>
              </a>
            </Space>
          </div>
        </div>
      </div>
    </div>
    <DashboardAddDietModal
      v-if="isOpenAddDietModal"
      @close="closeAddDietModal()"
    />
    <DashboardAddPatientModal
      v-if="isOpenAddPatientModal"
      @close="closeAddPatientModal()"
    />
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import { IconButton } from '@/components/IconButton'
import { Space } from '@/components/Space'
import { StyledButton } from '@/components/StyledButton'
import DashboardAddDietModal from '@/pages/dashboard/components/DashboardAddDietModal.vue'
import DashboardAddPatientModal from '@/pages/dashboard/components/DashboardAddPatientModal.vue'
import { useDashboardStore } from '@/store/dashboardStore'
import { useGlobalStore } from '@/store/globalStore'

const dashboardState = useDashboardStore()
const globalStore = useGlobalStore()
const { user } = storeToRefs(globalStore)
const isOpenAddDietModal = ref(false)
const isOpenAddPatientModal = ref(false)
const getDashboardLastActivity = computed(() => dashboardState.getDashboardLastActivity)
const agreementLink = `${import.meta.env.VUE_APP_HOST}/dietetyk/account/agreements/`

const getAmountOfUserDiets = () => {
  // if (user.value?.extra) {
  //   if (user?.extra.diets.counter.UserDietCount) {
  //     return user.value?.extra.diets.counter.UserDietCount
  //   } else {
  //     return user.value?.extra.diets.counter['user_diet_count']
  //   }
  // }
  return 0
}

const handleAddDietButton = () => {
  if (getAmountOfUserDiets() > 99 && !globalStore.hasPerm('accounts.max_diets_100')) {
    globalStore.showAppUpgradePlanModal()
  } else {
    isOpenAddDietModal.value = true
  }
}
const closeAddDietModal = () => {
  isOpenAddDietModal.value = false
}
const openAddDietModal = () => {
  isOpenAddDietModal.value = true
}
const closeAddPatientModal = () => {
  isOpenAddPatientModal.value = false
}
const openAddPatientModal = () => {
  isOpenAddPatientModal.value = true
}
</script>

<style scoped>
h2 {
  font-family: Montserrat-Medium;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  line-height: 28px;
  letter-spacing: -0.017em;
  text-align: left;
  margin: 0 0 9px;
}

.activities {
  padding-top: 28px;
}

.activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.activity > div {
  width: calc(50% - 14px);
}

.activity__content {
  padding: 24px;
  background: #f7f7f7;
  border-radius: 8px;
}

.activity__content .activity__label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activity__content .activity__label h3 {
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat-Medium;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  text-align: left;
}

.activity__content .activity__list {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.activity__content .activity__list__item {
  background: #fff;
  position: relative;
  padding: 13px 16px;
  text-align: left;
  border-radius: 8px;
  margin-top: 8px;
  cursor: pointer;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: var(--color-primary-text);
  text-decoration: none;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.activity__content .activity__list__item:hover {
  background: var(--color-main-20);
}

.activity__content .activity__list > .activity__list__item:first-child {
  margin-top: 0;
}

.activity__content .activity__list__item :deep(.material-icon__image) {
  width: 20px;
}

.activity__empty {
  margin-top: 22px;
  background: #fff;
  border: 1px dashed #ccc;
  display: flex;
  min-height: 142px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  flex-direction: column;
}

.activity__empty p {
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat-Medium;
  line-height: 20px;
  letter-spacing: -0.006em;
  margin: 0;
  text-align: center;
  color: #8d8d8d;
}

.activity__empty .add-item {
  background: var(--color-main-10);
  color: var(--color-main-100);
  font-size: 14px;
  font-style: normal;
  font-family: Montserrat-Medium;
  line-height: 20px;
  letter-spacing: -0.006em;
  border-radius: 4px;
  text-align: left;
  padding: 8px 16px;
  cursor: pointer;
  text-decoration: none;
}

.activity__empty .add-item:hover {
  color: #008183;
}

.activity__empty > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.activities :deep(.content) > .content__row:first-child {
  margin-top: 16px;
}

.activities :deep(.content__row--double) {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.activities :deep(.content__row--double) > div {
  width: 248px;
  position: relative;
}

.activities :deep(.content__row__select) {
  position: relative;
}

.activities :deep(.content__row__buttons) {
  display: flex;
  justify-content: right;
}

.activities :deep(.content__row) {
  margin-top: 28px;
  position: relative;
}

/* errors */

.ppdo p {
  padding: 0 10px;
  margin-bottom: 10px;
}

@media screen and (width <= 1400px) {
  .ppdo p {
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 15px;
  }

  .ppdo a {
    font-size: 12px;
  }
}
</style>
