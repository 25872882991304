export function getRatioN3N6(valueN3: number, valueN6: number) {
  let newValueN3
  let newValueN6
  if (valueN3 > 0) {
    newValueN3 = 1
    newValueN6 = round(valueN6 / valueN3, 1)
  } else {
    newValueN3 = 0
    newValueN6 = valueN6
  }
  return `${newValueN3} : ${newValueN6}`
}

export function polishPlural(
  singularNominativ: string,
  pluralNominativ: string,
  pluralGenitive: string,
  value: number
) {
  if (value === 1) {
    return singularNominativ
  } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
    return pluralNominativ
  } else {
    return pluralGenitive
  }
}

export const timeOptions = [
  { value: '00:00', text: '00:00' },
  { value: '00:30', text: '00:30' },
  { value: '01:00', text: '01:00' },
  { value: '01:30', text: '01:30' },
  { value: '02:00', text: '02:00' },
  { value: '02:30', text: '02:30' },
  { value: '03:00', text: '03:00' },
  { value: '03:30', text: '03:30' },
  { value: '04:00', text: '04:00' },
  { value: '04:30', text: '04:30' },
  { value: '05:00', text: '05:00' },
  { value: '05:30', text: '05:30' },
  { value: '06:00', text: '06:00' },
  { value: '06:30', text: '06:30' },
  { value: '07:00', text: '07:00' },
  { value: '07:30', text: '07:30' },
  { value: '08:00', text: '08:00' },
  { value: '08:30', text: '08:30' },
  { value: '09:00', text: '09:00' },
  { value: '09:30', text: '09:30' },
  { value: '10:00', text: '10:00' },
  { value: '10:30', text: '10:30' },
  { value: '11:00', text: '11:00' },
  { value: '11:30', text: '11:30' },
  { value: '12:00', text: '12:00' },
  { value: '12:30', text: '12:30' },
  { value: '13:00', text: '13:00' },
  { value: '13:30', text: '13:30' },
  { value: '14:00', text: '14:00' },
  { value: '14:30', text: '14:30' },
  { value: '15:00', text: '15:00' },
  { value: '15:30', text: '15:30' },
  { value: '16:00', text: '16:00' },
  { value: '16:30', text: '16:30' },
  { value: '17:00', text: '17:00' },
  { value: '17:30', text: '17:30' },
  { value: '18:00', text: '18:00' },
  { value: '18:30', text: '18:30' },
  { value: '19:00', text: '19:00' },
  { value: '19:30', text: '19:30' },
  { value: '20:00', text: '20:00' },
  { value: '20:30', text: '20:30' },
  { value: '21:00', text: '21:00' },
  { value: '21:30', text: '21:30' },
  { value: '22:00', text: '22:00' },
  { value: '22:30', text: '22:30' },
  { value: '23:00', text: '23:00' },
  { value: '23:30', text: '23:30' }
]

export const activityLevelOptions = [
  { value: 1.25, text: '(1,25) Leżący w łóżku' },
  { value: 1.4, text: '(1,4) Mało aktywny' },
  { value: 1.6, text: '(1,6) Umiarkowanie aktywny' },
  { value: 1.75, text: '(1,75) Aktywny' },
  { value: 2, text: '(2) Bardzo aktywny' },
  { value: 2.3, text: '(2,3) Sportowiec wyczynowy' }
]

export const modelDietCategoryMap = {
  patient: 'PatientDiet',
  system: 'SystemDiet',
  university: 'UniversityDiet',
  user: 'UserDiet',
  nfz: 'NFZDiet'
}

export const urlCategoryMap = {
  patient: 'DietListPatient',
  system: 'DietListSystem',
  university: 'DietListUniversity',
  user: 'DietListUser',
  nfz: 'DietListNFZ'
}

export const dishSizes = [
  { value: 1, text: 'Mały' },
  { value: 2, text: 'Średni' },
  { value: 3, text: 'Duży' }
] as const

export const mealTypes = [
  { value: 1, text: 'Śniadanie' },
  { value: 2, text: 'Drugie śniadanie' },
  { value: 3, text: 'Obiad' },
  { value: 4, text: 'Podwieczorek' },
  { value: 5, text: 'Kolacja' },
  { value: 6, text: 'Przekąska' }
] as const

export const dishTypes = [
  { value: 21, text: 'Sałatka', subgroup: 'type' },
  { value: 22, text: 'Zupa', subgroup: 'type' },
  { value: 23, text: 'Danie główne', subgroup: 'type' },
  { value: 40, text: 'Deser', subgroup: 'type' },
  { value: 24, text: 'Kanapki', subgroup: 'type' },
  { value: 25, text: 'Makarony', subgroup: 'type' },
  { value: 26, text: 'Danie mięsne', subgroup: 'type' },
  { value: 27, text: 'Danie rybne', subgroup: 'type' },
  { value: 28, text: 'Surówka', subgroup: 'type' },
  { value: 12, text: 'Polska', subgroup: 'region' },
  { value: 13, text: 'Włoska', subgroup: 'region' },
  { value: 14, text: 'Azjatycka', subgroup: 'region' },
  { value: 15, text: 'Śródziemnomorska', subgroup: 'region' },
  { value: 42, text: 'Sokowirówka', subgroup: 'kitchenware' },
  { value: 16, text: 'Piekarnik', subgroup: 'kitchenware' },
  { value: 17, text: 'Blender', subgroup: 'kitchenware' },
  { value: 18, text: 'Mikser', subgroup: 'kitchenware' },
  { value: 19, text: 'Bez obróbki termicznej', subgroup: 'kitchenware' },
  { value: 20, text: 'Kuchenka', subgroup: 'kitchenware' },
  { value: 39, text: 'Brak sprzętu', subgroup: 'kitchenware' },
  { value: 6, text: 'Bez laktozy', subgroup: 'specialDiets' },
  { value: 38, text: 'Bogatoresztkowa', subgroup: 'specialDiets' },
  { value: 7, text: 'Lekkostrawna', subgroup: 'specialDiets' },
  { value: 8, text: 'Płynna', subgroup: 'specialDiets' },
  { value: 9, text: 'DASH', subgroup: 'specialDiets' },
  { value: 10, text: 'Low FODMAP', subgroup: 'specialDiets' },
  { value: 11, text: 'W cukrzycy i insulinooporności', subgroup: 'specialDiets' },
  { value: 51, text: 'Wegańska', subgroup: 'specialDiets' },
  { value: 52, text: 'Wegetariańska', subgroup: 'specialDiets' },
  { value: 54, text: 'Bezglutenowa', subgroup: 'specialDiets' },
  { value: 53, text: 'Bezmleczna', subgroup: 'specialDiets' },
  { value: 29, text: 'Dla dzieci', subgroup: 'other' },
  { value: 30, text: 'Na słodko', subgroup: 'other' },
  { value: 31, text: 'Na słono', subgroup: 'other' },
  { value: 3, text: 'Szybki', subgroup: 'other' },
  { value: 32, text: 'Do 4 składników', subgroup: 'other' },
  { value: 33, text: 'Proste przepisy', subgroup: 'other' },
  { value: 1, text: 'Paleo', subgroup: 'other' },
  { value: 2, text: 'Potreningowy', subgroup: 'other' },
  { value: 4, text: 'Fit', subgroup: 'other' },
  { value: 5, text: 'Z niskim IG', subgroup: 'other' },
  // { value: 41, text: 'Superfood', subgroup: 'other' },
  { value: 34, text: 'Święta Bożego Narodzenia', subgroup: 'specialOccasion' },
  { value: 35, text: 'Wielkanoc', subgroup: 'specialOccasion' },
  { value: 36, text: 'Do pracy', subgroup: 'specialOccasion' },
  { value: 37, text: 'Do szkoły', subgroup: 'specialOccasion' }
] as const

export const ingredientTags = {
  is_vegan: 'Wegański',
  is_vegetarian: 'Wegetariański',
  is_diary_free: 'Bezmleczny',
  is_gluten_free: 'Bezglutenowy'
}

export const ingredientBases = [
  { value: 1, text: 'IŻŻ' },
  { value: 2, text: 'USDA' },
  { value: 3, text: 'Markowe' },
  { value: 4, text: 'UK' },
  { value: 5, text: 'CH' },
  { value: 6, text: 'NCCDB' }
]

export const groupMap = {
  1: 'Nabiał',
  2: 'Mięso i wyroby mięsne',
  3: 'Ryby i owoce morza',
  4: 'Pieczywo',
  5: 'Zbożowe',
  7: 'Orzechy i ziarna',
  8: 'Napoje',
  9: 'Tłuszcze',
  10: 'Inne',
  11: 'Owoce i Warzywa',
  12: 'Przyprawy i zioła',
  13: 'Suplementy'
}

export const ingredientCategories = [
  { value: 1, text: 'Nabiał' },
  { value: 2, text: 'Mięso i wyroby mięsne' },
  { value: 3, text: 'Ryby i owoce morza' },
  { value: 4, text: 'Pieczywo' },
  { value: 5, text: 'Zbożowe' },
  { value: 7, text: 'Orzechy i ziarna' },
  { value: 8, text: 'Napoje' },
  { value: 9, text: 'Tłuszcze' },
  { value: 10, text: 'Inne' },
  { value: 11, text: 'Owoce i Warzywa' },
  { value: 12, text: 'Przyprawy i zioła' },
  { value: 13, text: 'Suplementy' }
]

export const dayMap = [
  'Poniedziałek',
  'Wtorek',
  'Środa',
  'Czwartek',
  'Piątek',
  'Sobota',
  'Niedziela'
]
export const dayMapShort = ['Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'Sb', 'Nd'] as const

// mainGroup inside vue.js has 0 as id
// but on server side mainGroup is represented as None in UserDiet on group field
export const mainGroup = {
  id: 0,
  name: 'Katalog główny',
  diets_ids: []
}

export const round = (value: number, decimals: number) => {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

export const getIconBySortFn =
  <T>(sortBy: T, sortByReverse: boolean) =>
  (by: T) => {
    if (sortBy === by) {
      if (sortByReverse) {
        return 'expand_more'
      } else {
        return 'expand_less'
      }
    } else {
      return 'unfold_more'
    }
  }

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max))
