<template>
  <div
    v-if="doiIsAvailable"
    id="add-dish-or-ingredient-form"
    class="add-dish-or-ingredient-form"
    :class="{ extended: extended }"
  >
    <div class="name">
      <div class="name__textarea">
        {{ name }}
        <div
          v-if="ingredientAllergens.notLike"
          class="allergens not-like"
        />
        <div
          v-if="ingredientAllergens.like"
          class="allergens like"
        />
        <div
          v-if="ingredientAllergens.allergies"
          class="allergens allergies"
        />
        <div
          v-if="ingredientAllergens.intolerances"
          class="allergens intolerances"
        />
      </div>
    </div>
    <div
      v-if="mode === 'edit'"
      class="info"
    >
      <BaseIcon
        class="info-icon"
        size="22"
        type="info"
        name="info"
      /> Zmiany w potrawie będą
      widoczne tylko w ramach tego przepisu
    </div>
    <div class="kcal-quantity-measurement">
      <div class="kcal">
        {{ kcal }} kcal
      </div>
      <div class="quantity">
        <BaseInputNumber
          v-model="quantity"
          class="quantity__input"
          :class="{ 'is-disabled': !canDietBeEdited }"
          :get-ref="
            (el) => {
              quantityInput = el
            }
          "
          :min="0"
          :max="9999"
          :precision="1"
          :step="0.1"
          @update:model-value="setIngredientQuantityUpdated"
        />
        g
      </div>
      <div class="measurement">
        <BaseInputNumber
          v-model="measurement"
          class="measurement__input"
          :class="{ 'is-disabled': !canDietBeEdited }"
          :get-ref="
            (el) => {
              measurementInput = el
            }
          "
          :min="0"
          :max="maxQuantity"
          :precision="2"
          :step="0.01"
          @update:model-value="setIngredientMeasurementUpdated"
        />
        x {{ ingredient.measurement }}
      </div>
    </div>
    <div class="nutrients">
      <div
        v-for="nutrient in selectedNutrients"
        :key="nutrient"
        class="nutrient"
      >
        {{ dishOrIngredientNutrient(ingredient, nutrient) }}
      </div>
    </div>
    <div
      v-if="extended"
      class="ingredients-switch"
    >
      <label class="ingredients-switch__label">Pokaż w tabeli:</label>
      <BaseRadio
        class="ingredients-switch__radio"
        :label="'Wybrane wartości'"
        :model-value="nutrientsType === 'choosen'"
        @update:model-value="nutrientsType = 'choosen'"
      />
      <BaseRadio
        class="ingredients-switch__radio"
        :label="'Domyślne wartości'"
        :model-value="nutrientsType === 'default'"
        @update:model-value="nutrientsType = 'default'"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Nutrients } from '@/utils/nutrients'
import type { PropType } from 'vue'

import round from 'lodash/round'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, toRef, watch } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseInputNumber from '@/components/BaseInputNumber.vue'
import BaseRadio from '@/components/BaseRadio.vue'
import { DEFAULT_NUTRIENTS } from '@/const'
import { dietsService } from '@/services/dietsService'
import { useDietsStore } from '@/store/dietsStore'
import { type AllergensInfo, type DietIngredient } from '@/types/Diet'
import { nutrientsMap } from '@/utils/nutrients'

const props = defineProps({
  mode: {
    required: true,
    type: String as PropType<'edit' | 'add'>
  },
  extended: {
    default: true,
    type: Boolean
  },
  ingredient: {
    required: true,
    type: Object as PropType<DietIngredient>
  },
  lossesMultiplier: {
    type: Number,
    default: 1
  },
  chosenNutrients: {
    type: Array as PropType<Nutrients[]>,
    default: function () {
      return DEFAULT_NUTRIENTS
    }
  }
})
const { getDishOrIngredientAllergens } = useDietsStore()
const lossesMultiplier = toRef(props, 'lossesMultiplier')
const extended = toRef(props, 'extended')
const sortBy = ref('quantity')
const sortByReverse = ref(false)
const nutrientsType = ref('choosen') // choosen or default
const nutrientsDefault = ref<Nutrients[]>([
  'protein',
  'fat',
  'carbohydrates',
  'fiber',
  'carbohydrate_exchanger'
])

const quantityInput = ref<HTMLInputElement>()
const measurementInput = ref<HTMLInputElement>()

const ingredient = toRef(props, 'ingredient')

const { canDietBeEdited } = storeToRefs(useDietsStore())
const emit = defineEmits([
  'update',
  'updateName',
  'updateQuantity',
  'updateSize',
  'updateUsedPortions',
  'updateMaxPortions',
  'updatePreparationTime',
  'updatePreparationSteps',
  'updateMealType',
  'updateDishType',
  'addIngredient',
  'deleteIngredient',
  'replaceIngredient',
  'updateIngredientInDishQuantity',
  'updateIngredientInDishMeasurement'
])

const doiIsAvailable = computed(() => {
  return Boolean(ingredient.value)
})

const selectedNutrients = computed(() => {
  if (nutrientsType.value === 'choosen') {
    return props.chosenNutrients
  } else {
    return nutrientsDefault.value
  }
})
const name = computed(() => {
  return ingredient.value.name
})

const kcal = computed(() => {
  return dietsService.getIngredientNutrient(
    ingredient.value,
    'calories',
    true,
    lossesMultiplier.value
  )
})

const quantity = computed({
  get: function () {
    return round(ingredient.value.quantity, 1)
  },
  set: function (value) {
    emit('updateQuantity', value)
    emit('update', ingredient.value)
  }
})

const measurement = computed({
  get: function () {
    return round(ingredient.value.quantity / ingredient.value.grams, 2)
  },
  set: function (value) {
    const _quantitty = round(value * ingredient.value.grams, 1)
    emit('updateQuantity', _quantitty)
    emit('update', ingredient.value)
  }
})

const maxQuantity = computed(() => Math.min(round(9999 / ingredient.value.grams, 1), 9999))

const setIngredientQuantityUpdated = () => {
  sessionStorage.setItem('ingredientQuantityUpdated', 'true')
}
const setIngredientMeasurementUpdated = () => {
  sessionStorage.setItem('ingredientMeasurementUpdated', 'true')
}
const ingredientAllergens = computed<AllergensInfo>(() =>
  getDishOrIngredientAllergens(ingredient.value)
)

// DishOrIngredient
const dishOrIngredientNutrient = (i: DietIngredient, nutrient: Nutrients) => {
  const nutrientDetail = nutrientsMap[nutrient]
  const value = dietsService.getIngredientNutrient(i, nutrient, true, lossesMultiplier.value)

  return `${nutrientDetail.shortcut}: ${value} ${nutrientDetail.measurement}`
}

onMounted(() => {
  const ingredientQuantityUpdated = sessionStorage.getItem('ingredientQuantityUpdated') ?? 'false'
  const ingredientMeasurementUpdated =
    sessionStorage.getItem('ingredientMeasurementUpdated') ?? 'false'

  if (ingredientQuantityUpdated === 'true') {
    setTimeout(() => {
      quantityInput.value?.focus()
      sessionStorage.setItem('ingredientQuantityUpdated', 'false')
    }, 0)
  }
  if (ingredientMeasurementUpdated === 'true') {
    setTimeout(() => {
      measurementInput.value?.focus()
      sessionStorage.setItem('ingredientMeasurementUpdated', 'false')
    }, 0)
  }
})

watch(
  () => props.extended,
  () => {
    nutrientsType.value = 'choosen'
    sortBy.value = 'quantity'
    sortByReverse.value = false
  }
)
</script>

<style scoped>
.add-dish-or-ingredient-form.extended {
  min-width: 675px;
}

.allergens {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 3px;
  vertical-align: middle;
}

.allergens.not-like {
  background: var(--color-warning-60);
}

.allergens.like {
  background: var(--color-success-60);
}

.allergens.allergies {
  background: #faa0a8;
}

.allergens.intolerances {
  background: #a0a8fa;
}

.name {
  width: calc(100% - 136px);
}

.name__textarea {
  font-size: 24px;
  line-height: 28px;
  border: 0 !important;
  padding: 5px 0;
  font-family: Montserrat-Medium;
  width: 100%;
  display: block;
}

.info {
  line-height: 22px;
  height: 22px;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 16px;
}

.info-icon {
  margin-right: 6px;
}

.kcal-quantity-measurement {
  margin-bottom: 6px;
  font-size: 18px;
  font-family: Montserrat-Medium;
  display: flex;
  flex-direction: row;
  height: 32px;
  line-height: 32px;
}

.quantity {
  height: 32px;
  margin-left: 24px;
}

:deep(.base-input.is-disabled) {
  border-bottom: none;
  pointer-events: none;
}

.quantity__input {
  width: 68px;
  text-align: center;
  font-size: 18px;
  padding: 2px 4px;
  font-family: Montserrat-Medium;
}

.measurement {
  height: 32px;
  margin-left: 24px;
}

.measurement__input {
  width: 68px;
  text-align: center;
  font-size: 18px;
  padding: 2px 4px;
  font-family: Montserrat-Medium;
}

.nutrients {
  display: flex;
  flex-flow: row wrap;
  font-size: 12px;
  margin-top: 12px;
  line-height: 14px;
}

.nutrient {
  margin-right: 15px;
  white-space: nowrap;
}

.ingredients-switch {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
}

.ingredients-switch__label {
  font-size: 14px;
  font-family: Montserrat-Medium;
  line-height: 32px;
  height: 32px;
}

.ingredients-switch__radio {
  margin-left: 20px;
}
</style>
