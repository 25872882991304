<template>
  <div class="add-dish-or-ingredient">
    <input
      ref="search"
      class="search"
      placeholder="Wpisz nazwę"
      :value="searchValue"
      @input="searchHandler($event)"
      @click.stop.prevent
    >
    <div
      ref="options"
      class="options"
      :class="{
        'options-to-right-edge': optionsToRightEdge
      }"
      @scroll="scrollHandler"
      @keydown="focusSearch"
    >
      <div
        v-if="!props.searchIngredients"
        class="loading"
      >
        <BaseLoader class="base-loader--size-12" />
        <div class="loading-name">
          Ładowanie wyników
        </div>
      </div>
      <div
        v-else-if="searchResults.length === 0"
        class="no-results"
      >
        Brak wyników
      </div>
      <template v-else>
        <div
          v-for="ingredient in searchResults"
          :key="`${ingredient.name}-${ingredient.id}`"
          class="option"
          tabindex="0"
          @keyup.enter="selectIngredient(ingredient.id)"
          @click="selectIngredient(ingredient.id)"
        >
          <div class="type">
            <BaseIcon
              size="12"
              name="local_grocery_store"
            />
          </div>
          <div
            class="name"
            :title="ingredient.name"
          >
            {{ ingredient.name }}
          </div>
          <div class="properties">
            <div
              v-if="!ingredient.is_public"
              class="private"
            >
              <BaseIcon
                size="12"
                name="lock"
              />
            </div>
            <div
              v-if="ingredient.is_favourite"
              class="favourite"
            >
              <BaseIcon
                size="12"
                name="star"
              />
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { DietSearchIngredient } from '@/types/Diet'

import { computed, onMounted, ref, toRef } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import BaseLoader from '@/components/BaseLoader.vue'
import { useFuseSearch } from '@/hooks/useFuseSearch'

interface Props {
  searchIngredients?: DietSearchIngredient[]
}
const props = defineProps<Props>()

const options = ref<HTMLDivElement>()
const search = ref<HTMLInputElement>()
const emit = defineEmits(['select'])
const searchValue = ref('')
const searchLimit = ref(50)
const optionsToRightEdge = ref(false)
const ingredients = toRef(props, 'searchIngredients', [])

const _searchResult = useFuseSearch(ingredients, searchValue, ['name'])

const searchResults = computed(() => {
  const dishesAndIngredients = [..._searchResult.value]

  dishesAndIngredients.sort((a, b) => {
    // sort by name
    const aName = a.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/\u0142/g, 'l')
    const bName = b.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/\u0142/g, 'l')
    if (aName < bName) return -1
    if (aName > bName) return 1
    return 0
  })

  dishesAndIngredients.sort((a, b) => {
    // sort by favourite
    return -1 * (Number(a.is_favourite) - Number(b.is_favourite))
  })
  dishesAndIngredients.sort((a, b) => {
    // sort favourites by name
    if (a.is_favourite && b.is_favourite) {
      const aName = a.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      const bName = b.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\u0142/g, 'l')
      if (aName < bName) return -1
      if (aName > bName) return 1
    }
    return 0
  })

  return dishesAndIngredients.slice(0, Math.min(searchLimit.value, _searchResult.value.length))
})

const searchHandler = (event: Event) => {
  searchLimit.value = 50
  searchValue.value = (event.target as HTMLInputElement).value
}

const scrollHandler = ({ target }: Event) => {
  const { scrollTop, clientHeight, scrollHeight } = target as HTMLDivElement

  if (Math.ceil(scrollTop + clientHeight) >= scrollHeight * 0.95) {
    searchLimit.value += 50
  }
}

const focusSearch = (event: KeyboardEvent) => {
  if (event.key !== 'Tab' && event.key !== 'Shift' && event.key !== 'Enter') {
    search.value?.focus()
  }
}

const selectIngredient = (id: number) => {
  emit('select', {
    id
  })
}

onMounted(() => {
  const rect = options.value?.getBoundingClientRect()
  if (rect && rect.right > window.innerWidth) {
    optionsToRightEdge.value = true
  }
  search.value?.focus()
})
</script>

<style scoped>
.add-dish-or-ingredient {
  position: relative;
}

.search {
  width: 100%;
  height: 32px;
  line-height: 18px;
  font-size: 14px;
  padding: 7px 8px;
  background: var(--color-main-10);
  border: 0;
  border-bottom: 1px solid var(--color-main-100);
  border-radius: 4px 4px 0 0;
  outline: 0;
  box-sizing: border-box;
}

.search::placeholder {
  color: var(--color-tertiary-text);
  font-size: 12px;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 6px 0 rgb(155 155 155 / 50%);
  max-height: 400px;
  overflow: hidden auto;
  scrollbar-width: thin;
  padding: 0;
  width: 400px;
}

.options.options-to-right-edge {
  right: 0;
  left: inherit;
}

.options::-webkit-scrollbar {
  width: 6px;
}

.unused-portions-title {
  font-family: Montserrat-Medium;
  font-size: 12px;
  padding: 4px;
  pointer-events: none;
  user-select: none;
}

.separator {
  height: 1px;
  width: calc(100% + 12px);
  margin-left: -6px;
  background: var(--color-tertiary-text);
}

.loading {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  height: 24px;
  justify-content: left;
  align-items: center;
  color: var(--color-secondary-text);
  width: 326px;
  padding: 0 6px;
  pointer-events: none;
  user-select: none;
}

.loading-name {
  margin-left: 4px;
}

.loading .base-loader__circle::before {
  background-color: var(--color-secondary-text);
}

.no-results {
  height: 24px;
  line-height: 24px;
  width: 326px;
  padding: 0 4px;
  font-size: 12px;
  color: var(--color-secondary-text);
  pointer-events: none;
  user-select: none;
}

.option {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: center;
  font-size: 12px;
  height: 24px;
  width: 100%;
  padding: 0 8px;
  z-index: 101;
  cursor: pointer;
  box-sizing: border-box;
}

.option:hover,
.option:focus {
  outline: none;
  background: var(--color-main-10);
}

.type {
  pointer-events: none;
  display: inline-flex;
}

.type .base-icon {
  pointer-events: none;
  color: var(--color-main-100);
}

.unused .base-icon {
  color: var(--color-destructive-100);
}

.name {
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 4px;
}

.properties {
  margin-right: 4px;
  display: flex;
  gap: 2px;
  flex-direction: row;
  align-items: center;
}

.properties div {
  display: flex;
  align-items: center;
}

.portions {
  color: var(--color-main-100);
  font-family: Montserrat-Bold;
  font-size: 10px;
}

.private .base-icon,
.favourite .base-icon {
  color: var(--color-tertiary-text);
}
</style>
